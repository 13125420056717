import React, { Fragment, useMemo } from 'react'
import FilterAutocompleteComponent from '../../components/filter/FilterAutocomplete.component'
import { useSelector } from 'react-redux'
import _ from 'lodash'

const ScountingFilterLeagueCountry = (props) => {

    const { user } = useSelector(state => state.user)

    const countries = useMemo(() => (
        _.unionBy(user.competitions.map((item) => item.country), (e) => e.id)
            .sort((a, b) => a.name.localeCompare(b.name))
    ), [user])

    return (
        <Fragment>
            <FilterAutocompleteComponent
                options={countries}
                setFilter={(value) => props.setFieldValue('league_countries', value)} />
        </Fragment>
    )
}

export default ScountingFilterLeagueCountry