import React from 'react';
import {
    Button,
} from '@material-ui/core';


const Submit = ({
    isSubmitting,
    title,
    color = 'secondary',
    disabled = false,
    style
}) => {

    return (
        <Button
            color={color}
            disabled={disabled}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            style={style}>
            {title}
        </Button>
    );
};


export default Submit;
