import React, { useState, useCallback } from 'react'
import { withStyles } from "@material-ui/core/styles"
import { style } from './DetailProposal.component.style';
import Typography from '@material-ui/core/Typography';
import { Grid, Box, Button } from '@material-ui/core';
import ImageContainer from '../../containers/image/Image.container';
import FilesContainer from '../../containers/club_partnership/Files.container';
import LinksContainer from '../../containers/club_partnership/Links.container'
import PlayerAddLinkContainer from '../../containers/player/PlayerAddLink.container'
import PlayerAddFileContainer from '../../containers/player/PlayerAddFile.container'
import Requests from '../../core/requests'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import CallOutcomeComponent from '../club_partnership/CallOutcome.component';
import Modal from '../../components/Modal';
import { Partner } from "../../conf/Auth.config";
import { isNotMarketOperatorUser } from '../../utils/CheckLoggedUser';

const DetailProposalComponent = ({
    classes,
    data,
    mutate
}) => {

    const identifier = "proposal";
    const { id } = useParams()
    const { token, user } = useSelector(state => state.user)
    const [conversionLoading, setConversionLoading] = useState(false)
    const [error, setError] = useState(null)
    const [openModal, setOpenModal] = useState(null)
    const [conversionSuccessMessage, setConversionSuccessMessage] = useState(null)

    const convertProposalToMandate = useCallback((proposalId) => {
        setConversionSuccessMessage(null);
        setError(null);
        setConversionLoading(true)
        Requests.proposal.convertProposalToMandate(({
            token: token.access,
            agenda_note: proposalId,
            player: data?.id,
        })).then(() => {
            setConversionLoading(false)
            setConversionSuccessMessage("Conversion done successfully")
            setOpenModal(true);
        }).catch((error) => {
            setConversionLoading(false)
            setError(error?.response?.data?.error)
            setOpenModal(true);
        })
    }, [])

    const isNotPartnerUser = useCallback(() => {
        return user?.groups?.find(element => element.name !== Partner);
    }, [])

    return (
        <Grid container>
            <Grid container item xs={12}>
                <Grid item xs={2} className={classes.container}>
                    <ImageContainer src={data.image_url} className={classes.avatar} />
                </Grid>
                <Grid item xs={5} className={classes.container}>
                    <Typography className={classes.name}>{data.full_name}</Typography>
                </Grid>
            </Grid>
            <Grid className={classes.divider} item xs={12} />
            <Grid container item xs={12}>
                <Grid item xs>
                    <Box display="flex" flexDirection="row" alignItems='center'>
                        <Typography className={classes.label}>Age:</Typography>
                        <Typography className={classes.value}>{data.age}</Typography>
                    </Box>
                    <Box display="flex" flexDirection="row" alignItems='center'>
                        <Typography className={classes.label}>Native Country:</Typography>
                        <Typography className={classes.value}>{data?.birth_country?.name}</Typography>
                    </Box>

                    <Box display="flex" flexDirection="row" alignItems='end'>
                        <Typography className={classes.label}>Passaport:</Typography>
                        <Typography className={classes.value}>{data.passports?.map((item, index) => index === data.passports.length - 1 ? `${item.name}` : `${item.name}-`)}</Typography>
                    </Box>

                    <Box display="flex" flexDirection="row" alignItems='center'>
                        <Typography className={classes.label}>Height:</Typography>
                        <Typography className={classes.value}>{data.height}</Typography>
                    </Box>
                </Grid>
                <Grid item xs>
                    <Box display="flex" flexDirection="row" alignItems='center'>
                        <Typography className={classes.label}>Position:</Typography>
                        <Typography className={classes.value}>{data.roles.map((role, index) => index === data.roles.length - 1 ? `${role.short_name}` : `${role.short_name}-`)}</Typography>
                    </Box>
                    <Box display="flex" flexDirection="row" alignItems='center'>
                        <Typography className={classes.label}>Current club:</Typography>
                        <Typography className={classes.value}>{data?.team?.name}</Typography>
                    </Box>
                    <Box display="flex" flexDirection="row" alignItems='center'>
                        <Typography className={classes.label}>Contract expiry:</Typography>
                        <Typography className={classes.value}>{data.contract_expires}</Typography>
                    </Box>
                </Grid>
                {isNotPartnerUser() && isNotMarketOperatorUser(user?.groups) &&
                    <Grid xs={12}>
                        <Button disabled={conversionLoading} onClick={() => convertProposalToMandate(id)} className={classes.fullWidthWithMargin} variant="contained" type='submit' color="primary">
                            Converti in mandato
                        </Button>
                    </Grid>
                }
                <Modal
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                    title="Conversion Outcome">
                    <CallOutcomeComponent success={conversionSuccessMessage} error={error} />
                </Modal>
                <Grid className={classes.divider} item xs={12} />
                {isNotMarketOperatorUser(user?.groups) &&
                    <PlayerAddFileContainer playerId={data.id} mutate={mutate} />
                }
                <Grid item xs={12}>
                    <FilesContainer identifier={identifier} fullWidth={true} mutate={mutate} files={data.files || null} />
                </Grid>

                <Grid className={classes.divider} item xs={12} />
                {isNotMarketOperatorUser(user?.groups) &&
                    <PlayerAddLinkContainer showUsersInvolved={true} noMarginTop={true} playerId={data.id} mutate={mutate} />
                }
                <Grid item xs={12}>
                    <LinksContainer identifier={identifier} fullWidth={true} links={data?.links} mutate={mutate} />
                </Grid>
            </Grid>
        </Grid>
    )

}

export default withStyles(style)(DetailProposalComponent)
