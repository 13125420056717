import _ from 'lodash';

export const ClubPartnershipEndpoints = {
    basePath: '/api/v1/mandates/',
    endpoints: {
        clubsList: {
            path: 'area-clubs/',
            method: 'GET',
            get: 'data',
            fallback: [],
            transform: (data) => data?.sort((a, b) => a?.country?.name?.localeCompare(b?.country?.name)),
            auth: true,
        },
        clubMandates: {
            path: 'clubs/?team={{clubId}}',
            method: 'GET',
            get: 'data',
            fallback: [],
            auth: true,
            swr: {
                revalidateOnFocus: false,
                revalidateOnMount: false,
                revalidateOnReconnect: false,
                dedupingInterval: 1000,
            }
        },
        deleteMandate: {
            path: 'clubs/{{mandateId}}/',
            method: 'DELETE',
            fallback: null,
            auth: true
        },
        getMandate: {
            path: 'clubs/{{mandateId}}/',
            method: 'GET',
            get: 'data',
            fallback: [],
            auth: true,
        },
        addMandate: {
            path: 'clubs/',
            method: 'POST',
            get: 'data',
            fallback: null,
            auth: true,
        },
        editMandate: {
            path: 'clubs/{{mandateId}}/',
            method: 'PATCH',
            get: 'data',
            fallback: null,
            auth: true,
        },
        getCommentsByMandate: {
            path: '/clubs/{{mandateId}}/comments/',
            method: 'GET',
            get: 'data',
            fallback: [],
            auth: true,
            swr: {
                revalidateOnFocus: false
            }
        },
        addMandateComment: {
            path: '/clubs/{{id}}/comments/',
            method: 'POST',
            get: 'data',
            fallback: null,   
            auth: true,    
        },
        deleteMandateComment: {
            path: '/clubs/{{id}}/comments/{{commentId}}/',
            method: 'DELETE',
            fallback: null,   
            auth: true
        }
    }
}

export default ClubPartnershipEndpoints;