import axios from "axios"

const options = {
    headers: {
        "Content-Type": "application/json"
    }
}

const instance = axios.create(options);

export const Manager = instance
export default Manager