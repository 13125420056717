import React, { useEffect } from "react";
import { Form } from "formik";
import { Box, Button, Typography, Slider } from "@material-ui/core";
import ScountingFilterRoleContainer from "../../containers/scouting/ScountingFilterRole.container";
import ScountingFilterLeagueContainer from "../../containers/scouting/ScountingFilterLeague.container";
import ScountingFilterLeagueCountry from "../../containers/scouting/ScountingFilterLeagueCountry.container";
import PlayerNationalityContainer from "../../containers/scouting/PlayerNationality.container";
import { OptionsFoot } from "../../conf/ScoutingFiler.conf";
import FilterAutocompleteComponent from "./FilterAutocomplete.component";
import SearchBar from "../table/table_scouting/SearchBar";

const ScountingFilterComponent = ({ onChange = () => { }, identifier, ...props }) => {
    useEffect(() => {
        onChange();
    }, [props.values]);

    return (
        <Form onSubmit={props.handleSubmit}>
            <Box mt={2}>
                <Typography>Filter By League Country</Typography>
                <ScountingFilterLeagueCountry {...props} />
            </Box>
            <Box mt={2}>
                <Typography>Filter By League</Typography>
                <ScountingFilterLeagueContainer {...props} />
            </Box>
            { identifier !== "elitePage" && identifier !== "elitePortfolioPage" ?
            <>
                <Box mt={2}>
                    <Typography>Player Nationality</Typography>
                    <PlayerNationalityContainer {...props} />
                </Box>
                <Box mt={2}>
                    <Typography>Filter By Role</Typography>
                    <ScountingFilterRoleContainer {...props} />
                </Box>
                <Box mt={2}>
                    <Typography>Filter By Foot:</Typography>
                    <FilterAutocompleteComponent
                        options={OptionsFoot}
                        setFilter={(value) => props.setFieldValue("footedness", value)}
                    />
                </Box>
                <Box mt={2}>
                    <Typography>Filter By Height (cm):</Typography>
                    <Box mt={5}>
                        <Slider
                            name="heights"
                            min={150}
                            max={210}
                            value={[props.values.min_height, props.values.max_height]}
                            onChange={(event, newValue) => {
                                props.setFieldValue("min_height", newValue[0]);
                                props.setFieldValue("max_height", newValue[1]);
                            }}
                            valueLabelDisplay="on"
                        />
                    </Box>
                </Box>
                <Box mt={2}>
                    <Typography>Filter By Age:</Typography>
                    <Box mt={5}>
                        <Slider
                            name="age"
                            min={14}
                            max={46}
                            value={[props.values.min_age, props.values.max_age]}
                            onChange={(event, newValue) => {
                                props.setFieldValue("min_age", newValue[0]);
                                props.setFieldValue("max_age", newValue[1]);
                            }}
                            valueLabelDisplay="on"
                            step={1}
                        />
                    </Box>
                </Box>
            </> : null }
            <Box mt={2}>
                <Typography>Filter By Name:</Typography>
                <Box mt={2}>
                    <SearchBar
                        name="full_name"
                        value={props.values.full_name}
                        handleChange={(event) => {
                            props.setFieldValue('full_name', event.target.value);
                        }}
                    />
                </Box>
            </Box>


            <Box padding={2}>
                <Button variant="contained" type="submit" color="primary">
                    Search
                </Button>
            </Box>
        </Form>
    );
};

export default ScountingFilterComponent;
