import React, { Fragment, useCallback, useEffect, useState } from 'react'
import Progress from '../../components/progress/Progress.component'
import Error from '../../components/error/Error.component'
import LoginComponent from '../../components/login/Login.component'
import { useDispatch, useSelector } from 'react-redux'
import { login, resetError } from '../../store/user.store'
import { Formik } from 'formik'
import { LoginInitialValues, LoginValidationSchema } from '../../conf/Login.conf'
import { useHistory } from 'react-router-dom'
import { HeadCompany, HeadScout, MarketOperator, Partner, Scout } from '../../conf/Auth.config'

const LoginContainer = () => {

    const { loading, error, user } = useSelector(state => state.user)
    const dispatch = useDispatch()
    const history = useHistory()

    const onSubmitLogin = useCallback((values, actions) => {
        //actions.setSubmitting(true)
        dispatch(login(values))
    }, [])

    useEffect(() => {

        let path = '/login'

        if (user) {
            const groups = user.groups[0].name

            if (HeadCompany.includes(groups)) {
                path = '/mandates/desk'
            }

            if (HeadScout.includes(groups)) {
                path = '/scouting/alert'
            }

            if (Scout.includes(groups)) {
                path = '/scouting/alert'
            }

            if (Partner.includes(groups) || MarketOperator.includes(groups)) {
                path = '/player_requests/club_proposal'
            }

            history.push(path)

        }

    }, [user])

    const onResetError = useCallback(() => {
        //actions.setSubmitting(true)
        dispatch(resetError())
    }, [])

    return (
        <Fragment>
            <Formik
                validationSchema={LoginValidationSchema.schema}
                onSubmit={onSubmitLogin}
                initialValues={LoginInitialValues}>
                {(props) => (
                    <Fragment>
                        {error && <Error open={error?.error?.message ? true : false} message={error?.error?.message} onResetError={onResetError} />}
                        {loading && <Progress />}
                        <LoginComponent {...props} />
                    </Fragment>
                )}
            </Formik>
        </Fragment>
    )
}

export default LoginContainer