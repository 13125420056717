import React from 'react'
import MainLayout from '../../layout/main/Main.layout'
import ClubMandateContainer from '../../containers/club_partnership/ClubMandate.container'

const ClubMandatePage = () => {

    return (
        <MainLayout title="Mandate">
            <ClubMandateContainer />
        </MainLayout>
    )
}

export default ClubMandatePage