import React, { useCallback, useState } from 'react'
import { withStyles } from "@material-ui/core/styles"
import { style } from './ScoutingPlayersListAlert.component.style';
import TableList from '../table/table_scouting/Table';
import Row from './ScoutingPlayerRowAlert.component';

const headCells = [
    {
        id: 'player', label: 'Player', sortable: false
    },
    {
        id: 'role', label: 'Role', sortable: false
    },
    {
        id: 'age', label: 'Age', sortable: false
    },
    {
        id: 'nationality', label: 'Nationality', sortable: false
    },
    {
        id: 'club', label: 'Club', sortable: false
    },
    {
        id: 'league', label: 'League', sortable: false
    },
    {
        id: 'report', label: 'Detail', sortable: false
    },
]

const ScountingPlayersListAlertComponent = ({
    rawData,
    players,
    pageIndex,
    setPageIndex,
    onClickDetail = () => { },
}) => {

    return (
        <TableList
            headCells={headCells}
            items={players}
            rowsPerPage={20}
            rowsPerPageOptions={[]}
            count={rawData.count}
            rawData={rawData}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
        >
            {(item) =>
                <Row player={item} onClickDetail={onClickDetail} />}
        </TableList >

    )
}

export default withStyles(style)(ScountingPlayersListAlertComponent)