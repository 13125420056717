import React, { Fragment, useState, useCallback } from 'react'
import PlayerAddFileComponent from '../../components/club_proposal/PlayerAddFile.component';
import { Formik } from 'formik';
import Requests from '../../core/requests'
import { useSelector } from 'react-redux'
import CallOutcomeComponent from '../../components/club_partnership/CallOutcome.component'

const PlayerAddFileContainer = ({
    playerId,
    mutate
}) => {

    const { token } = useSelector(state => state.user);
    const [error, setError] = useState(null);

    const onReportSubmit = useCallback((values, resetForm) => {
        if(values?.file){
            Requests.reports.file({
                player: parseInt(values.player),
                file: values.file,
                users: JSON.stringify(values.operators),
                token: token.access
            }).then(() => {
                mutate()
                resetForm()
            }).catch((error) => {
                setError(error?.response?.data?.error)
            })
        }
    }, [])


    return (
        <Fragment>
            <CallOutcomeComponent error = {error} />
            <Formik
                initialValues={{ file: null, player: playerId, operators: null}}
                validateOnBlur={true}
                validateOnChange={false}>
                {(props) =>
                    <PlayerAddFileComponent onReportSubmit = { () => onReportSubmit(props?.values, props?.resetForm) } {...props} />
                }
            </Formik>
        </Fragment>
    )
}

export default PlayerAddFileContainer
