import _ from 'lodash';

export const AgencyEndpoints = {
    basePath: '/api/v1/',
    endpoints: {
        addAgency: {
            path: 'agencies/',
            method: 'POST',
            get: 'data',
            fallback: null,   
            auth: true,
            multipart: true   
        },
        getListAgencies: {
            path: 'agencies/',
            method: 'GET',
            get: 'data',
            fallback: [],   
            auth: true,
            swr: {
                revalidateOnFocus: false
            }   
        },
        getAgenciesByName: {
            path: 'agencies/?name={{agencyName}}',
            method: 'GET',
            get: 'data',
            fallback: [],   
            auth: true,
            swr: {
                revalidateOnFocus: false
            }   
        }
    }
}

export default AgencyEndpoints;