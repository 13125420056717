import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import StarRatingComponent from 'react-star-rating-component';

const Row = ({
    player,
    onClickDetail = () => { },
}) => {

    return (
        <TableRow key={player.id}>
            <TableCell>{player.fullName}</TableCell>
            <TableCell>{player.role}</TableCell>
            <TableCell>{player.age}</TableCell>
            <TableCell>{player.nationality}</TableCell>
            <TableCell>{player.club}</TableCell>
            <TableCell>{player.league}</TableCell>
            <TableCell>
                <StarRatingComponent
                    name={player.id}
                    starCount={5}
                    value={player.player.marks.mean ? player.player.marks.mean : 0}
                    editing={false}/>
            </TableCell>
            <TableCell onClick={() => onClickDetail(player)} style={{ cursor: 'pointer' }}>Report</TableCell>
        </TableRow>
    )
}

export default Row;