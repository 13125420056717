import React, { Fragment, useState, useRef, useCallback } from 'react'
import { Box, LinearProgress, Typography, Grid } from '@material-ui/core'
import { useSelector } from 'react-redux'
import Requests from '../../core/requests'
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from "@material-ui/core/TextField";
import Avatar from '@material-ui/core/Avatar';
import { isArray } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { color } from '../../config/colors/colors'

const useStyles = makeStyles((theme) => ({
    flex: {
        display: 'flex',
        alignItems: 'center'
    },
    m3: {
        margin: '3px'
    },
    title: {
        fontSize: theme.spacing(3),
        color: color.title,
        fontWeight: 'bold',
        marginBottom: "20px"
    },
    fullWidth: {
        width: "100%"
    }
}));

const FilterAgencyByName = ({
    setAgencyId
}) => {

    const classes = useStyles();
    const { token } = useSelector(state => state.user)
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [timer, setTimer] = useState(null)
    const previousController = useRef();

    const handleChange = useCallback((event, newValue) => {
        let selectedValue;
        if (newValue) {
            if (isArray(newValue)) {
                selectedValue = newValue.map((item) => item.id)
            } else {
                selectedValue = newValue.id
            }
        }
        setAgencyId(selectedValue);
    }, []);
  
    const getData = useCallback((searchTerm) => {
        setLoading(true);
        if (previousController.current) {
            previousController.current.abort();
        }
        const controller = new AbortController();
        previousController.current = controller;

        Requests.agency.getAgenciesByName(({ token: token.access, agencyName: searchTerm })).then((data) => {
            setOptions(data);
            setLoading(false)
        }).catch(() => {
            setOptions([]);
            setLoading(false)
        })
    }, []);
    
    const onInputChange = (event, value) => {
        clearTimeout(timer)
        
        const newTimer = setTimeout(() => {
            if (value && value?.length >= 3) {
                getData(value);
            } else {
                setOptions([]);
            }
        }, 800)

        setTimer(newTimer)
    };

    return (
        <Fragment>
            <Box mt={2} className={classes.fullWidth}>
                {loading && <LinearProgress />}
                <Typography className={classes.title}>
                    Propose player to partner
                </Typography>
                <Typography>Search Agency</Typography>
                <Autocomplete
                    onChange={handleChange}
                    id="combo-box-demo"
                    options={options}
                    onInputChange={onInputChange}
                    getOptionLabel={(option) => `${option?.name}(${option?.country?.name})`}
                    renderInput={(params) => (
                        <TextField {...params} variant="outlined" />
                    )}
                    renderOption={(option) => (
                        <Grid item xs className={classes.flex}>
                            <Avatar src={option?.logo} className={classes.m3}/>
                            <Typography>{`${option?.name}(${option?.country?.name})`}</Typography>
                        </Grid>
                    )}
                />
            </Box>
        </Fragment>
    )
}

export default FilterAgencyByName