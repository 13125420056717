import React from 'react'
import { Box, Button } from '@material-ui/core'
import moment from 'moment'
import _ from 'lodash'
import DeleteIcon from '@material-ui/icons/Delete';
import { HeadCompany } from "../../conf/Auth.config";
import Linkify from 'react-linkify-always-blank'


const CommentComponent = ({ item, classes, disable, openConfirmDeletionPopup, user }) => {
    return (
        <Box>
            {user?.groups?.some(element => element.name === HeadCompany) &&
                <Box style={{ display: "inline" }}>
                    <Button disabled={disable} className={classes.deleteCommentButton} onClick={() => openConfirmDeletionPopup(item?.id)}>
                        <DeleteIcon />
                    </Button>
                </Box>}
            <Box style={{ display: "inline" }}>
                <Linkify properties={{
                    target: '_blank'
                }}>
                    <Box component="span" style={{ fontWeight: 'bold' }}>{item.user ? `${item.user.first_name} ${item.user.last_name} ` : 'Utente anonimo '}</Box>
                    {item.is_operator_text && 'ha commentato '}: {item.text}
                    <Box component="span" style={{ fontWeight: 'bold' }}>{item.users?.length > 0 && item.users.map(user => ` @${user.first_name} ${user.last_name}`)}</Box>
                    <Box component="span" style={{ color: 'grey', fontStyle: 'italic' }}> {moment(item.created_at).format('DD/MM/YYYY')}</Box>
                </Linkify>
            </Box>
        </Box >
    )
}

export default CommentComponent;