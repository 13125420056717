import React, { Fragment, useCallback, useState } from 'react'
import Loading from '../../components/progress/Progress.component'
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import Requests from '../../core/requests'
import { PlayerStatusInitialValues, PlayerStatusValidationShema } from '../../conf/Player.config';
import PlayerStatusComponent from '../../components/players/PlayerStatus.component';
import CallOutcomeComponent from '../../components/club_partnership/CallOutcome.component'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import { color } from '../../config/colors/colors'

const useStyles = makeStyles(theme => ({
    centeredTitle: {
        fontSize: theme.spacing(3),
        color: color.title,
        fontWeight: 'bold',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        textAlign: 'center'
    }
}));

const PlayerStatusContainer = ({
    player,
    data,
    loading,
    mutate
}) => {

    const { token } = useSelector(state => state.user)
    const classes = useStyles();
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(null)

    const onSubmit = (values, actions) => {
        let expiration_date = null;
        if(values?.expiration_date){
            expiration_date = values?.expiration_time ? `${values.expiration_date} ${values.expiration_time}` : `${values.expiration_date} 23:59`;
        }
        actions.setSubmitting(true)
        Requests.players.assistedPlayerStatus({ token: token.access, id: player }, {
            player_status: values.player_status,
            work_status: values.work_status,
            operators: values.usersInvolved,
            note: values.note,
            ...(expiration_date && { expiration_date }),
        })
            .then((data) => onSuccess(data, actions))
            .catch((error) => onFailure(error, actions))
    }

    const onSuccess = useCallback((data, actions) => {
        mutate();
        setError(null)
        setSuccess("Player update completed successfully")
        actions.setSubmitting(false)
    }, [])

    const onFailure = useCallback((error, actions) => {
        setError(error?.response?.data?.error || "Error")
        actions.setSubmitting(false)
    }, [])

    return (
        <Fragment>
            <CallOutcomeComponent success = {success} error = {error} />
            {loading ? <Loading /> : null}
            <Grid item xs>
                <Typography className={classes.centeredTitle}>
                    Status
                </Typography>
            </Grid>
            <Grid item xs={12}>
                {data && <Formik
                    initialValues={PlayerStatusInitialValues(data)}
                    validationSchema={PlayerStatusValidationShema}
                    onSubmit={onSubmit}>
                    {(props) => <PlayerStatusComponent {...props} />}
                </Formik>}
            </Grid>
        </Fragment>
    )
}

export default PlayerStatusContainer