import React, { Fragment } from 'react'
import FilterAutocompleteComponent from '../../components/filter/FilterAutocomplete.component'

const CalendarActivityTypeContainer = ({
    onChange = () => { },
    name,
    ...props
}) => {

    const activityTypeData = [
        {
            id: "Match",
            name: "Match"
        },{
            id: "Meeting",
            name: "Meeting"
        }
    ];

    const getSelectedValue = () => {
        return activityTypeData.filter(element => element.id === props.values.type)[0];   
    }
    
    return (
        <Fragment>
            <FilterAutocompleteComponent {...props} name={name} value={getSelectedValue() || null} title='Type' options={activityTypeData} setFilter={(value) => onChange('type', value)} />
        </Fragment>
    )
}

export default CalendarActivityTypeContainer