import React, { Fragment, useState, useEffect, useCallback } from "react";
import AgenciesSectionComponent from "../../components/agency_partnership/AgenciesSection.component";
import { Grid, Typography, makeStyles, Button } from "@material-ui/core";
import useSWRRequest from "../../core/useSWRRequest";
import Requests from "../../core/requests";
import { useSelector } from "react-redux";
import Loading from "../../components/Loading";
import { AddCircleRounded } from '@material-ui/icons';
import Modal from '../../components/Modal'
import AddAgencyContainer from '../agency_partnership/AddAgency.container'
import ClubPartnershipTeamsContainer from "../club_partnership/ClubPartnershipTeams.container";
import { isNotMarketOperatorUser } from "../../utils/CheckLoggedUser";

const useStyles = makeStyles({
    title: {
        fontSize: '30px',
        fontFamily: 'Anton-Regular',
        color: '#761200'
    },
    icon: {
        width: 'auto',
        height: '2.5rem',
        '&:hover': {
            background: 'transparent'
        }
    }
})

const AgencyPartnershipContainer = ({ caller }) => {

    const setEndPoint = useCallback(() => {
        switch (caller) {
            case "baPortfolio":
                return Requests.ba_portfolio.agenciesList;
            case "partnerProposal":
                return Requests.partner_proposal.agenciesList;
            default:
                return Requests.agency_partnership.agenciesList;
        }
    }, [])

    const classes = useStyles();
    const { token, user } = useSelector((state) => state.user);
    const { data, loading, mutate } = useSWRRequest(setEndPoint(), { token: token.access });
    const [openCreate, setOpenCreate] = useState(null);
    const identifier = caller ? caller : "agencyPartnership";
    console.log('caller---->', caller)
    
    return (
        <Fragment>
            <Grid item xs={12}>
                {loading && <Loading />}
            </Grid>
            <Grid container>
                { isNotMarketOperatorUser(user?.groups) &&
                    <>
                        <Grid item xs style={{ marginBottom: "20px" }}>
                            <Typography className={classes.title}>
                                Add Agency
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button color="primary" component="span" onClick={() => setOpenCreate(true)}>
                                <AddCircleRounded className={classes.icon} />
                            </Button>
                        </Grid>
                    </>
                }
                <Modal
                    open={openCreate}
                    onClose={() => setOpenCreate(false)}
                    title='Add Agency'>
                    <AddAgencyContainer caller={caller} mutate={mutate} onClose={() => setOpenCreate(false)} />
                </Modal>
                <Grid item xs={12}>
                    {data.map((item) => (
                        <AgenciesSectionComponent identifier={identifier} countryId={item.country.id} key={item.country.id} title={item.country.name}>
                            <ClubPartnershipTeamsContainer identifier={identifier} elements={item.agencies} countryId={item.country.id} />
                        </AgenciesSectionComponent>
                    ))}
                </Grid>
            </Grid>
        </Fragment>
    );
}

export default AgencyPartnershipContainer