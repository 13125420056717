import React, { Fragment, useState, useCallback, useEffect } from 'react'
import {
    FormControl,
    Input,
    Tabs,
    Tab,
    Box,
    Divider,
    Typography,
    Select,
    MenuItem,
    Button,
    Chip,
    Grid
} from '@material-ui/core'
import useSWRRequest from '../../core/useSWRRequest'
import Requests from '../../core/requests'
import { useSelector } from 'react-redux'
import Loading from "../../components/Loading"
import TableList from "../../components/table/Table"
import moment from 'moment'
import { InboxRow } from "../../components/inbox/InboxRows"
import _ from 'lodash'
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmDeletionModalComponent from '../../components/club_partnership/ConfirmDeletionModal.component'
import { HeadCompany } from "../../conf/Auth.config";
import Linkify from 'react-linkify-always-blank'
import { style } from '../chat/Chat.container.style'
import { withStyles } from "@material-ui/core/styles";

const InboxContainer = ({classes}) => {

    const { token, user } = useSelector(state => state.user)
    const [tab, setTab] = useState(tabsList[0]);
    const { data, loading, mutate } = useSWRRequest(Requests.inbox[tab.endpoint], { token: token.access })
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [tagUsers, setTagUsers] = useState([])
    const [taggedUsers, setTaggedUsers] = useState([])
    const [comment, setComment] = useState('')
    const [error, setError] = useState(false)
    const [disable, setDisable] = useState(false);
    const [openCreate, setOpenCreate] = useState(false)
    const [selectedItemId, setSelectedItemId] = useState(false)

    useEffect(() => {
        mutate()
    }, [tab])

    // get tag users
    useEffect(() => {
        handleInboxRequest({ token }, 'tagUsers',
            (resp) => {
                setTagUsers(resp)
                setLoadingSubmit(false)
            },
            () => {
                //todo handle error
            })
    }, [])

    const saveComment = useCallback(() => {
        setLoadingSubmit(true)
        Requests.inbox[tab.saveEndpoint]({ token: token.access, users: taggedUsers.map(taggedUser => taggedUser.id), text: comment })
            .then(() => {
                setLoadingSubmit(false)
                setComment('')
                setTaggedUsers([])
                setError(null)
                mutate()
            })
            .catch((error) => {
                setLoadingSubmit(false)
                setError(error)
            })
    }, [taggedUsers, comment, data])

    const addTaggedUser = useCallback((tagUser) => {
        if (_.includes(taggedUsers, tagUser) === false) {
            setTaggedUsers([...taggedUsers, tagUser])
        }
        setComment(comment)
    }, [taggedUsers, comment])

    const handleChange = useCallback((event, selectedTab) => {
        setError(false)
        setTab(tabsList[selectedTab]);
    }, []);

    const handleDelete = useCallback((item) => {
        setError(false)
        setTaggedUsers((chips) => chips.filter((chip) => chip !== item));
    }, [taggedUsers, setTaggedUsers])

    const tagBaUsers = useCallback(() => {
        Requests.users.baUsers({
            token: token.access,
        }).then((response) => {
            setTaggedUsers(response)
        }).catch((error) => {
            setError(error?.response?.data?.error || "error")
        })

    }, [])

    const deleteElement = useCallback((id) => {
        if (id) {
            setDisable(true);
            Requests.inbox[tab.deleteItemEndPoint]({ token: token.access, id })
                .then(() => {
                    enableDeleteButtonAndMutate()
                })
                .catch((error) => {
                    setError(error);
                    enableDeleteButtonAndMutate()
                })
        }
    }, [tab])

    const enableDeleteButtonAndMutate = useCallback(() => {
        setOpenCreate(false);
        setDisable(false);
        mutate();
    }, [tab])

    const openConfirmDeletionPopup = useCallback((id) => {
        setOpenCreate(true);
        setSelectedItemId(id);
    }, [])

    return (
        <Fragment>
            <ConfirmDeletionModalComponent openCreate={openCreate} setOpenCreate={setOpenCreate} deleteFunction={() => deleteElement(selectedItemId)} />
            <Box width={"100%"}>
                <Tabs value={tab.id} onChange={handleChange} >
                    {tabsList.map(tab => <Tab key={tab.id} label={tab.name} />)}
                </Tabs>
                <Box mt={2}>
                    {loadingSubmit || loading && <Loading />}
                    {
                        data?.length > 0 && <TableList count={ data?.length } items={data} page={0} rowsPerPage={10} enableSearch={false} rowsPerPageOptions={[5, 10, 25, 50]}>
                            {(item) => <InboxRow key={item.id}><CommentComponent item={item} classes={classes} disable={disable} openConfirmDeletionPopup={openConfirmDeletionPopup} user={user} /></InboxRow>}
                        </TableList>
                    }
                </Box>
                <Divider />
                <Typography variant={"h5"} className={classes.comment}>Leave a comment</Typography>
                <FormControl className={classes.input} onClick={() => setError(false)}>
                    <Input multiline={true} value={comment} onChange={(event) => setComment(event.target.value)} />
                    <Box dispaly="flex">
                        {taggedUsers?.map((item, index) => <Chip
                            key={index}
                            label={` @${item['first_name']} ${item['last_name']}`}
                            variant="outlined"
                            onDelete={() => handleDelete(item)}
                            id={item.id}
                            className={classes.chip}
                        />)}
                    </Box>
                </FormControl>
                <Grid container>
                    <Grid container item xs={12} sm={12} md={7} lg={5}>
                        <Grid item xs={2}>
                            <Typography variant={"subtitle1"} className={classes.comment}>Tag users</Typography>
                        </Grid>
                        <Grid item xs={10} align="center">
                            <Box height={1} display="flex" alignItems="center" justifyContent="flex-start">
                                <Select
                                    value={''}
                                    onChange={(event) => addTaggedUser(event.target.value)}
                                    onClick={() => setError(false)}
                                    className={classes.selectTag}
                                    variant={"outlined"}
                                    inputProps={{
                                        classes: { select: classes.select },
                                    }}>
                                    {tagUsers.map((tagUser, index) => <MenuItem key={index} value={tagUser} className={classes.tagUserStyle} >{`${tagUser['first_name']} ${tagUser['last_name']}`}</MenuItem>)}
                                </Select>
                            </Box>

                        </Grid>
                    </Grid>
                    <Box ml={1} mt={3} height={1} display="flex" alignItems="center" >
                        <Button
                            color='primary'
                            size="large"
                            variant="contained"
                            onClick={() => setTaggedUsers(tagUsers)}
                        >
                            Tagga tutti
                        </Button>
                    </Box>
                    <Box ml={1} mt={3} height={1} display="flex" alignItems="center" justifyContent="flex-start">
                        <Button
                            color='primary'
                            size="large"
                            variant="contained"
                            onClick={() => tagBaUsers()}
                        >
                            Tagga BA
                        </Button>
                    </Box>
                </Grid>

                {error && <Box align="right" mb={1} className={classes.error}>Si è verificato un errore. Si prega di riprovare.</Box>}
                <Box className={classes.buttonComment}>
                    <Button
                        color={"secondary"}
                        size="large"
                        variant="contained"
                        onClick={saveComment}
                    >
                        Add comment
                    </Button>
                </Box>

            </Box>
        </Fragment>
    )
}

export default withStyles(style)(InboxContainer);

const handleInboxRequest = ({ token, payload }, endpoint, callback, onError) => {
    Requests.inbox[endpoint]({ token: token.access, ...payload })
        .then((resp) => {
            callback(resp)
        })
        .catch((error) => {
            onError(error)
        })
}

const CommentComponent = ({ item, classes, disable, openConfirmDeletionPopup, user }) => (
    <Box>
        {user?.groups?.some(element => element.name === HeadCompany) &&
            <Box style={{ display: "inline" }}>
                <Button disabled={disable} className={classes.deleteCommentButton} onClick={() => openConfirmDeletionPopup(item?.id)}>
                    <DeleteIcon />
                </Button>
            </Box>}
        <Box style={{ display: "inline" }}>
            <Linkify properties={{
                target: '_blank'
            }}>
                <Box component="span" style={{ fontWeight: 'bold' }}>{item.user ? `${item.user.first_name} ${item.user.last_name} ` : 'Utente anonimo '}</Box>
                {item.is_operator_text && 'ha commentato '}: {item.text}
                <Box component="span" style={{ fontWeight: 'bold' }}>{item.users?.length > 0 && item.users.map(user => ` @${user.first_name} ${user.last_name}`)}</Box>
                <Box component="span" style={{ color: 'grey', fontStyle: 'italic' }}> {moment(item.created_at).format('DD/MM/YYYY')}</Box>
            </Linkify>
        </Box>
    </Box >
)

const tabsList = [
    {
        name: 'Chat',
        id: 0,
        endpoint: 'chat',
        saveEndpoint: 'saveChat',
        deleteItemEndPoint: 'deleteChatItem'
    },
    {
        name: 'Calendar',
        id: 1,
        endpoint: 'calendar',
        saveEndpoint: 'saveCalendar',
        deleteItemEndPoint: 'deleteCalendarItem'
    },
    {
        name: 'Mandates',
        id: 2,
        endpoint: 'mandates',
        saveEndpoint: 'saveMandates',
        deleteItemEndPoint: 'deleteMandatesItem'
    },
    {
        name: 'Club Request',
        id: 3,
        endpoint: 'clubRequest',
        saveEndpoint: 'saveClubRequest',
        deleteItemEndPoint: 'deleteClubRequestItem'
    }
]
