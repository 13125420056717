import React, { useCallback, Fragment, useState } from 'react'
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { color } from '../../config/colors/colors'
import { Box } from '@material-ui/core'
import Requests from '../../core/requests'
import DeleteIcon from '@material-ui/icons/Delete';
import CallOutcomeComponent from '../../components/club_partnership/CallOutcome.component'
import ConfirmDeletionModalComponent from '../../components/club_partnership/ConfirmDeletionModal.component'
import { isNotMarketOperatorUser } from '../../utils/CheckLoggedUser';

const useStyles = makeStyles((theme) => ({
    subtitle: {
        fontSize: theme.spacing(2),
        color: color.black,
        fontWeight: 'bold',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    link: {
        cursor: 'pointer',
        overflowWrap: "anywhere"
    },
    linkItem: {
        marginBottom: "8px"
    },
    deleteIcon: {
        cursor: "pointer"
    }
}));


const LinksContainer = ({
    links,
    mutate,
    identifier,
    fullWidth
}) => {

    const classes = useStyles();
    const { token, user } = useSelector(state => state.user)
    const [openCreate, setOpenCreate] = useState(false)
    const [error, setError] = useState(null)

    let selectedLinkId = 0;


    const openReportLink = useCallback((url) => {
        window.open(url, '_blank');
    }, [])

    const setEndPoint = useCallback(() => {

        switch (identifier) {
            case "monitoring":
            case "partnerPlayer":
            case "proposal":
                return Requests.reports.deleteLink;
            case "club":
            case "agency":
                return Requests.mandate_reports.deleteLink;
            case "countryAttachments":
                return Requests.reports.deleteCountryLink;
            case "competitionAttachments":
                return Requests.reports.deleteCompetitionLink;
            case "partnerProposal":
                return Requests.mandate_reports.deleteProposalLink;
            case "proposalList":
                return Requests.mandate_reports.deleteTeamLink;
            case "baPortfolio":
                return Requests.mandate_reports.deleteBaMandateLink;
        }
    }, [])

    const deleteLink = useCallback((id) => {
        const deleteFunction = setEndPoint();
        deleteFunction({
            token: token.access,
            link_id: selectedLinkId
        }).then(() => {
            mutate();
            setOpenCreate(false);
        }).catch((error) => {
            setError(error?.response?.data?.error)
        })
    }, [])

    const confirmDeleteLink = useCallback((id) => {
        selectedLinkId = id;
        setError(null);
        setOpenCreate(true);
    }, [])

    return (
        <Fragment>
            <Grid item xs={fullWidth ? 12 : 6}>
                <Typography className={classes.subtitle}>
                    Links
                </Typography>
                {links?.map((link) =>
                    <Box key={link.id} className={classes.linkItem} display='flex' alignItems='center'>
                        {isNotMarketOperatorUser(user?.groups) && <DeleteIcon className={classes.deleteIcon} onClick={() => confirmDeleteLink(link.id)} />}
                        <Typography key={link.id} onClick={() => openReportLink(link.url)} className={classes.link}>
                            {link.name ? link.name : link.url}
                        </Typography>
                    </Box>
                )
                }
            </Grid>
            <ConfirmDeletionModalComponent openCreate={openCreate} setOpenCreate={setOpenCreate} deleteFunction={deleteLink}>
                <CallOutcomeComponent error={error} />
            </ConfirmDeletionModalComponent>
        </Fragment>
    )
}

export default LinksContainer
