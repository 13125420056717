
export const color = {
    black:'#000000',
    white:'#FFFFFF',
    grey: "#F4F4F4",
    primary: {
        main: "#2C5364"
    },
    secondary: {
        main: "#0F2027"
    },
    other: {
        main: "#182327",
        cards:"#223239",
        selected: '#158E98'
    },
    elite:{
        main:'#986F21',
        other:'#0F2027',
        selected: '#986121'
    },
    title: '#761200',
    subtitle:'#747474',
    charts: '#C6D6DD',

}
