import React, { Fragment, useState, useEffect, useCallback } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import useSWRRequest from "../../core/useSWRRequest";
import Requests from "../../core/requests";
import { useSelector } from "react-redux";
import Loading from "../../components/Loading";
import FilesContainer from '../../containers/club_partnership/Files.container';
import LinksContainer from '../../containers/club_partnership/Links.container';
import { color } from '../../config/colors/colors'
import MandateAddFileContainer from '../club_partnership/MandateAddFile.container';
import MandateAddLinkContainer from '../club_partnership/MandateAddLink.container';

const useStyles = makeStyles((theme) => ({   
    title: {
        fontSize: '30px',
        fontFamily: 'Anton-Regular',
        color: '#761200'
    },
    icon: {
        width: 'auto',
        height: '2.5rem',
        '&:hover': {
            background: 'transparent'
        }
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        backgroundColor: color.primary.main,
        height: theme.spacing(0.2)
    }
}))

const CountryAttachmentsContainer = ({ nationality, competition }) => {

    const classes = useStyles();
    const { token } = useSelector((state) => state.user);
    const { data, loading, mutate } = useSWRRequest(competition ? Requests.competitions.getCompetition : Requests.areas.getCountry , { token: token.access, ...(nationality && { countryId: nationality  }), ...(competition && { competitionId: competition  })});
    const [openCreate, setOpenCreate] = useState(false);
    const identifier = competition ? "competitionAttachments" : "countryAttachments";

    useEffect(() => {
        refreshComponent();
    }, [openCreate])

    const refreshComponent = useCallback(() => {
        if (!openCreate) {
          mutate()
        }
    }, [])

    return (
        <Fragment>
            {loading && <Loading />}
            <Grid container item xs={12} alignItems='center'>
                <MandateAddFileContainer identifier = {identifier} nationality = {nationality} mutate= {mutate} competition = {competition} />
            </Grid>
            <FilesContainer identifier = {identifier} fullWidth = {true} mutate={mutate} files={data.files || null}/>

            <Grid className={classes.divider} item xs={12} />

            <Grid container item xs={12}>
                <MandateAddLinkContainer identifier = {identifier} nationality = {nationality} competition = {competition} mutate= {mutate}/>
            </Grid>
            <LinksContainer fullWidth={true} identifier = {identifier} links = {data?.links} mutate= {mutate}/>
        </Fragment>
    );
}

export default CountryAttachmentsContainer