import React, { Fragment, useCallback, useState, useEffect } from 'react'
import { Box, LinearProgress, Typography } from '@material-ui/core'
import FilterAutocompleteComponent from '../../components/filter/FilterAutocomplete.component'
import { useSelector } from 'react-redux'
import Requests from '../../core/requests'

const TeamContainer = ({
    onChange = () => { },
    countries,
    loading,
    country,
    team,
    values,
    isMarketOperatorUser,
    addSinglePlayer,
    ...props
}) => {

    const { token } = useSelector(state => state.user)
    const [players, setPlayers] = useState(null)
    const [teams, setTeams] = useState(false)
    const [loadingTeams, setLoadingTeams] = useState(false)
    const [isSearchingPlayers, setIsSearchingPlayers] = useState(false)

    useEffect(() => {
        if(values?.team != team || values?.country != country){
            values.players = [];
            values.team = null;
        }
        if(values?.country){
            searchTeamsByCountry(values?.country);
        }
    }, [values.country]);

    useEffect(() => {
        if(values?.team){
            searchPlayers("team", values?.team);
        }
    }, [values.team]);

    useEffect(() => {
        let newPlayers = players?.map(item => item.id) || [];
        if(values.players && values.players.length && newPlayers && newPlayers.length && !newPlayers.some(player => values.players.includes(player))){
            values.players = [];
        }
    }, [players]);

    const searchTeamsByCountry = useCallback((countryId) => {
        setLoadingTeams(true)
        if(countryId){
            Requests.teams.teamsByCountry({ token: token.access, countryId}).then((data) => {
                setTeams(data.map((item) => ({
                    id: item.id,
                    name: item.name
                })))
                setLoadingTeams(false)
            }).catch((error) => {
                setLoadingTeams(false)
            })
        }
    }, []);

    const searchPlayers = useCallback((key, teamId) => {
        setIsSearchingPlayers(true)
        if(key && teamId){
            onChange(key, teamId)
            Requests.players.playersByTeam({ token: token.access, teamId: teamId}).then((data) => {
                setPlayers(data.map((item) => ({
                    id: item.id,
                    name: item.full_name
                })))
                setIsSearchingPlayers(false)
            }).catch((error) => {setIsSearchingPlayers(false)})
        }
    }, []);

    const setCountry = () => {
        if(values.country && !loading && countries){
            return countries.filter(country => country.id == values.country)[0];
        }
    };

    const setTeam = () => {
        if(values.team && !loadingTeams && teams){
            return teams.filter(team => team.id == values.team)[0];
        }
    };

    const setSelectPlayers = () => {
        if(addSinglePlayer && values.players){
            return players.filter(player => player.id == values.players)[0];
        }
        if(!addSinglePlayer && values.players && players && players.length){
            return players.filter(player => values.players.some(id => player.id === id));
        }  
    }

    return (
        <Fragment>
            <Box mt={2}>
                <Typography>Search players by country</Typography>
                {loading ? <LinearProgress /> : null}
                <FilterAutocompleteComponent {...props} disabled={isMarketOperatorUser ? isMarketOperatorUser() : false} value={setCountry() || []} options={countries} setFilter={(value) => onChange('country', value)} name="country" />
            </Box>
            {loadingTeams ? <LinearProgress /> : null}
            {values?.country && teams?.length && !loadingTeams ?
                <Box mt={2}>
                    <Typography>Search players by team</Typography>
                    <FilterAutocompleteComponent {...props} disabled={isMarketOperatorUser ? isMarketOperatorUser() : false} value={setTeam() || []} options={teams} setFilter={(value) => onChange('team', value)} name="team"/>
                </Box> : null}
            {isSearchingPlayers ? <LinearProgress /> : null}
            {players && values.team && values.country && !isSearchingPlayers ?
                <Box mt={2}>
                    <Typography>Players</Typography>
                    <FilterAutocompleteComponent {...props} disabled={isMarketOperatorUser ? isMarketOperatorUser() : false} value={setSelectPlayers() || []} multiple={addSinglePlayer ? false : true} options={players} setFilter={(value) => onChange('players', value)} name="players"/>
                </Box> : null}
        </Fragment>
    )
}

export default TeamContainer