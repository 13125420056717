import React from 'react'
import MainLayout from '../../layout/main/Main.layout'
import AgencyMandatesContainer from '../../containers/agency_partnership/AgencyMandates.container'

const AgencyMandatesPage = () => {

    return (
        <MainLayout title='Agency Mandates' logo={true}>
            <AgencyMandatesContainer caller = "agencyPartnership" />
        </MainLayout>
    )
}

export default AgencyMandatesPage