import { color } from '../../config/colors/colors.js';

export const style = theme => ({
    root:{
        backgroundColor: color.grey,  
    },
    link: {
        textDecoration:'none'
    },
    title:{
        color:'#16282f',
        fontWeight:'bold',
        fontSize:'30px'
    }
})