import React, { Fragment, useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import {Grid, Box, LinearProgress, Button} from '@material-ui/core';
import { color } from '../../config/colors/colors'
import { makeStyles } from '@material-ui/styles';
import ContentLayout from '../../layout/content/Content.layout'
import useSWRRequest from '../../core/useSWRRequest'
import Requests from '../../core/requests'
import { useSelector } from 'react-redux'
import MandateStatusContainer from './MandateStatus.container'
import MandateAddFileContainer from '../club_partnership/MandateAddFile.container';
import MandateAddLinkContainer from '../club_partnership/MandateAddLink.container';
import MandateOverviewContainer from './MandateOverview.container';
import FilesContainer from '../club_partnership/Files.container';
import LinksContainer from '../club_partnership/Links.container';
import Modal from '../../components/Modal';
import CallOutcomeComponent from '../../components/club_partnership/CallOutcome.component';
import { Partner } from "../../conf/Auth.config";
import { isNotMarketOperatorUser } from '../../utils/CheckLoggedUser';
import ChatContainer from '../chat/Chat.container';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: color.grey,
    },
    divider: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(5),
        height: theme.spacing(0.2),
        backgroundColor: color.primary.main,
        width: theme.spacing(14)
    },
    fullWidth: {
        height: "42px",
        width: "100%",
        backgroundColor: "rgb(30, 58, 70)"
    }
}));

const AgencyMandateContainer = ({ caller }) => {

    const setEndPoint = useCallback(() => {
        switch(caller) {
            case "baPortfolio":
              return Requests.ba_portfolio.getMandate;
            case "partnerProposal":
                return Requests.partner_proposal.getProposal;
            default:
                return Requests.agency_partnership.getMandate;
          }
    }, [])


    const setCommentsEndPoint = useCallback(() => {
        switch(caller) {
            case "baPortfolio":
              return Requests.ba_portfolio.getCommentsByMandate;
            case "partnerProposal":
                return Requests.partner_proposal.getCommentsByProposal;
            default:
                return Requests.agency_partnership.getCommentsByMandate;
        }
    }, [])

    const classes = useStyles();
    const { id } = useParams()
    const { token, user } = useSelector(state => state.user)
    const { data, loading, mutate } = useSWRRequest(setEndPoint(), { token: token.access, id: id })
    const [conversionLoading, setConversionLoading] = useState(false)
    const [error, setError] = useState(null)
    const [openModal, setOpenModal] = useState(null)
    const [conversionSuccessMessage, setConversionSuccessMessage] = useState(null)
    
    const identifier =  caller ? caller : "agency";

    const { data: comments, mutate: mutateComments } = useSWRRequest(setCommentsEndPoint(), { token: token.access, mandateId: id })

    const convertProposalToMandate = useCallback((proposalId) => {
        setConversionSuccessMessage(null);
        setError(null);
        setConversionLoading(true)
        Requests.partner_proposal.convertProposalToMandate(({
            token: token.access,
            partner_proposal: proposalId,
        })).then(() => {
            setConversionLoading(false)
            setConversionSuccessMessage("Conversion done successfully")
            setOpenModal(true);
        }).catch((error) => {
            setConversionLoading(false)
            setError(error?.response?.data?.error)
            setOpenModal(true);
        }) 
    }, [])

    const isNotPartnerUser = useCallback(() => {
        return user?.groups?.find(element => element.name !== Partner);
    }, [])

    return (
        <Fragment>
            {loading && <LinearProgress />}
            <Grid container className={classes.root}>
                <ContentLayout>
                    <Box marginTop={2} marginBottom={8}>
                        <MandateOverviewContainer identifier = {identifier} mandate={data} />
                    </Box>
                </ContentLayout>
            </Grid>

            <ContentLayout>
                {isNotMarketOperatorUser(user?.groups) &&
                    <Grid container item xs={12} alignItems='center'>
                        <MandateAddFileContainer identifier = {identifier} mandateId={id} mutate= {mutate} />
                        <MandateAddLinkContainer identifier = {identifier} mandateId={id} mutate= {mutate}/>
                    </Grid>
                }

                <Grid container item xs={12}>
                    <FilesContainer identifier = {identifier} files = {data?.files} mutate= {mutate} />
                    <LinksContainer identifier = {identifier} links = {data?.links} mutate= {mutate}/>
                </Grid>

                <Grid className={classes.divider} item xs={12} />

                <Grid container item xs={12} alignItems='center'>
                    <MandateStatusContainer identifier = {identifier} mandate={data} mutate= {mutate}/>
                </Grid>
                {identifier === "partnerProposal" && isNotPartnerUser() ?
                    <Grid xs={12}>
                        <Button disabled={conversionLoading} onClick={() => convertProposalToMandate(id)} className={classes.fullWidth} variant="contained" type='submit' color="primary">
                            Converti in mandato
                        </Button>
                    </Grid> : null 
                }

                <Grid className={classes.divider} item xs={12} />

                <Grid container item xs={12} alignItems='center'>
                    <ChatContainer data={comments} identifier = {identifier} id = {id} mutate = {mutateComments}/>
                </Grid>
                
                <Modal
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                    title="Conversion Outcome">
                    <CallOutcomeComponent success = {conversionSuccessMessage} error = {error} />
                </Modal>
            </ContentLayout>
        </Fragment>
    )
    
}

export default AgencyMandateContainer