import React from "react";
import MainLayout from "../../layout/main/Main.layout";
import ClubProposalContainer from '../../containers/club_proposal/ClubProposal.container'
import ContentLayout from '../../layout/content/Content.layout'

const EliteClubProposalPage = () => {

    return (
        <MainLayout title='Elite Club Proposal' logo={true}>
            <ContentLayout>
                <ClubProposalContainer identifier = {"eliteClubProposal"}/>
            </ContentLayout>
        </MainLayout>
    )
};

export default EliteClubProposalPage;
