import React, { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { color } from '../../config/colors/colors'
import { makeStyles } from '@material-ui/styles';
import ContentLayout from '../../layout/content/Content.layout'
import { Grid, Typography } from '@material-ui/core'
import useSWRRequest from '../../core/useSWRRequest'
import Requests from '../../core/requests'
import { useSelector } from 'react-redux'
import UpdateActivityContainer from '../../containers/activity/UpdateActivity.container.js'
import DeleteActivityContainer from '../../containers/activity/DeleteActivity.container.js'
import CommentsActivityContainer from '../../containers/activity/CommentsActivity.container.js'
import AddCommentContainer from '../../containers/activity/AddComment.container.js'
import FilesContainer from '../club_partnership/Files.container';
import CallOutcomeComponent from '../../components/club_partnership/CallOutcome.component'
import ActivityAddFileContainer from '../../containers/activity/ActivityAddFile.container.js'
import ChatContainer from '../../containers/chat/Chat.container';


const useStyles = makeStyles(theme => ({
    centeredTitle: {
        fontSize: theme.spacing(3),
        color: color.title,
        fontWeight: 'bold',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        textAlign: "center"
    },
    divider: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        height: theme.spacing(0.2),
        backgroundColor: color.primary.main,
        width: theme.spacing(14)
    },
    title: {
        fontSize: theme.spacing(3),
        color: color.title,
        fontWeight: 'bold',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    icon: {
        width: 'auto',
        height: '2.5rem',
        '&:hover': {
            background: 'transparent'
        }
    }
}));


const ActivityContainer = () => {

    const classes = useStyles();
    const { id } = useParams()
    const { token } = useSelector(state => state.user)
    const { data, mutate } = useSWRRequest(Requests.activity.activities, { token: token.access, id })
    const [error, setError] = useState(null)
    const identifier = "activity";

    const refreshActivity = useCallback(() => {
        mutate()
    }, [])

    const onSubmit = useCallback((values) => {
        Requests.mandate_reports.addActivityFile({
            calendar_activity: parseInt(values.calendar_activity),
            file: values.file,
            token: token.access
        }).then(() => {
            mutate()
        }).catch((error) => {
            setError(error?.response?.data?.error)
        })
    }, [])

    return (
        <ContentLayout>
            <Grid container item xs={12} alignItems='center'>
                <Grid item xs={12}>
                    <Typography className={classes.centeredTitle}> Activity Detail </Typography>
                    <UpdateActivityContainer activityId={id}/>
                    <DeleteActivityContainer activityId={id}/>
                </Grid>
                
                <Grid className={classes.divider} item xs={12} />

                <CallOutcomeComponent error = {error} />
                <ActivityAddFileContainer onSubmit = {onSubmit} activityId = {id} />
                
                <Grid item xs={12}>
                    <FilesContainer mutate={refreshActivity} files={data.files || null} identifier = {identifier} />
                </Grid>
                
                {/* <Grid className={classes.divider} item xs={12} />

                <Grid item xs={12}>
                    <Typography className={classes.centeredTitle}> Comments </Typography>
                    <CommentsActivityContainer activityId={id} comments = {data.comments} refreshActivity = {refreshActivity} />
                </Grid> */}

                <Grid className={classes.divider} item xs={12} />
                
                <ChatContainer data={data.comments} identifier = {identifier} id = {id} mutate = {mutate}/>

            </Grid>
            {/* <Grid item xs={12}>
                <AddCommentContainer activityId = {id} refreshActivity = {refreshActivity} />
            </Grid> */}

        </ContentLayout>
    )
}

export default ActivityContainer