import { color } from '../../config/colors/colors.js';

export const style = theme => ({
    root: {
        backgroundColor: color.grey,
        margin: "0px 0px 24px 0px",
        padding: theme.spacing(3),
        width: '100%',
        boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.16)'
    },
    icon: {
        width: theme.spacing(5),
        height: theme.spacing(5),
    },
    title: {
        fontSize: theme.spacing(2.5),
        color: color.primary.main,
        fontWeight: 'bold',
        fontFamily: 'Anton-Regular',
        letterSpacing: "1.4px",
        marginInlineStart: "0.2rem"
    },
    pointer: {
        cursor: 'pointer'
    }

});