import React, { Fragment, useMemo } from 'react'
import { Grid, Typography } from '@material-ui/core';
import PlayerThumbComponent from '../../components/players/PlayerThumb.component';
import Loading from '../../components/Loading'

const MonitoringPlayersContainer = ({
    position,
    data,
    loading,
    identifier,
    openDetail = () => { }
}) => {

    const players = useMemo(() => {
        if (data.length > 0) {
            if(identifier === "partnerPlayer"){
                return data.filter((e) => e.roles[0].position === position.id)
            }else{
                return data.filter((e) => e.player.roles[0].position === position.id)
            }
        }
        return []
    }, [data])

    return (
        <Fragment>
            <Grid container>
                <Grid xs={12}>
                    {loading ? <Loading /> : null}
                </Grid>
                {players.map((player) =>
                    <Grid key={player.id} item xs={6} sm={4} md={2} onClick={() => openDetail(player)}>
                        <PlayerThumbComponent data={player} identifier={identifier}/>
                    </Grid>
                )}
                {players.length === 0 &&  !loading? <Typography style={{ padding: '2rem' }}>Nessun giocatore</Typography> : null}
            </Grid>
        </Fragment>
    )
}

export default MonitoringPlayersContainer