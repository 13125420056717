import React, { Fragment } from 'react'
import { Box, LinearProgress, Typography } from '@material-ui/core'
import FilterAutocompleteComponent from '../../components/filter/FilterAutocomplete.component'
import { useSelector } from 'react-redux'
import useSWRRequest from '../../core/useSWRRequest'
import Requests from '../../core/requests'

const CountryContainer = ({
    onChange = () => { },
    ...props
}) => {

    const { token } = useSelector(state => state.user)
    const { data, loading } = useSWRRequest(Requests.areas.countries, { token: token.access })

    return (
        <Fragment>
            <Box mt={2}>
                <Typography>Country</Typography>
                {loading ? <LinearProgress /> : null}
                <FilterAutocompleteComponent {...props} options={data} setFilter={(value) => onChange('country', value)} name="country" />
            </Box>
        </Fragment>
    )
}

export default CountryContainer