import React, { useState } from 'react';
import Row from './ClubPartnershipRow.component';
import TableList from '../../components/table/Table';

const headCells = [
    {
        id: 'team', label: 'Team', sortable: false
    },
    {
        id: 'players', label: 'Players', sortable: false
    },
    {
        id: 'status', label: 'Status', sortable: false
    },
    {
        id: 'userInvolved', label: 'User involved', sortable: false
    },
    {
        id: 'deadline', label: 'Deadline', sortable: false
    },
    {
        id: 'delete', label: '', sortable: false
    },
    {
        id: 'detail', label: '', sortable: false
    },
]

const headCellsPartnerProposal = [
    {
        id: 'team', label: 'Team', sortable: false
    },
    {
        id: 'players', label: 'Players', sortable: false
    },
    {
        id: 'userInvolved', label: 'User involved', sortable: false
    },
    {
        id: 'age', label: 'Age', sortable: false
    },
    {
        id: 'position', label: 'Position', sortable: false
    },
    {
        id: 'country', label: 'Country', sortable: false
    },
    {
        id: 'delete', label: '', sortable: false
    },
    {
        id: 'detail', label: '', sortable: false
    },
]

const ClubPartnershipList = ({
    items = [],
    onAddAction = () => { },
    onSelectItem = () => { },
    onDeleteItem = () => { },
    caller,
    checkIsNotMarketOperatorUser
}) => {

    const [searchValue, setSearchValue] = useState('')
    const filterItems = (items) => items?.filter((item) => item?.team?.name?.toLowerCase().includes(searchValue?.toLowerCase()) || item?.players?.some(e => e?.full_name?.toLowerCase().includes(searchValue)))

    return (
        <TableList 
            add={checkIsNotMarketOperatorUser ? checkIsNotMarketOperatorUser() : true}
            headCells={caller === "partnerProposal" ? headCellsPartnerProposal : headCells}
            items={filterItems(items)}
            count={filterItems(items)?.length}
            page={0}
            rowsPerPage={25}
            setSearchValue={setSearchValue} addAction={onAddAction}>
            {(item) => <Row checkIsNotMarketOperatorUser = {checkIsNotMarketOperatorUser} caller = {caller} key={item.id} item={item} onSelectItem={onSelectItem} onDeleteItem={onDeleteItem}/>}
        </TableList>
    );
};


export default ClubPartnershipList;