import React, { Fragment } from 'react'
import { useParams } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import { color } from '../../config/colors/colors'
import { makeStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box'
import ContentLayout from '../../layout/content/Content.layout'
import { LinearProgress } from '@material-ui/core'
import useSWRRequest from '../../core/useSWRRequest'
import Requests from '../../core/requests'
import { useSelector } from 'react-redux'
import MandateStatusContainer from './MandateStatus.container'
import MandateAddFileContainer from './MandateAddFile.container';
import MandateAddLinkContainer from './MandateAddLink.container';
import MandateOverviewContainer from './MandateOverview.container';
import FilesContainer from './Files.container';
import LinksContainer from './Links.container';
import ChatContainer from '../chat/Chat.container';


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: color.grey,
    },
    divider: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(5),
        height: theme.spacing(0.2),
        backgroundColor: color.primary.main,
        width: theme.spacing(14)
    }
}));

const ClubMandateContainer = () => {

    const classes = useStyles();
    const { id } = useParams()
    const { token } = useSelector(state => state.user)
    const { data, loading, mutate } = useSWRRequest(Requests.club_partnership.getMandate, { token: token.access, mandateId: id })
    const { data: comments, mutate: mutateComments } = useSWRRequest(Requests.club_partnership.getCommentsByMandate, { token: token.access, mandateId: id })
    const identifier = "club";

    return (
        <Fragment>
            {loading && <LinearProgress />}
            <Grid container className={classes.root}>
                <ContentLayout>
                    <Box marginTop={2} marginBottom={8}>
                        <MandateOverviewContainer mandate={data} />
                    </Box>
                </ContentLayout>
            </Grid>

            <ContentLayout>

                <Grid container item xs={12} alignItems='center'>
                    <MandateAddFileContainer identifier = {identifier} mandateId={id} mutate= {mutate} />
                    <MandateAddLinkContainer identifier = {identifier} mandateId={id} mutate= {mutate}/>
                </Grid>

                <Grid container item xs={12}>
                    <FilesContainer identifier = {identifier} files = {data?.files} mutate= {mutate} />
                    <LinksContainer identifier = {identifier} links = {data?.links} mutate= {mutate}/>
                </Grid>

                <Grid className={classes.divider} item xs={12} />

                <Grid container item xs={12} alignItems='center'>
                    <MandateStatusContainer mandate={data} />
                </Grid>

                <Grid className={classes.divider} item xs={12} />

                <Grid container item xs={12} alignItems='center'>
                    <ChatContainer data={comments} identifier = {identifier} id = {id} mutate = {mutateComments}/>
                </Grid>
                
            </ContentLayout>
        </Fragment>
    )
    
}

export default ClubMandateContainer