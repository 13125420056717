import React, { useCallback, useMemo } from 'react'
import PlayerOverlayScoutingComponent from '../../components/players/PlayerOverlayScouting.component'
import { useSelector } from 'react-redux'
import useSWRRequest from '../../core/useSWRRequest'
import Requests from '../../core/requests'
import { HeadCompany, HeadScout } from '../../conf/Auth.config';

const ScoutingPlayerContainer = ({
    id,
    closeOverlay = () => { },
    open = false,
    selectArea = false,
    identifier
}) => {

    const { token, user } = useSelector(state => state.user)
    const { data, mutate, loading } = useSWRRequest(Requests.players.interestingPlayer, { token: token.access, id })

    const onSelect = useCallback(() => {
        Requests.players.interestingSelectFlag({ id, token: token.access }).then(() => mutate())
    }, [token, id])

    const onSelectElite = useCallback(() => {
        Requests.players.interestingSelectFlagElite({ id, token: token.access }).then(() => mutate())
    }, [token, id])

    const onEvaluationChange = useCallback((next) => {
        Requests.reports.mark({ token: token.access, player: id, mark: next }).then(() => mutate())
    }, [token, id])


    const elite = useMemo(() => {
        const groups = user.groups[0].name
        return HeadCompany.includes(groups) || HeadScout.includes(groups)
    }, [user])


    return (
        <PlayerOverlayScoutingComponent
            data={data}
            loading={loading}
            closeOverlay={closeOverlay}
            open={open}
            selectArea={selectArea}
            onSelect={onSelect}
            elite={elite}
            onSelectElite={onSelectElite}
            onEvaluationChange={onEvaluationChange}
            identifier = {identifier}
            playerId = {id}
            mutate = {mutate}
            />
    )
}

export default ScoutingPlayerContainer