import React, { useEffect, Fragment, useMemo, useState } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import _sortBy from "lodash/sortBy";
import ScoutingPlayersListSelectedComponent from "../../components/scouting/ScoutingPlayersListSelected.component";
import { useSelector } from "react-redux";
import _ from "lodash";
import qs from "qs";
import useSWRRequest from "../../core/useSWRRequest";
import Requests from "../../core/requests";

const ScoutingPlayersSelectedContainer = ({ open, filter, openOverlay }) => {
	const { token } = useSelector((state) => state.user);
	const [pageIndex, setPageIndex] = useState(1);
	const query = useMemo(
		() =>
			qs.stringify(
				_.pickBy({ ...filter, selected: "True" }, (value) => value !== null),
				{ encode: false }
			),
		[filter]
	);

	const {
		data: players,
		loading,
		mutate,
	} = useSWRRequest(Requests.players.interesting, {
		token: token.access,
		query: `?${query}`,
		pageIndex: `${pageIndex}`,
	});

	const {
		data: rawData,
		loading: loadingData,
		mutate: mutateData,
	} = useSWRRequest(Requests.players.interestingRaw, {
		token: token.access,
		query: `?${query}`,
		pageIndex: `${pageIndex}`,
	});

	useEffect(() => {
		if (!open) {
			mutate();
			mutateData();
		}
	}, [open, pageIndex]);

	return (
		<Fragment>
			{loading && filter ? <LinearProgress /> : null}
			<ScoutingPlayersListSelectedComponent
				onClickDetail={openOverlay}
				players={players}
				rawData={rawData}
				pageIndex={pageIndex}
				setPageIndex={setPageIndex}
			/>
		</Fragment>
	);
};

export default ScoutingPlayersSelectedContainer;
