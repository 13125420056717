export const style = theme => ({
    toolbar: {
        backgroundColor: '#182327',
        padding: "1rem 0rem",
        alignItems: 'center'
    },
    menu: {
        fontSize: theme.spacing(2.2),
        fontWeight: 'bold',
        color: '#ffffff',
        textAlign: 'center',
        margin: '0rem 1.5rem',
        cursor: 'pointer',
        ['@media (max-width:1024px)']: { // eslint-disable-line no-useless-computed-key
            fontSize: theme.spacing(1.5),
            margin: '0rem 0.6rem',
        }
    },
    nestedMenu: {
        fontSize: theme.spacing(2.2),
        fontWeight: 'normal',
        color: '#182327',
        textAlign: 'center',
        ['@media (max-width:1024px)']: { // eslint-disable-line no-useless-computed-key
            fontSize: theme.spacing(1.5),
        }
    },
    selected: {
        fontSize: theme.spacing(2.2),
        fontWeight: 'bold',
        color: '#158e98',
        textAlign: 'center',
        margin: '0rem 1.5rem',
        cursor: 'pointer',
        ['@media (max-width:1024px)']: { // eslint-disable-line no-useless-computed-key
            fontSize: theme.spacing(1.5),
            margin: '0rem 0.6rem',
        }
    },
    title: {
        fontSize: theme.spacing(2.2),
        fontWeight: 'bold',
    },
    subtitle: {
        flexGrow: 1,
        fontSize: theme.spacing(1.8),
        fontWeight: 'normal',
    }

});