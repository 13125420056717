import { color } from '../../config/colors/colors.js';

export const style = theme => ({
    comment: {
        marginTop: '2rem',
        marginBottom: '2rem',
    },
    input: {
        width: "100%",
        padding: '20px 10px 10px 10px',
        backgroundColor: '#e0e0e0',
        borderRadius: '5px'
    },
    selectTag: {
        marginLeft: "2rem",
        height: "3rem",
        minWidth: "15rem",
        width: '100%'
    },
    select: {
        '&:focus': {
            backgroundColor: 'transparent'
        }
    },
    buttonComment: {
        display: "flex",
        width: "100%",
        justifyContent: "flex-end",
        [theme.breakpoints.down('sm')]: {
            paddingTop: '1rem'
        }
    },
    tagUserStyle: {
        color: "black"
    },
    chip: {
        marginTop: '10px',
        marginRight: '5px'
    },
    error: {
        color: 'red'
    },
    buttonTag: {
        maxWidth: '20rem',
    },
    deleteCommentButton: {
        backgroundColor: "transparent",
        backgroundRepeat: "no-repeat",
        border: "none",
        cursor: "pointer",
        overflow: "hidden",
        outline: "none",
        float: "right",
    },
    centeredTitle: {
        fontSize: theme.spacing(3),
        color: color.title,
        fontWeight: 'bold',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        textAlign: 'center'
    }
});


