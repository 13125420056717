import React from 'react'
import MainLayout from '../../layout/main/Main.layout'
import AgencyMandatesContainer from '../../containers/agency_partnership/AgencyMandates.container'

const AgencyProposalsPage = () => {

    return (
        <MainLayout title='Agency Proposals' logo={true}>
            <AgencyMandatesContainer caller="partnerProposal" />
        </MainLayout>
    )
}

export default AgencyProposalsPage