import React from 'react'
import MainLayout from '../../layout/main/Main.layout'
import Grid from '@material-ui/core/Grid'
import ScoutingSectionComponent from '../../components/scouting/ScoutingSection.component'
import ContentLayout from '../../layout/content/Content.layout'

const ScoutingPage = () => {

    return (
        <MainLayout title='Scouting'>
            <ContentLayout>
                <Grid container>
                    <Grid item xs={6}>
                        <ScoutingSectionComponent title='Alert' link='/scouting/alert' />
                    </Grid>
                    <Grid item xs={6}>
                        <ScoutingSectionComponent title='Selected List' link='/scouting/selected' />
                    </Grid>
                </Grid>
            </ContentLayout>
        </MainLayout>
    )
}

export default ScoutingPage