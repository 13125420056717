import _ from 'lodash';

export const AgencyPartnershipEndpoints = {
    basePath: '/api/v1/mandates/',
    endpoints: {
        agenciesList: {
            path: 'area-agencies/',
            method: 'GET',
            get: 'data',
            fallback: [],
            auth: true,
        },
        agenciesPlayersList: {
            path: 'agencies-players/',
            method: 'GET',
            get: 'data',
            fallback: [],
            auth: true,
        },
        agencyMandates: {
            path: 'agencies/?country={{countryId}}&agency={{agencyId}}',
            method: 'GET',
            get: 'data',
            fallback: [],
            auth: true,
            swr: {
                revalidateOnFocus: false,
                revalidateOnMount: false,
                revalidateOnReconnect: false,
                dedupingInterval: 1000,
            }
        },
        deleteMandate: {
            path: 'agencies/{{id}}/',
            method: 'DELETE',
            fallback: null,
            auth: true,
        },
        getMandate: {
            path: 'agencies/{{id}}/',
            method: 'GET',
            get: 'data',
            fallback: [],
            auth: true,
        },
        addMandate: {
            path: 'agencies/',
            method: 'POST',
            get: 'data',
            fallback: null,
            auth: true,
        },
        editMandate: {
            path: 'agencies/{{id}}/',
            method: 'PATCH',
            get: 'data',
            fallback: null,
            auth: true,
        },
        getCommentsByMandate: {
            path: '/agencies/{{mandateId}}/comments/',
            method: 'GET',
            get: 'data',
            fallback: [],
            auth: true,
            swr: {
                revalidateOnFocus: false
            }
        },
        addMandateComment: {
            path: '/agencies/{{id}}/comments/',
            method: 'POST',
            get: 'data',
            fallback: null,   
            auth: true,    
        },
        deleteMandateComment: {
            path: '/agencies/{{id}}/comments/{{commentId}}/',
            method: 'DELETE',
            fallback: null,   
            auth: true
        }

    }
}

export default AgencyPartnershipEndpoints;