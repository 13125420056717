import _ from 'lodash';

export const ActivityEndpoints = {
    basePath: '/api/v1/',
    endpoints: {
        activities: {
            path: 'calendar-activities/{{id}}/',
            method: 'GET',
            get: 'data',
            fallback: [],   
            auth: true,    
        },
        deleteActivity: {
            path: 'calendar-activities/{{id}}/',
            method: 'DELETE',
            fallback: null,   
            auth: true
        },
        updateActivity: {
            path: 'calendar-activities/{{id}}/',
            method: 'PATCH',
            get: 'data',
            fallback: null,   
            auth: true,    
        },
        addActivityComment: {
            path: 'calendar-activities/{{id}}/comments/',
            method: 'POST',
            get: 'data',
            fallback: null,   
            auth: true,    
        },
        deleteComment: {
            path: 'calendar-activities/{{id}}/comments/{{commentId}}/',
            method: 'DELETE',
            fallback: null,   
            auth: true
        },
        updateComment: {
            path: 'calendar-activities/{{activityId}}/comments/{{commentId}}/',
            method: 'PUT',
            get: 'data',
            fallback: null,   
            auth: true,    
        },
        getActivityComments: {
            path: 'calendar-activities/{{id}}/comments/',
            method: 'GET',
            get: 'data',
            fallback: null,   
            auth: true,    
        }
    }
}

export default ActivityEndpoints;