const Inbox = {
    title: 'Inbox',
    url: '/inbox'
}

const Calendar = {
    title: 'Calendar',
    url: '/calendar'
}

const MyPortfolio = {
    title: 'My Portfolio',
    url: '/mandates/desk'
}

const PartnerPlayers = {
    title: 'Partner Players',
    url: '/mandates/partner_players'
}

const ClubPartnership = {
    title: 'Club Partnership',
    url: '/mandates/club_partnership'
}

const AgencyPartnership = {
    title: 'Agency Partnership',
    url: '/mandates/agency_partnership'
}

const Mandates = {
    title: 'Mandates',
    url: '/mandates',
    items: [MyPortfolio, PartnerPlayers, ClubPartnership, AgencyPartnership]
}

/* const Monitoring = {
    title: 'Monitoring',
    url: '/monitoring'
} */

const ScountingAlert = {
    title: 'Alert',
    url: '/scouting/alert'
}

const ScountingSelected = {
    title: 'Selected List',
    url: '/scouting/selected'
}

const Scouting = {
    title: 'Scouting',
    url: '/scouting',
    items: [ScountingAlert, ScountingSelected]
}

const EliteScoutingList = {
    title: 'Elite Scouting List',
    url: '/elite/scoutinglist'
}

const ElitePortfolio = {
    title: 'Elite Portfolio',
    url: '/elite/eliteportfolio'
}

const EliteClubProposal = {
    title: 'Elite Club Proposal',
    url: '/elite/eliteclubproposal'
}

const Elite = {
    title: 'Elite',
    url: '/elite',
    items: [EliteScoutingList, ElitePortfolio, EliteClubProposal]
}

const ClubProposal = {
    title: 'Club Proposal',
    url: '/player_requests/club_proposal'
}

const PartnerProposal = {
    title: 'Partner Proposal',
    url: '/player_requests/partner_proposal'
}

const BaPortfolio = {
    title: 'B.A. Portfolio',
    url: '/player_requests/ba_portfolio'
}

const PlayerRequest = {
    title: 'Player Request',
    url: '/club_requests',
    items: [ClubProposal, PartnerProposal, BaPortfolio]
}

const PlayerRequestMarketOperator = {
    title: 'Player Request',
    url: '/club_requests',
    items: [ClubProposal, BaPortfolio]
}


export const MenuHeadCompanyConf = {
    title: 'Branchini Associati',
    subtitle: 'Giacomo Branchini',
    menu: [Inbox, Calendar, Mandates, /* Monitoring, */ Scouting, Elite, PlayerRequest]
}

export const MenuHeadScountingConf = {
    title: 'Branchini Associati',
    subtitle: 'Giacomo Branchini',
    menu: [Calendar, Scouting, PlayerRequest]
}


export const MenuScountingConf = {
    title: 'Branchini Associati',
    subtitle: 'Giacomo Branchini',
    menu: [Calendar, Scouting]
}

export const MenuPartnerConf = {
    title: 'Branchini Associati',
    subtitle: 'Giacomo Branchini',
    menu: [PlayerRequest]
}


export const MenuExternalAgentConf = {
    title: 'Branchini Associati',
    subtitle: 'Giacomo Branchini',
    menu: [ScountingAlert]
}

export const MenuExternalMarketOperatorConf = {
    title: 'Branchini Associati',
    subtitle: 'Giacomo Branchini',
    menu: [PlayerRequestMarketOperator]
}

export const MenuDefault = {
    title: 'Branchini Associati',
    subtitle: 'Giacomo Branchini',
    menu: []
}