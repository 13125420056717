import React, { useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core'
import Requests from '../../core/requests'
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(() => ({
    actionOnCommentButton: {
        backgroundColor: "transparent",
        backgroundRepeat: "no-repeat",
        border: "none",
        cursor: "pointer",
        overflow: "hidden",
        outline: "none",
        float: "right",
    }
}));


const DeleteCommentContainer = ({
    activityId,
    commentId,
    refreshActivity
}) => {

    const { token } = useSelector(state => state.user)
    const classes = useStyles();
    const [disable, setDisable] = useState(false);


    const deleteComment = useCallback((activityId, commentId) => {
        setDisable(true);
        Requests.activity.deleteComment({ token: token.access, activityId: activityId, commentId: commentId}).then(() =>{
            enableDeleteButtonAndMutate()
        }).catch(() => {
            enableDeleteButtonAndMutate()
        });
    }, [setDisable])

    const enableDeleteButtonAndMutate = useCallback(() => {
        setDisable(false);
        refreshActivity();
    }, [setDisable])

    return (
        <Button disabled={disable} className={classes.actionOnCommentButton} onClick={() => deleteComment(activityId, commentId)}>
            <DeleteIcon/>
        </Button>
    )
}

export default DeleteCommentContainer