import _ from 'lodash';

export const ReportsEndpoints = {
    basePath: '/api/v1/reports/',
    endpoints: {
        file: {
            path: '/file-players/',
            method: 'POST',
            get: 'data',
            fallback: null,
            auth: true,
            multipart: true
        },
        deleteFile: {
            path: '/file-players/{{file_id}}/',
            method: 'DELETE',
            get: 'data',
            fallback: null,
            auth: true,            
        },
        addLink: {
            path: '/link-players/',
            method: 'POST',
            get: 'data',
            fallback: null,   
            auth: true,    
        },
        deleteLink: {
            path: '/link-players/{{link_id}}/',
            method: 'DELETE',
            get: 'data',
            fallback: null,
            auth: true,            
        },
        mark: {
            path: '/mark-players/',
            method: 'POST',
            get: 'data',
            fallback: null,
            auth: true,
        },
        deleteCountryFile: {
            path: '/file-countries/{{file_id}}/',
            method: 'DELETE',
            get: 'data',
            fallback: null,
            auth: true,            
        },
        deleteCountryLink: {
            path: '/link-countries/{{link_id}}/',
            method: 'DELETE',
            get: 'data',
            fallback: null,
            auth: true,            
        },
        deleteCompetitionFile: {
            path: '/file-competitions/{{file_id}}/',
            method: 'DELETE',
            get: 'data',
            fallback: null,
            auth: true,            
        },
        deleteCompetitionLink: {
            path: '/link-competitions/{{link_id}}/',
            method: 'DELETE',
            get: 'data',
            fallback: null,
            auth: true,            
        }
    }
}

export default ReportsEndpoints;