import React from 'react'
import { Fragment } from 'react'
import Loading from '../../components/progress/Progress.component'
import DetailProposalComponent from '../../components/proposal/DetailProposal.component'
import { useSelector } from 'react-redux'
import useSWRRequest from '../../core/useSWRRequest'
import Requests from '../../core/requests'

const RequestPlayerContainer = ({
    id,    
}) => {

    const { token } = useSelector(state => state.user)
    const { data, loading, mutate } = useSWRRequest(id ? Requests.players.interestingPlayer : "",  { token: token.access, id})

    return (
        <Fragment>
            {loading ? <Loading /> : null}
            {data? <DetailProposalComponent mutate={mutate} data={data} /> : null}
        </Fragment>
    )
}

export default RequestPlayerContainer