import React, { useEffect, useCallback, useState, Fragment } from 'react'
import Loading from '../../components/progress/Progress.component'
import { Box } from '@material-ui/core'
import List from '../../components/proposal/ListProposal.component'
import { useParams } from 'react-router'
import { useSelector } from 'react-redux'
import useSWRRequest from '../../core/useSWRRequest'
import Requests from '../../core/requests'
import ConfirmDeletionModalComponent from '../../components/club_partnership/ConfirmDeletionModal.component'
import CallOutcomeComponent from '../../components/club_partnership/CallOutcome.component'
import { isNotMarketOperatorUser } from '../../utils/CheckLoggedUser'


const ProposalListContainer = ({
    open,
    onAddAction = () => { },
    onSelectItem = () => { },
}) => {

    const { token, user } = useSelector(state => state.user)
    const { id } = useParams();
    const { data, loading, mutate } = useSWRRequest(Requests.notes.note, { token: token.access, id })
    const [openCreate, setOpenCreate] = useState(false)
    const [errorBE, setErrorBE] = useState(null)
    const [selectedProposalId, setSelectedProposalId] = useState(0)
    const [selectedPlayerId, setSelectedPlayerId] = useState(0)

    const onDeleteItem = useCallback((proposal, player) => {
        if(isNotMarketOperatorUser(user?.groups)){
            setErrorBE(null);
            setSelectedProposalId(proposal);
            setSelectedPlayerId(player);
            setOpenCreate(true);            
        }

    }, [])

    const deleteItem = useCallback(() => {
        Requests.notes.updateProposal({ token: token.access, id: selectedProposalId }, {
            players: data.proposals.find((item) => item.id === selectedProposalId).players.filter((item) => item.id !== selectedPlayerId).map((item) => item.id)
        }).then(() => {
            setOpenCreate(false);
            mutate();
        }).catch((error) => {
            setErrorBE(error?.response?.data?.error)
        })
    }, [data, selectedProposalId, selectedPlayerId])

    useEffect(() => {
        if (!open) {
            mutate()
        }
    }, [open])


    return (
        <Fragment>
            {loading ? <Loading /> : null}
            {data &&
                <Box mt={2} width={1}>
                    <List items={data.proposals} onAddAction={onAddAction} onSelectItem={onSelectItem} onDeleteItem={onDeleteItem} />
                </Box>}
            <ConfirmDeletionModalComponent openCreate={openCreate} setOpenCreate = {setOpenCreate} deleteFunction = {deleteItem} >
                <CallOutcomeComponent error = {errorBE} />
            </ConfirmDeletionModalComponent>
        </Fragment>
    )
}

export default ProposalListContainer