import React, { Fragment } from 'react'
import MandateOverviewComponent from '../../components/club_partnership/MandateOverview.component'

const MandateOverviewContainer = ({
    mandate
}) => {

    return (
        <Fragment>
            {mandate ? <MandateOverviewComponent mandate={mandate} /> : null}
        </Fragment>
    )
}

export default MandateOverviewContainer