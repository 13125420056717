import React from 'react';
import {
    TextField,
} from '@material-ui/core';


const EditText = ({
    touched,
    errors,
    values,
    handleBlur,
    label,
    name,
    handleChange,
    type = 'text',
    required = false,
    disabled = false
}) => {

    return (
        <TextField
            style={{ marginTop: '16px' }}
            error={Boolean(touched[name] && errors[name])}
            fullWidth
            required={required}
            helperText={touched[name] && errors[name]}
            label={label}
            name={name}
            onBlur={handleBlur}
            onChange={handleChange}
            type={type}
            value={values[name]}
            variant="outlined"
            disabled={disabled}
            InputLabelProps={{
                shrink: true,
            }} />
    );
};


export default EditText;