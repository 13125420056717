import React from 'react'
import MainLayout from '../../layout/main/Main.layout'
import AgencyMandateContainer from '../../containers/agency_partnership/AgencyMandate.container'

const AgencyMandatePage = () => {

    return (
        <MainLayout title="Mandate">
            <AgencyMandateContainer />
        </MainLayout>
    )
}

export default AgencyMandatePage