import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import ContentLayout from "../../layout/content/Content.layout";
import MandatesContainer from "../../containers/club_partnership/Mandates.container";
import { useHistory } from "react-router";
import Modal from "../../components/Modal";
import AddMandateContainer from "../../containers/club_partnership/AddMandate.container";

const ClubMandatesContainer = () => {
	const history = useHistory();
	const { clubId, countryId } = useParams();
	const [openCreate, setOpenCreate] = useState(false);

	const onSelectItem = useCallback((mandateId) => {
		history.push(`/mandates/mandate/${mandateId}`);
	}, []);

	return (
		<ContentLayout>
			<MandatesContainer
				clubId={clubId}
				onAddAction={() => setOpenCreate(true)}
				onSelectItem={onSelectItem}
				open={openCreate}
			/>
			<Modal
				open={openCreate}
				onClose={() => setOpenCreate(false)}
				title="Add Mandate"
			>
				<AddMandateContainer
					clubId={clubId}
					countryId={countryId}
					onClose={() => setOpenCreate(false)}
				/>
			</Modal>
		</ContentLayout>
	);
};

export default ClubMandatesContainer;
