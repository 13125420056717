import { createSlice } from '@reduxjs/toolkit'
const initialToken = sessionStorage.getItem('token') ? JSON.parse(sessionStorage.getItem('token')) : null
const initialUser = sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')) : null

// Slice
const slice = createSlice({
    name: 'user',
    initialState: {
        token: initialToken,
        user: initialUser,
        loading: false,
        error: null
    },
    reducers: {
        login: (state) => {
            state.loading = true
            state.error = null
        },
        loginSuccess: (state, action) => {
            state.error = null
            state.loading = false
            state.token = action.token
            sessionStorage.setItem('token', JSON.stringify(action.token))
        },
        meSuccess: (state, action) => {
            state.error = null
            state.loading = false
            state.user = action.user
            sessionStorage.setItem('user', JSON.stringify(action.user))
        },
        logout: (state) => {
            state.error = null
            state.loading = false
        },
        logoutSuccess: (state) => {
            state.user = null
            state.token = null
            state.error = null
            state.loading = false
            sessionStorage.removeItem('token')
            sessionStorage.removeItem('user')
        },
        error: (state, action) => {
            state.user = null
            state.token = null
            state.error = action.error
            state.loading = false
            sessionStorage.removeItem('token')
            sessionStorage.removeItem('user')
        },
        resetError: (state, action) => {
            state.error = null
        },
    },
});


export const {
    login,
    loginSuccess,
    meSuccess,
    logout,
    logoutSuccess,
    error,
    resetError
} = slice.actions

export default slice.reducer