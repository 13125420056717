import React from 'react';
import { TableBody } from '@material-ui/core';
import { stableSort, getComparator } from '../../../utils/SortList'
import { Fragment } from 'react';


const TableContent = ({
    items = [],
    order = '',
    orderBy = '',
    page = 0,
    rowsPerPage = 5,
    children
}) => {

    return (
        <TableBody>
            {stableSort(items, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
                <Fragment key={index}>
                    {children(item)}
                </Fragment>
            ))}
        </TableBody>
    );
};


export default TableContent;
