import React, { useCallback, Fragment, useState } from 'react'
import { Form, Formik } from 'formik';
import Requests from '../../core/requests'
import { useSelector } from 'react-redux';
import MandateAddFileComponent from '../../components/club_partnership/MandateAddFile.component';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { color } from '../../config/colors/colors'
import CallOutcomeComponent from '../../components/club_partnership/CallOutcome.component'

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: theme.spacing(3),
        color: color.title,
        fontWeight: 'bold',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    }
}));


const MandateAddFileContainer = ({
    mandateId,
    mutate,
    identifier, 
    nationality,
    competition,
    teamId
}) => {

    const classes = useStyles();
    const { token } = useSelector(state => state.user)
    const [error, setError] = useState(null)

    const setEndPointAndRequestObj = useCallback((values) => {

        switch (identifier) {
            case "club":
            case "agency":
                return Requests.mandate_reports.addFile({
                    mandate: parseInt(values.mandate),
                    file: values.file,
                    token: token.access
                })
            case "countryAttachments":
                return Requests.mandate_reports.addCountryFile({
                    country: parseInt(nationality),
                    file: values.file,
                    token: token.access
                })
            case "competitionAttachments":
                return Requests.mandate_reports.addCompetitionFile({
                    competition: parseInt(competition),
                    file: values.file,
                    token: token.access
                })
            case "partnerProposal":
                return Requests.mandate_reports.addProposalFile({
                    partner_proposal: parseInt(values.mandate),
                    file: values.file,
                    token: token.access
                })
            case "proposalList":
                return Requests.mandate_reports.addTeamFile({
                    team: parseInt(teamId),
                    file: values.file,
                    token: token.access
                })
            case "baPortfolio":
                return Requests.mandate_reports.addBaMandateFile({
                    ba_proposal: parseInt(mandateId),
                    file: values.file,
                    token: token.access
                })
        }
    }, [])

    const onSubmit = useCallback((values) => {
        setEndPointAndRequestObj(values).then(() => {
            mutate()
        }).catch((error) => {
            setError(error?.response?.data?.error)
        })
    }, [])


    return (
        <Fragment>
            <CallOutcomeComponent error = {error} />
            <Grid item xs>
                <Typography className={classes.title}>
                    Upload Reports
                </Typography>
            </Grid>
            <Grid item>
                <Formik
                    initialValues={{ file: null, mandate: mandateId}}
                    onSubmit={onSubmit}
                    validateOnBlur={true}
                    validateOnChange={false}>
                    {(props) =>
                        <Form>
                            <MandateAddFileComponent {...props} />
                        </Form>
                    }
                </Formik>
            </Grid>
        </Fragment>
    )
}

export default MandateAddFileContainer
