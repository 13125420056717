import React, { useState, Fragment, useCallback } from 'react'
import Loading from '../../components/progress/Progress.component'
import AddLinkComponent from '../../components/club_partnership/AddLink.component'
import { LinkInitialValues, LinkAddValidationSchema } from '../../conf/Link.conf'
import { useSelector } from 'react-redux'
import Requests from '../../core/requests'
import CallOutcomeComponent from '../../components/club_partnership/CallOutcome.component'

const AddLinkContainer = ({
    onClose,
    mandateId,
    mutate,
    identifier,
    nationality,
    competition,
    teamId
}) => {

    const { token } = useSelector(state => state.user)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const setEndPointAndRequestObj = useCallback((values) => {
        switch (identifier) {
            case "club":
            case "agency":
                return Requests.mandate_reports.addLink({
                    token: token.access,
                    mandate: parseInt(mandateId),
                    url: values.url,
                    name: values.name,
                })
            case "countryAttachments":
                return Requests.mandate_reports.addCountryLink({
                    country: parseInt(nationality),
                    url: values.url,
                    name: values.name,
                    token: token.access
                })
            case "competitionAttachments":
                return Requests.mandate_reports.addCompetitionLink({
                    competition: parseInt(competition),
                    url: values.url,
                    name: values.name,
                    token: token.access
                })
            case "partnerProposal":
                return Requests.mandate_reports.addProposalLink({
                    partner_proposal: parseInt(mandateId),
                    url: values.url,
                    name: values.name,
                    token: token.access
                })
            case "proposalList":
                return Requests.mandate_reports.addTeamLink({
                    team: parseInt(teamId),
                    url: values.url,
                    name: values.name,
                    token: token.access
                })
            case "baPortfolio":
                return Requests.mandate_reports.addBaMandateLink({
                    ba_proposal: parseInt(mandateId),
                    url: values.url,
                    name: values.name,
                    token: token.access
                })
        }
    }, [])

    const onSubmit = (values, actions) => {
        setLoading(true)
        actions.setSubmitting(true)
        setEndPointAndRequestObj(values).then(() => {
            setLoading(false)
            setError(null)
            actions.setSubmitting(false)
            actions.resetForm();
            mutate();
            onClose();
        }).catch((error) => {
            setLoading(false)
            setError(error?.response?.data?.error)
            actions.setSubmitting(false)
            actions.resetForm();
        })
    }

    return (
        <Fragment>
            <CallOutcomeComponent error={error} />
            {loading ? <Loading /> : null}
            <AddLinkComponent initialValues={LinkInitialValues()} validationSchema={LinkAddValidationSchema} onSubmit={onSubmit} />
        </Fragment>
    )
}

export default AddLinkContainer