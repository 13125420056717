import _ from 'lodash';

export const AreasEndpoints = {
    basePath: 'api/v1/areas/',
    endpoints: {
        countries: {
            path: '/countries/',
            method: 'GET',
            get: 'data',
            fallback: [],
            transform: (data) => data.sort((a, b) => a.name.localeCompare(b.name)),
            auth: true,
            swr: {
                revalidateOnFocus: false
            }
        },
        getCountry: {
            path: 'countries/{{countryId}}/',
            method: 'GET',
            get: 'data',
            fallback: [],   
            auth: true,    
        },
        countriesHasTeams: {
            path: '/countries/?has_teams=True',
            method: 'GET',
            get: 'data',
            fallback: [],
            transform: (data) => data.map((item) => ({
                id: item.id,
                name: item.name
            })).sort((a, b) => a.name.localeCompare(b.name)),
            auth: true,
            swr: {
                revalidateOnFocus: false
            }
        },      
    }
}

export default AreasEndpoints;