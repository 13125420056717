import React from 'react';
import { TablePagination } from '@material-ui/core';


const TableFooter = ({
    count = 0,
    handlePageChange = () => { },
    handleLimitChange = () => { },
    rowsPerPage = 10,
    page = 0,
    rowsPerPageOptions
}) => {

    return (
        <TablePagination
            component="div"
            count={count}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions ? rowsPerPageOptions : [5, 10, 25]} />
    );
};


export default TableFooter;