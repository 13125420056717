import React from 'react';
import { Box } from '@material-ui/core';
import EditTextArea from '../EditTextArea';
import Submit from '../Submit';
import { Form, Formik } from 'formik';
import FilterUsersContainer from '../../containers/filters/FilterUsers.container';

const AddLinkComponent = ({    
    initialValues,
    validationSchema,
    showUsersInvolved,
    onSubmit = () => {}
}) => {

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={onSubmit} >
            {(props) => 
                <Form onSubmit={props.handleSubmit}>
                <Box mt={2}>
                    <EditTextArea {...props} label='Insert Url' name="url" />
                </Box>
                    <Box mt={2}>
                        <EditTextArea {...props} label='Insert Name' name="name" />
                    </Box>
                {showUsersInvolved && 
                    <FilterUsersContainer {...props} name="operators" onChange={(name, value) => props.setFieldValue(name, value)} />
                }
                <Box my={2}>
                    <Submit {...props} title="Add Url" />
                </Box>
            </Form>
            }
        </Formik>
    );
};

export default AddLinkComponent;