import React, { useCallback, useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Card, Table, makeStyles } from '@material-ui/core';
import TableToolbar from './TableToolbar';
import TableContent from './TableContent';
import TableHeader from './TableHeader';
import TableFooter from './TableFooter';
import { stableSort, getComparator } from '../../utils/SortList';
import { Fragment } from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: '0px',
        padding: '0rem',
        boxShadow: 'none',
    },
}));

const TableList = ({
    items = [],
    headCells = [],
    order: firstOrder,
    orderBy: firstOrderBy,
    rowsPerPage: firstRowsPerPage,
    rowsPerPageOptions,
    addLabel = '',
    addAction = () => { },
    children,
    setSearchValue = () => { },
    enableSearch = true,
    add = true,
    count,
    rawData,
    onUpdateRowsAndPages = () => { },
    //handlePageChange = () => { },
    pageIndex
}) => {

    const classes = useStyles();
    const [rowsPerPage, setRowsPerPage] = useState(firstRowsPerPage);
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState(firstOrder)
    const [orderBy, setOrderBy] = useState(firstOrderBy);
    const [name, setName] = useState('')

    const handleLimitChange = useCallback((event) => setRowsPerPage(event.target.value));

    // OLD PAGE CHANGE HANDLE
    const handlePageChange = useCallback((event, newPage) => setPage(newPage));

    const handleRequestSort = useCallback((event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    });

    const searchChange = useCallback((value) => {
        setName(value)
        setPage(0)
        setSearchValue(value)
    })

    useEffect(() => {
        onUpdateRowsAndPages(stableSort(items, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
    }, [page, rowsPerPage, order, orderBy])

    const onRefreshAction = useCallback(() => {
        onUpdateRowsAndPages(stableSort(items, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
    })

    return (
        <Fragment>
            {enableSearch && <TableToolbar searchLabel='Search' searchChange={(event) => searchChange(event.target.value)} addLabel={addLabel} addAction={addAction} add={add} refreshAction={onRefreshAction} />}
            <Card className={classes.root}>
                <PerfectScrollbar >
                    <Table>
                        <TableHeader order={order} orderBy={orderBy} headCells={headCells} onRequestSort={handleRequestSort} />
                        <TableContent items={items} order={order} orderBy={orderBy} page={page} rowsPerPage={rowsPerPage} >
                            {(item) => children(item)}
                        </TableContent>
                    </Table>
                </PerfectScrollbar>
                <TableFooter count={count} handlePageChange={handlePageChange} handleLimitChange={handleLimitChange} rowsPerPage={rowsPerPage} page={page} rowsPerPageOptions={rowsPerPageOptions} />
            </Card>
        </Fragment>
    );
};


export default TableList;
