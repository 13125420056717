import { color } from '../../config/colors/colors.js';

export const style = theme => ({
    root: {
    },
    header: {
        fontSize: theme.spacing(3),
        backgroundColor: 'white',
        color: color.primary.main,
        fontWeight: 'bold'
    },
    title: {
        fontSize: theme.spacing(3),
        color: color.title,
        fontWeight: 'bold'
    },
    close: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
    },
    avatar: {
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
    container: {
        alignSelf: 'center',
    },
    name: {
        color: color.primary.main,
        fontSize: theme.spacing(3),
    },
    star: {
        color: color.primary.main,
    },
    monitored: {
        color: color.primary.main,
        fontSize: theme.spacing(2.2),
        fontWeight: 'bold'
    },
    divider:{
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        backgroundColor: color.primary.main,
        height: theme.spacing(0.2)
    },
    value:{
        color: color.secondary.main,        
        textTransform: 'uppercase',
        paddingLeft: theme.spacing(0.5),
    },
    label:{
        color: color.primary.main,
        fontWeight: 'bold'
    },
    button: {
        backgroundColor: color.primary.main,
        color: color.white,
        fontSize: theme.spacing(2),
        fontWeight: 600
    },
});


