import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import MainLayout from '../../layout/main/Main.layout'
import MonitoringContainer from '../../containers/monitoring/Monitoring.container'
import ContentLayout from '../../layout/content/Content.layout'

const MonitoringPage = () => {

    let history = useHistory()

    const openDetail = useCallback((player) => {
        history.push(`/player_detail/${player.id}/${player?.player?.id}`)
    }, [])

    return (
        <MainLayout title='My Portfolio' logo={true}>
            <ContentLayout>
                <MonitoringContainer openDetail={openDetail} />
            </ContentLayout>
        </MainLayout>
    )
}

export default MonitoringPage