import React, { Fragment } from 'react';
import { Paper, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import { color } from '../../config/colors/colors'

const useStyles = makeStyles(theme => ({
    paperCommentOk: {
        padding : "16px",
        backgroundColor: "#d4edda",
        borderColor: "#c3e6cb"
    },
    labelOk: {
        textAlign: "center",
        color: "#155724",
    },
    paperCommentKo: {
        padding : "16px",
        backgroundColor: "#f8d7da",
        borderColor: "#f5c6cb"
    },
    labelKo: {
        textAlign: "center",
        color: color.title,
    }
}));

const CallOutcomeComponent = ({
    success,
    error   
}) => {


    const classes = useStyles();

    return (
        <Fragment>
            {error && error.message ? 
                <Grid item xs={12}>
                    <Paper className={classes.paperCommentKo}>
                        <Typography className={classes.labelKo}>{error.message}</Typography>
                    </Paper>
                </Grid>
            : null}
            {success ? 
                <Grid item xs={12}>
                    <Paper className={classes.paperCommentOk}>
                        <Typography className={classes.labelOk}>{success}</Typography>
                    </Paper>
                </Grid>
            : null}
        </Fragment>
    );
};

export default CallOutcomeComponent;