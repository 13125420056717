import { call, put, takeLatest } from 'redux-saga/effects'
import { login, loginSuccess, meSuccess, logout, logoutSuccess, error } from './user.store';
import Requests from '../core/requests';

function* loginUser(action) {

    try {
        const token = yield call(Requests.auth.login, { ...action.payload })
        yield put({ type: loginSuccess.type, token: token });
        const user = yield call(Requests.users.me, { token: token.access })
        yield put({ type: meSuccess.type, user: user });
    } catch (e) {
        yield put({ type: error.type, error: e?.response?.data });
    }
}


function* logoutUser(action) {

    try {
        yield call(Requests.auth.logout, { token: action.payload.access })
        yield put({ type: logoutSuccess.type });
    } catch (e) {
        yield put({ type: error.type, error: e });
    }
}


function* sagas() {
    yield takeLatest(login.type, loginUser);
    yield takeLatest(logout.type, logoutUser);
}

export default sagas;