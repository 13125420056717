import React, { useCallback, useState } from 'react'
import Error from '../../components/error/Error.component'
import Loading from '../../components/progress/Progress.component'
import { Fragment } from 'react'
import { Box, Paper, makeStyles, Typography, Grid } from '@material-ui/core'
import { useSelector } from 'react-redux'
import useSWRRequest from '../../core/useSWRRequest'
import Requests from '../../core/requests'
import { color } from '../../config/colors/colors'
import TableList from '../../components/table/Table'
import Row from '../../components/proposal/RowOperatorProposal.component'
import Modal from '../../components/Modal'
import ImageContainer from '../image/Image.container'

const useStyles = makeStyles((theme) => ({
    value: {
        color: color.secondary.main,
        textTransform: 'uppercase',
        paddingLeft: theme.spacing(0.5),
    },
    label: {
        color: color.primary.main,
        fontWeight: 'bold'
    },
    avatar: {
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        backgroundColor: color.primary.main,
        height: theme.spacing(0.2)
    },
    container: {
        alignSelf: 'center',
    },
    link: {
        textDecoration: 'underline',
        cursor: 'pointer'
    }
}));

const headCells = [
    {
        id: 'players', label: 'Players', sortable: false
    },
    {
        id: 'age', label: 'Age', sortable: false
    },
    {
        id: 'team', label: 'Team', sortable: false
    },
    {
        id: 'country', label: 'Country', sortable: false
    },
    {
        id: 'detail', label: '', sortable: false
    },
]


const DetailProposalOperatorComponent = ({
    data
}) => {

    const classes = useStyles()

    const openReportLink = useCallback((url) => {
        window.open(url, '_blank');
    }, [])

    return (
        data &&
        <Grid container>
            <Grid container item xs={12}>
                <Grid item xs={2} className={classes.container}>
                    <ImageContainer src={data.image_url} className={classes.avatar} />
                </Grid>
                <Grid item xs={5} className={classes.container}>
                    <Typography className={classes.name}>{data.full_name}</Typography>
                </Grid>
            </Grid>
            <Grid className={classes.divider} item xs={12} />
            <Grid container item xs={12}>
                <Grid item xs>
                    <Box display="flex" flexDirection="row" alignItems='center'>
                        <Typography className={classes.label}>Age:</Typography>
                        <Typography className={classes.value}>{data.age}</Typography>
                    </Box>
                    <Box display="flex" flexDirection="row" alignItems='center'>
                        <Typography className={classes.label}>Native Country:</Typography>
                        <Typography className={classes.value}>{data.birth_country.name}</Typography>
                    </Box>

                    <Box display="flex" flexDirection="row" alignItems='end'>
                        <Typography className={classes.label}>Passaport:</Typography>
                        <Typography className={classes.value}>{data.passports.map((item, index) => index === data.passports.length - 1 ? `${item.name}` : `${item.name}-`)}</Typography>
                    </Box>

                    <Box display="flex" flexDirection="row" alignItems='center'>
                        <Typography className={classes.label}>Height:</Typography>
                        <Typography className={classes.value}>{data.height}</Typography>
                    </Box>
                </Grid>
                <Grid item xs>
                    <Box display="flex" flexDirection="row" alignItems='center'>
                        <Typography className={classes.label}>Position:</Typography>
                        <Typography className={classes.value}>{data.roles.map((role, index) => index === data.roles.length - 1 ? `${role.short_name}` : `${role.short_name}-`)}</Typography>
                    </Box>
                    <Box display="flex" flexDirection="row" alignItems='center'>
                        <Typography className={classes.label}>Current club:</Typography>
                        <Typography className={classes.value}>{data.team.name}</Typography>
                    </Box>
                    <Box display="flex" flexDirection="row" alignItems='center'>
                        <Typography className={classes.label}>Contract expiry:</Typography>
                        <Typography className={classes.value}>{data.contract_expires}</Typography>
                    </Box>
                </Grid>
                <Grid className={classes.divider} item xs={12} />
                <Grid item xs>
                    <Typography className={classes.label}>Reports:</Typography>
                    {data.links.map((link) => <Typography className={classes.link} key={link.id} onClick={() => openReportLink(link.url)}>Report {link.name}</Typography>)}
                </Grid>
            </Grid>
        </Grid>
    )

}


const RequestsListOperatorContainer = () => {

    const classes = useStyles()
    const [detail, setDetail] = useState(null)
    const { token } = useSelector(state => state.user)
    const { data, loading, error } = useSWRRequest(Requests.notes.notes, { token: token.access })


    return (
        <Fragment>
            {error ? <Error message={error.message} /> : null}
            {loading ? <Loading /> : null}
            <Box mt={2} width={1}>
                {data.map((note) =>
                    note.work_status === 'Elite' ?
                        <Paper key={note.id}>
                            <Box p={2}>
                                <Grid container>
                                    <Grid container item xs={12}>
                                        <Box display="flex" flexDirection="row" alignItems='end' mt={1}>
                                            <Typography className={classes.label}>Role:</Typography>
                                            <Typography className={classes.value}>{note.role.map((item) => `${item.full_name}, `)}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid container item xs={12}>
                                        <Box display="flex" flexDirection="row" alignItems='end' mt={1}>
                                            <Typography className={classes.label}>Team:</Typography>
                                            <Typography className={classes.value}>{note.team.name}</Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box mt={2} p={2}>
                                <TableList
                                    headCells={headCells}
                                    items={note.proposals}
                                    page={0}
                                    add={false}
                                    rowsPerPage={25}>
                                    {(item) => item.players.map((player) => <Row key={player.id} proposal={item.id} player={player} onSelectItem={() => setDetail(player)} />)}
                                </TableList>
                            </Box>
                        </Paper> : null
                )}
            </Box>
            <Modal
                open={detail ? true : false}
                onClose={() => setDetail(null)}
                title='Detail Proposal'>
                <DetailProposalOperatorComponent data={detail} />
            </Modal>
        </Fragment>
    )
}

export default RequestsListOperatorContainer
