import React, { Fragment } from 'react'
import { LinearProgress } from '@material-ui/core'
import FilterAutocompleteComponent from '../../components/filter/FilterAutocomplete.component'
import { useSelector } from 'react-redux'
import useSWRRequest from '../../core/useSWRRequest'
import Requests from '../../core/requests'

const CalendarActivityUsersContainer = ({
    onChange = () => { },
    ...props
}) => {

    const { token } = useSelector(state => state.user)
    const { data, loading } = useSWRRequest(Requests.users.getHeadCompanyUsers, { token: token.access })

    const getSelectedOperators = () => {
        if(data && props.values.usersInvolved.length){
            return data.filter(dataItem => props.values.usersInvolved.some(id => dataItem.id === id));
        }  
    }

    return (
        <Fragment>
            {loading ? <LinearProgress /> : null}
            <FilterAutocompleteComponent value={getSelectedOperators() || []} title='Users Involved' multiple={true} options={data} setFilter={(value) => onChange('usersInvolved', value)} />
        </Fragment>
    )
}

export default CalendarActivityUsersContainer