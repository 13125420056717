import React, { Fragment } from "react";
import { LinearProgress } from "@material-ui/core";
import FilterAutocompleteComponent from "../../components/filter/FilterAutocomplete.component";
import { useSelector } from "react-redux";
import useSWRRequest from "../../core/useSWRRequest";
import Requests from "../../core/requests";

const FilterUsersContainer = ({
	onChange = () => {},
	isMarketOperatorUser,
	...props
}) => {
	const { token } = useSelector((state) => state.user);
	const { data, loading } = useSWRRequest(
		Requests.users.getPlayerRequestUsers,
		{ token: token.access }
	);

	const getSelectedOperators = () => {
		if (data && props?.values?.operators?.length) {
			return data.filter((dataItem) =>
				props.values.operators.some((id) => dataItem.id === id)
			);
		}
	};

	return (
		<Fragment>
			{loading ? <LinearProgress /> : null}
			<FilterAutocompleteComponent
				disabled={isMarketOperatorUser ? isMarketOperatorUser() : false}
				value={getSelectedOperators() || []}
				title="Select Operators"
				multiple={true}
				options={data}
				setFilter={(value) => onChange("operators", value)}
			/>
		</Fragment>
	);
};

export default FilterUsersContainer;
