import React, { useState, Fragment } from 'react'
import Loading from '../../components/progress/Progress.component'
import { Formik } from 'formik';
import CalendarActivityComponent from '../../components/calendar/CalendarActivity.component'
import { CalendarAddInitialValues, CalendarAddValidationSchema } from '../../conf/Calendar.conf'
import { useSelector } from 'react-redux'
import Requests from '../../core/requests'
import { Paper, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import { color } from '../../config/colors/colors'

const useStyles = makeStyles(() => ({
    paperCommentKo: {
        padding : "16px",
        backgroundColor: "#f8d7da",
        borderColor: "#f5c6cb"
    },
    labelKo: {
        textAlign: "center",
        color: color.title,
    }
}));



const CalendarAddActivityContainer = ({
    onClose = () => { },
    eventDate
}) => {

    const { token } = useSelector(state => state.user)
    const classes = useStyles();
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const onSubmit = (values, actions) => {
        setLoading(true)
        actions.setSubmitting(true)
        Requests.calendar.addActivity(({
            token: token.access,
            type: values.type,
            operators: values.usersInvolved,
            detail: values.activityDetail,
            note: values.note,
            start_date: eventDate?.start || "",
            end_date: eventDate?.end || "",
            start: values.startTime || null,
            end: values.endTime || null
        })).then(() => {
            setLoading(false)
            setError(null)
            actions.setSubmitting(false)
            onClose()
        }).catch((errorBE) => {
            setLoading(false)
            setError(errorBE.response.data.error)
            actions.setSubmitting(false)
        })    
    }

    return (
        <Fragment>
            {error && error.message ? 
                <Grid item xs={12}>
                    <Paper className={classes.paperCommentKo}>
                        <Typography className={classes.labelKo}>{error.message}</Typography>
                    </Paper>
                </Grid>
            : null}
            {loading ? <Loading /> : null}
            <CalendarActivityComponent initialValues={CalendarAddInitialValues()} validationSchema ={CalendarAddValidationSchema} onSubmit={onSubmit} />
        </Fragment>
    )
}

export default CalendarAddActivityContainer