import React, { useState, Fragment } from 'react'
import Loading from '../../components/progress/Progress.component'
import { Formik } from 'formik';
import AddAgencyComponent from '../../components/agency_partnership/AddAgency.component'
import { AgencyPartnershipAgencyInitialValues, AgencyPartnershipAgencyValidationSchema } from '../../conf/AgencyPartnershipAgency.config';
import { useSelector } from 'react-redux'
import Requests from '../../core/requests'
import CallOutcomeComponent from '../../components/club_partnership/CallOutcome.component'

const AddAgencyContainer = ({
    onClose = () => { },
    mutate,
    caller
}) => {

    const { token } = useSelector(state => state.user)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const onSubmit = (values, actions) => {
        setLoading(true)
        actions.setSubmitting(true)
        Requests.agency.addAgency(({
            token: token.access,
            name: values.name,
            country: values.country,
            agency_operators: JSON.stringify(values.usersInvolved),
            logo: values.logo,
        })).then(() => {
            setLoading(false)
            setError(null)
            actions.setSubmitting(false)
            mutate()
            onClose()
        }).catch((error) => {
            setLoading(false)
            setError(error?.response?.data?.error)
            actions.setSubmitting(false)
        })
    }

    return (
        <Fragment>
            <CallOutcomeComponent error={error} />
            {loading ? <Loading /> : null}
            <Formik
                initialValues={AgencyPartnershipAgencyInitialValues()}
                validationSchema={AgencyPartnershipAgencyValidationSchema}
                enableReinitialize={true}
                onSubmit={onSubmit} >
                {(props) => <AddAgencyComponent caller={caller} {...props} />}
            </Formik>
        </Fragment>
    )
}

export default AddAgencyContainer