import React from 'react'
import { withStyles } from "@material-ui/core/styles"
import { style } from './ClubPartnershipTeamThumb.component.style';
import { Typography, Grid } from '@material-ui/core';
import ImageContainer from '../../containers/image/Image.container';

const ClubPartnershipTeamThumbComponent = ({
    classes,
    data,
    identifier
}) => {
    return (
        <Grid container className={classes.root}>
            <Grid item >
                <ImageContainer src={identifier === "agencyPartnership"  || identifier === "partnerProposal" ||  identifier === "baPortfolio" ? data.logo : data.image_url} className={classes.icon} />
            </Grid>
            <Grid item>
                <Typography className={classes.name}>
                    {data.name}
                </Typography>
            </Grid>
        </Grid>
    )
}

export default withStyles(style)(ClubPartnershipTeamThumbComponent)