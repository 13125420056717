import React from 'react'
import MainLayout from '../../layout/main/Main.layout'
import ContentLayout from '../../layout/content/Content.layout'
import ClubPartnershipContainer from '../../containers/club_partnership/ClubPartnership.container'

const ClubPartnershipPage = () => {

    return (
        <MainLayout title='Club Partnership' logo={true}>
            <ContentLayout>
                <ClubPartnershipContainer />
            </ContentLayout>
        </MainLayout>
    )
}

export default ClubPartnershipPage