import moment from 'moment'
import * as Yup from 'yup';

export const RequestsFilterInitialValues = {
    nationality: '',
    league: '',
    team: '',
}

export const RequestAddInitialValues = (data) => ({
    deadline: moment().format("YYYY-MM-DD"),
    nationality: data.nationality || '',
    competition: data.competition || '',
    competitionId: data.competitionId || '',
    team: data.team || '',
    position: '',
    status: '',
    operators: ''
})

export const RequestUpdateInitialValues = (data) => ({
    deadline: data?.deadline,
    note: data?.note,
    work_status: data?.work_status,
    operators: data?.operators ? data?.operators.map(item => item.id) : [],
})


export const RequestAddValidationShema = Yup.object().shape({
    nationality: Yup.string().required('Nationality is required'),
    team: Yup.string().required('Club is required'),
})

