import React from 'react'
import Grid from '@material-ui/core/Grid'

const ContentLayout = ({
    children
}) => {

    return (
        <Grid container>
            <Grid item xs={1}/>
            <Grid container item xs={10}>
                {children}
            </Grid>
            <Grid item xs={1} />
        </Grid>
    )
}

export default ContentLayout