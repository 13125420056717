import React, { useEffect } from 'react';
import { Box, Grid} from '@material-ui/core';
import EditTextArea from '../../components/EditTextArea';
import EditText from '../EditText';
import Submit from '../../components/Submit';
import { Form } from 'formik';
import Select from '../Select';
import UsersInvolvedContainer from '../../containers/club_partnership/UsersInvolved.container';

const player_status =  [{
    label: 'OnSale',
    value: 'OnSale'
},{
    label: 'Stable',
    value: 'Stable'
},{
    label: 'AgencyProposal',
    value: 'AgencyProposal'
},{
    label: 'ClubRequest',
    value: 'ClubRequest'
},{
    label: 'ContractExtension',
    value: 'ContractExtension',
},{
    label: 'ContractExpiring',
    value: 'ContractExpiring'
}]

const work_status =  [{
    label: 'NotPriority',
    value: 'NotPriority'
},{
    label: 'Todo',
    value: 'Todo'
},{
    label: 'InProgress',
    value: 'InProgress'
},{
    label: 'Done',
    value: 'Done'
}]

const PlayerStatusComponent = ({
    ...props
}) => {
    useEffect(()=>{
        if(!props.values.expiration_date){
            props.values.expiration_time = ""; 
        }
    },[props.values.expiration_date])

    return (
        <Form onSubmit={props.handleSubmit}>
            <Box mt={2}>
                <Select {...props} label='Player Status' name="player_status" options={player_status} value={props.values.player_status}/>
            </Box>
            <Box mt={2}>
                <Select {...props} label='Work Status' name="work_status" options={work_status} value={props.values.work_status}/>
            </Box>
            <Box mt={2}>
                <UsersInvolvedContainer {...props} onChange={(name, value) => props.setFieldValue(name, value)} />
            </Box>
            <Box mt={2}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <EditText {...props} type="date" label='Expiration Date' name="expiration_date"/>
                    </Grid>
                    <Grid item xs={6}>
                        <EditText {...props} type="time" label='Expiration Time' name="expiration_time" disabled={!props?.values.expiration_date}/>
                    </Grid>
                </Grid>
            </Box>
            <Box mt={2}>
                <EditTextArea {...props} label='Note' name="note" />
            </Box>
            <Box my={2}>
                <Submit {...props} title='Save' />
            </Box>
        </Form>
    );
};

export default PlayerStatusComponent;