import * as Yup from 'yup';


export const ProposalAddInitialValues = (request) => ({
    team: request?.team?.name,
    role: request?.role?.map((item) => item.full_name),
    position: request?.position?.name || request?.position_text || "Not specified",
    agenda_note: request?.id,
    country: '',
    players: null,
    selectedTeam: ''
})


export const ProposalAddValidationSchema = Yup.object().shape({
    agenda_note: Yup.string().required('Country is required'),
    players: Yup.array().required('Players is required')
})