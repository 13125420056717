import React, { useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/styles';
import { Button, Box } from '@material-ui/core'
import { useHistory } from 'react-router'
import Requests from '../../core/requests'
import ConfirmDeletionModalComponent from '../../components/club_partnership/ConfirmDeletionModal.component'

const useStyles = makeStyles(() => ({
    deleteButton: {
        width: "100%",
        color: "#fff",
        padding: "8px 22px",
        backgroundColor: "#223239",
        fontSize: "0.9375rem",
        '&:hover': {
            backgroundColor: "#233539",
            boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)"
        }
    }
}));

const DeleteActivityContainer = ({
    activityId
}) => {

    const { token } = useSelector(state => state.user)
    const classes = useStyles();
    const [openCreate, setOpenCreate] = useState(false)
    const history = useHistory()


    const deleteActivity = useCallback((id) => {
        Requests.activity.deleteActivity({ token: token.access, id: id}).then(() =>{
            history.goBack();
        });
    }, [])

    return (
        <Box my={2}>
            <ConfirmDeletionModalComponent openCreate={openCreate} setOpenCreate = {setOpenCreate} deleteFunction = {() => deleteActivity(activityId)}/>
            <Button className={classes.deleteButton} onClick={() => setOpenCreate(true)}>
                Delete
            </Button>
        </Box>
    )
}

export default DeleteActivityContainer