import React from 'react'
import { withStyles } from "@material-ui/core/styles"
import { style } from '../players/PlayerThumbHorizontal.component.style';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';


const MandateOverviewComponent = ({
    classes,
    mandate,
}) => {

    return (
        <Grid container className={classes.root} spacing={1}>
            <Grid item xs={12}>
                <Typography className={classes.title}>
                    Overview Mandate
                </Typography>
            </Grid>

            <Grid item xs={2}>
                <Avatar alt='' src={mandate?.team?.image_url} className={classes.icon} />
            </Grid>

            <Grid container item xs={10}>
                <Grid item xs={12}>
                    <Box display='flex' alignItems='flex-end' height={1} flexDirection='row'>
                        <Typography className={classes.name}>
                            {mandate?.team?.name?.toUpperCase()}
                        </Typography>
                        <Typography className={classes.nationality}>
                            ({mandate?.team?.country?.name})
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box display='flex' alignItems='flex-start' height={1}>
                        <Typography className={classes.role}>
                            {mandate?.deadline}
                        </Typography>
                        <Typography className={classes.nationality}>
                            {mandate?.deadline && "(Deadline)"}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>

            <Grid container display='flex' flexDirection='column' style={{
                marginTop: '2rem'
            }}>
                <Grid item xs={12}>
                    <Box className={classes.valueContainer}>
                        <Typography className={classes.label}>
                            Players:
                        </Typography>
                        <Typography className={classes.value}>{mandate?.players?.map((item, index) => index === mandate.players.length - 1 ? `${item.full_name}` : `${item.full_name} - `)}</Typography>
                    </Box>

                </Grid>

                <Grid item xs={12}>
                    <Box className={classes.valueContainer}>
                        <Typography className={classes.label}>
                            Work Status:
                        </Typography>
                        <Typography className={classes.value}>
                            {mandate?.work_status ? mandate.work_status : "NotPriority"}
                        </Typography>
                    </Box>

                </Grid>

            </Grid>
        </Grid>
    )
}

export default withStyles(style)(MandateOverviewComponent)