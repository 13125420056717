import _ from 'lodash';

export const ProposalEndpoints = {
    basePath: '/api/v1/club-requests',
    endpoints: {
        proposals: {
            path: '/proposals/',
            method: 'GET',
            get: 'data',
            fallback: [],   
            auth: true,    
        },
        proposal: {
            path: '/proposals/{{id}}/',
            method: 'GET',
            get: 'data',
            fallback: null,   
            auth: true,    
        }, 
        add: {
            path: '/proposals/',
            method: 'POST',
            get: 'data',
            fallback: null,   
            auth: true,    
        },
        convertProposalToMandate: {
            path: '/club-proposal-to-mandate/',
            method: 'POST',
            get: 'data',
            fallback: null,   
            auth: true,    
        },                
    }
}

export default ProposalEndpoints;