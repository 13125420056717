import React from 'react'
import { withStyles } from "@material-ui/core/styles"
import { style } from './PlayerThumbHorizontal.component.style';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';


const PlayerThumbHorizontalComponent = ({
    classes,
    data,
    identifier
}) => {

    return (
        <Grid container className={classes.root} spacing={1}>
            <Grid item xs={12}>
                <Typography className={classes.title}>
                    Overview Player
                </Typography>
            </Grid>

            <Grid item xs={2}>
                <Avatar alt='' src={identifier === "partnerPlayer" ? data?.image_url : data?.player?.image_url} className={classes.icon} />
            </Grid>

            <Grid container item xs={10}>
                <Grid item xs={12}>
                    <Box display='flex' alignItems='flex-end' height={1} flexDirection='row'>
                        <Typography className={classes.name}>
                            {identifier === "partnerPlayer" ? data?.full_name?.toUpperCase() : data?.player?.full_name?.toUpperCase()}
                        </Typography>
                        <Typography className={classes.nationality}>
                            {identifier === "partnerPlayer" ? data?.birth_country?.name : data?.player?.birth_country?.name}
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box display='flex' alignItems='flex-start' height={1}>
                        <Typography className={classes.role}>
                            {identifier === "partnerPlayer" ? data?.roles?.map((item, index) => index === data?.roles?.length - 1 ? `${item.full_name}` : `${item.full_name}-`) : data?.player?.roles?.map((item, index) => index === data?.player?.roles?.length - 1 ? `${item.full_name}` : `${item.full_name}-`)}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>

            <Grid container display='flex' flexDirection='column' style={{
                marginTop: '2rem'
            }}>
                <Grid item xs={12}>
                    <Box className={classes.valueContainer}>
                        <Typography className={classes.label}>
                            Contract Expires:
                        </Typography>
                        <Typography className={classes.value}>{identifier === "partnerPlayer" ? data?.contract_expires : data?.player?.contract_expires}</Typography>
                    </Box>

                </Grid>

                <Grid item xs={12}>
                    <Box className={classes.valueContainer}>
                        <Typography className={classes.label}>
                            Current Club:
                        </Typography>
                        <Typography className={classes.value}>
                            {identifier === "partnerPlayer" ? data?.team?.name : data?.player?.team?.name}
                        </Typography>
                    </Box>

                </Grid>

                <Grid item xs={12}>
                    <Box className={classes.valueContainer}>
                        <Typography className={classes.label}>
                            Age:
                        </Typography>
                        <Typography className={classes.value}> {identifier === "partnerPlayer" ? data?.age : data?.player?.age}</Typography>
                    </Box>
                </Grid>

                {identifier === "partnerPlayer" && <Grid item xs={12}>
                    <Box className={classes.valueContainer}>
                        <Typography className={classes.label}>
                            Agency Name:
                        </Typography>
                        <Typography className={classes.value}>{data?.agencies?.map((item, index) => index === data?.agencies?.length - 1 ? `${item}` : `${item} - `)}</Typography>
                    </Box>
                </Grid>}

            </Grid>
        </Grid>
    )
}

export default withStyles(style)(PlayerThumbHorizontalComponent)