import React from 'react';
import {
    TextField,
} from '@material-ui/core';


const Select = ({
    label,
    name,
    handleChange,
    // values,
    value,
    options,
    required = false,
    disabled
}) => {

    return (
        <TextField
            style={{ marginTop: '16px' }}
            fullWidth
            label={label}
            name={name}
            onChange={handleChange}
            required={required}
            select
            SelectProps={{ native: true }}
            // value={values[name]}
            value={value}
            disabled={disabled}
            variant="outlined">
            {options.map((option) => (
                <option
                    key={option.value}
                    value={option.value}>
                    {option.label}
                </option>
            ))}
        </TextField>
    );
};


export default Select;