import React, { useEffect } from 'react'
import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AddCircleRounded } from '@material-ui/icons';

const useStyles = makeStyles(() => ({    
    input: {
        display: 'none'
    },
    icon: {
        width: 'auto',
        height: '2.7rem',
        '&:hover': {
            background: 'transparent'
        }
    },    
}));


const AddAgencyLogoContainer = ({
    ...props
}) => {

    const classes = useStyles();

    useEffect(() => {
        if(props.values.logo){
            const reader = new FileReader();            
            reader.readAsDataURL(props.values.logo);
        }
    }, [props.values])
    
    return (
        <Box>
            {props?.values.logo && props?.values.logo.name ? props?.values.logo.name : "Add agency logo"}
            <input id='logo' name='logo' accept="image/*" className={classes.input} type="file" onChange={(event) => {
                props.setFieldValue('logo', event.currentTarget.files[0]); 
            }} />
            <label htmlFor='logo'>
                <Button color="primary" component="span">
                    <AddCircleRounded className={classes.icon} />
                </Button>
            </label>
        </Box>
    )
}

export default AddAgencyLogoContainer