import React from 'react'
import Avatar from '@material-ui/core/Avatar';


const ImageContainer = ({
    className,
    src
}) => {

    return <Avatar alt='' src={src} className={className} />
}

export default ImageContainer