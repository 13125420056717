import React from 'react';
import { TableCell, TableRow, Box } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionIcon from '@material-ui/icons/Description';

const Row = ({
    item,
    onSelectItem = () => { },
    onDeleteItem = () => { },
    caller,
    checkIsNotMarketOperatorUser
}) => {

    return (
        <TableRow key={item?.id}>
            <TableCell>{item?.team?.name}</TableCell>
            <TableCell>{item?.players?.map((player) => (<Box key={player?.id}> {player.full_name || ""}</Box>))}</TableCell>
            {caller !== "partnerProposal" && <TableCell>{item?.work_status || "NotPriority"}</TableCell>}
            <TableCell>{item?.users?.map((user) => (<Box key={user?.id}> { user.first_name + " " + user.last_name }</Box>))}</TableCell>
            {caller !== "partnerProposal" && <TableCell>{item?.deadline}</TableCell>}
            {caller === "partnerProposal" && 
                <>
                    <TableCell>{item?.players[0]?.age || ""}</TableCell>
                    <TableCell>{item?.players[0]?.roles?.map((role, index) => index === item?.players[0]?.roles?.length - 1 ? `${role.short_name}` : `${role.short_name}-`)}</TableCell>
                    <TableCell>{item?.players[0]?.team?.country?.name || ""}</TableCell>
                </>
            }
            <TableCell onClick={() => onDeleteItem(item?.id)} style={{ cursor: 'pointer', textAlign: "center"}}><DeleteIcon style={checkIsNotMarketOperatorUser && checkIsNotMarketOperatorUser() ? {} : {color: "#dddddd"}} /></TableCell>
            <TableCell onClick={() => onSelectItem(item?.id)} style={{ cursor: 'pointer', textAlign: "center"}}><DescriptionIcon /></TableCell>
        </TableRow>
    )
}

export default Row;