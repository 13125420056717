import React, { useState, Fragment } from 'react'
import Loading from '../../components/progress/Progress.component'
import { Formik } from 'formik';
import AddMandateComponent from '../../components/club_partnership/AddMandate.component'
import { ClubPartnershipMandateInitialValues, ClubPartnershipMandateValidationSchema } from '../../conf/ClubPartnershipMandate.config';
import { useSelector } from 'react-redux'
import Requests from '../../core/requests'
import CallOutcomeComponent from '../../components/club_partnership/CallOutcome.component'

const AddMandateContainer = ({
    onClose,
    clubId,
    countryId,
    mutate
}) => {

    const { token } = useSelector(state => state.user)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const onSubmit = (values, actions) => {
        setLoading(true)
        actions.setSubmitting(true)
        Requests.club_partnership.addMandate(({
            token: token.access,
            country: values.mandateCountry,
            team: values.mandateTeam,
            type: values.typeStatus,
            players: values.players,
            work_status: values.workStatus,
            users: values.usersInvolved,
            deadline: values.deadline,
            note: values.note
        })).then(() => {
            setLoading(false)
            setError(null)
            actions.setSubmitting(false)
            onClose()
            mutate()
        }).catch((error) => {
            setLoading(false)
            setError(error?.response?.data?.error)
            actions.setSubmitting(false)
        })
    }

    return (
        <Fragment>
            <CallOutcomeComponent error={error} />
            {loading ? <Loading /> : null}
            <Formik
                initialValues={ClubPartnershipMandateInitialValues({ mandateCountry: Number(countryId), mandateTeam: { id: Number(clubId) } })}
                validationSchema={ClubPartnershipMandateValidationSchema}
                enableReinitialize={true}
                onSubmit={onSubmit} >
                {(props) => <AddMandateComponent showMandateReferences={true} country={Number(countryId)} team={Number(clubId)} {...props} />}
            </Formik>
        </Fragment>
    )
}

export default AddMandateContainer