import React, { useState } from 'react';
import { TextField, Box } from '@material-ui/core';


const SearchBar = ({
    label,
    handleChange = () => { },
    value
}) => {

    return (
        <Box display="flex" mb={3} alignItems='flex-end'>
            <TextField
                label={label}
                variant="outlined"
                fullWidth
                value={value}
                onChange={handleChange}
            />
        </Box >
    );
};


export default SearchBar;