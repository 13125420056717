import { color } from '../../config/colors/colors.js';

export const style = theme => ({
    root: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        cursor: 'pointer',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    icon: {
        width: theme.spacing(10),
        height: theme.spacing(10),
        border: `2px solid ${theme.palette.primary.main}`,
        backgroundColor: '#fff',
        boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)"
    },
    name: {
        fontSize: theme.spacing(2),
        color: color.primary.main,
        fontWeight: 600,
        marginTop: '11px',
        textTransform: 'capitalize'
    },
    surname: {
        fontSize: theme.spacing(2),
        color: color.primary.main,
        fontWeight: 600
    },
    nationality: {
        fontSize: theme.spacing(1.5),
        color: color.primary.main,
        fontStyle: 'italic',

    }
});