import React from 'react';
import { TextField, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search'

const SearchBar = ({
    label,    
    handleChange,    
}) => {

    return (
        <TextField
            label={label}
            variant="outlined"
            fullWidth
            onChange={handleChange}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }} />
    );
};


export default SearchBar;