import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { style } from "../players/PlayersSection.component.style";
import { Grid, Typography, Button } from "@material-ui/core";
import PortiereIcon from "../../assets/icons/portiere.svg";
import DifesaIcon from "../../assets/icons/difensore.svg";
import CentrocampoIcon from "../../assets/icons/centrocampista.svg";
import TrequartiIcon from '../../assets/icons/trequarti.svg'
import AttaccoIcon from "../../assets/icons/attaccante.svg";
import { AddCircleRounded } from '@material-ui/icons';
import Modal from '../Modal'
import AddMandateContainer from '../../containers/agency_partnership/AddMandate.container'
import { isNotMarketOperatorUser } from "../../utils/CheckLoggedUser";
import { useSelector } from "react-redux";


const AgenciesSectionComponent = ({
  classes,
  title = "",
  children,
  countryId,
  identifier
}) => {

  const [openCreate, setOpenCreate] = useState(false);
  const { user } = useSelector((state) => state.user);

  return (
    <Grid container borderRadius={8} className={classes.root}>
      <Grid item xs style={{ display: "flex", alignItems: "center" }}>
        {/* <img
          src={
            title === "Portiere"
              ? PortiereIcon
              : title === "Difesa"
                ? DifesaIcon
                : title === "Centrocampo"
                  ? CentrocampoIcon
                  : title === "Attacco"
                    ? AttaccoIcon
                    : title === 'Trequarti'
                      ? TrequartiIcon
                      : null
          }
          height="40px"
        /> */}
        <Typography className={classes.title}>{title.toUpperCase()}</Typography>
      </Grid>
      { isNotMarketOperatorUser(user?.groups) &&
        <Grid item>
          <Button color="primary" component="span" onClick={() => setOpenCreate(true)}>
            <AddCircleRounded className={classes.icon} />
          </Button>
        </Grid>
      }
      <Modal
        open={openCreate}
        onClose={() => setOpenCreate(false)}
        title={identifier === "partnerProposal" ? 'Add Proposal' : 'Add Mandate'}>
        <AddMandateContainer identifier={identifier} countryId={countryId} onClose={() => setOpenCreate(false)} />
      </Modal>
      {children}
    </Grid>
  );
};

export default withStyles(style)(AgenciesSectionComponent);
