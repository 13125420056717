import * as Yup from 'yup';

export const ClubPartnershipMandateInitialValues = (data) => ({
    typeStatus: data && data.type? data.type.map(item => item.id) : [],
    workStatus: data && data.work_status?  data.work_status : '',
    usersInvolved: data && data.users? data.users.map(user => user.id) : [],
    deadline: data && data.deadline?  data.deadline : '',
    note: data && data.note? data.note : '',
    country : data?.players?.[0]?.team?.country?.id || '',
    team : data?.players?.[0]?.team?.id || '',
    players : data && data.players? data.players.map(item => item.id) : [],
    mandateCountry: data && data.mandateCountry? data.mandateCountry : '',
    mandateTeam : data && data.mandateTeam && data.mandateTeam.id ? data.mandateTeam.id : '',
})

export const ClubPartnershipMandateValidationSchema = Yup.object().shape({
    country: Yup.string().required('Country is required'),
    typeStatus: Yup.string().required('Type is required'),
    team: Yup.string().required('Team is required').nullable(),
    players: Yup.string().required('Players is required'),
    deadline: Yup.string().required('Deadline is required')
})