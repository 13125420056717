import React, { useCallback, useState, Fragment, useEffect } from "react";
import Error from "../../components/error/Error.component";
import Loading from "../../components/progress/Progress.component";
import { useSelector } from "react-redux";
import useSWRRequest from "../../core/useSWRRequest";
import Requests from "../../core/requests";
import CallOutcomeComponent from "../../components/club_partnership/CallOutcome.component";
import AllToDoValidProposalsComponents from "../../components/proposal/AllToDoValidProposals.component";
import { Box, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import Modal from '../../components/Modal'
import { useParams } from 'react-router-dom'

const useStyles = makeStyles(() => ({
    popupButton: {
        color: "#FFFFFF",
        width: "10rem",
        margin: "16px",
        fontSize: "16px",
        fontWeight: "600",
        backgroundColor: "#2C5364",
        '&:hover': {
            backgroundColor: "#233539",
            boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)"
        }
    }
}));

const AllToDoValidProposalsContainer = () => {
    const classes = useStyles();
    const { token } = useSelector((state) => state.user);
    const [openCreate, setOpenCreate] = useState(false);
    const [errorBE, setErrorBE] = useState(null);
    const [proposalId, setProposalId] = useState(null);
    const [success, setSuccess] = useState(null)
    const { playerId } = useParams()
    const { data: items, loading, error, mutate } = useSWRRequest(Requests.notes.allToDoValidNotes, { token: token.access, playerId });

    const addPlayerToProposal = useCallback((playerId, proposalId) => {
        Requests.proposal.add(({
            token: token.access,
            agenda_note: proposalId,
            players: [playerId],          
        })).then(() => {
            mutate();
            setSuccess("Player proposed successfully")
        }).catch((error) => {
            setErrorBE(error)
        })        
    }, []);

    useEffect(() => {
        if(openCreate){
            setErrorBE(null);
            setSuccess(null);
        }
    }, [openCreate]);

    return (
        <Fragment>
            {error ? <Error message={error.message} /> : null}
            {loading ? <Loading /> : null}
            <Box mt={2} width={1}>
                <AllToDoValidProposalsComponents items={items} setOpenCreate={setOpenCreate} setProposalId={setProposalId} />
            </Box>
            <Modal open={openCreate} onClose={() => setOpenCreate(false)} title='Are you sure?'>
                <CallOutcomeComponent success = {success} error = {errorBE} />
                <Box display="flex" flexDirection="row" justifyContent='center' flexGrow={1}>
                    <Button className={classes.popupButton} onClick={() => setOpenCreate(false)}>Close</Button>
                    <Button className={classes.popupButton} onClick={() => addPlayerToProposal(playerId, proposalId)}>Add</Button>
                </Box>
            </Modal>
        </Fragment>
    );
};

export default AllToDoValidProposalsContainer;
