import React, { Fragment } from 'react';
import { Route } from 'react-router-dom'

export function PublicRoute({
    component: Component,    
    ...rest
}) {

    return (
        <Route
            {...rest}
            render={props => {

                return (
                    <Fragment>
                        <Component {...props} />
                    </Fragment>
                )
            }}
        />
    )
}


export default PublicRoute