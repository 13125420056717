import React, { useCallback, Fragment, useState } from 'react'
import { Form, Formik } from 'formik';
import ReportAddFileComponent from '../../components/reports/ReportAddFile.component';
import Requests from '../../core/requests'
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import CallOutcomeComponent from '../../components/club_partnership/CallOutcome.component'
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { color } from '../../config/colors/colors'

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: theme.spacing(3),
        color: color.title,
        fontWeight: 'bold',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    }
}));

const ReportAddFileContainer = ({
    id,
    data, 
    mutate,
    identifier
}) => {

    const classes = useStyles();
    const { token } = useSelector(state => state.user)
    const [error, setError] = useState(null)

    const onSubmit = useCallback((values) => {
        Requests.reports.file({
            player: parseInt(values.player),
            file: values.file,
            token: token.access
        }).then(() => mutate()).catch((error) => setError(error?.response?.data?.error || "Error"))
    }, [])

    return (
        data ?
            <Fragment>
                <CallOutcomeComponent error = {error} />
                <Grid item xs>
                    <Typography className={classes.title}>
                        Reports Player
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <Formik
                        initialValues={{
                            file: null,
                            player: identifier === "partnerPlayer" ? data.id : data.player.id
                        }}
                        onSubmit={onSubmit}
                        validateOnBlur={true}
                        enableReinitialize ={true}
                        validateOnChange={false}>
                        {(props) =>
                            <Form>
                                <ReportAddFileComponent {...props} />
                            </Form>
                        }
                    </Formik>
                </Grid>
            </Fragment> : null
    )
}

export default ReportAddFileContainer
