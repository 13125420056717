import React, { Fragment, useCallback, useState, useEffect } from 'react'
import { Box, LinearProgress, Typography } from '@material-ui/core'
import FilterAutocompleteComponent from '../../components/filter/FilterAutocomplete.component'
import { useSelector } from 'react-redux'
import Requests from '../../core/requests'

const MandateReferencesContainer = ({
    onChange = () => { },
    countries,
    loading,
    country,
    team,
    values,
    ...props
}) => {

    const { token } = useSelector(state => state.user)
    const [teams, setTeams] = useState(false)
    const [loadingTeams, setLoadingTeams] = useState(false)


    useEffect(() => {
        if(team != values.mandateTeam){
            values.mandateTeam = null;
        }
        if(values?.mandateCountry){
            searchTeamsByCountry(values?.mandateCountry);
        }

    }, [values.mandateCountry]);

    const searchTeamsByCountry = useCallback((countryId) => {
        setLoadingTeams(true)
        if(countryId){
            Requests.teams.teamsByCountry({ token: token.access, countryId}).then((data) => {
                setTeams(data.map((item) => ({
                    id: item.id,
                    name: item.name
                })))
                setLoadingTeams(false)
            }).catch((error) => {
                setLoadingTeams(false)
            })
        }
    }, []);
    

    const setCountry = () => {
        if(values.mandateCountry && !loading && countries){
            return countries.filter(country => country.id == values.mandateCountry)[0];
        }
    };

    const setTeam = () => {
        if(values.mandateTeam && !loadingTeams && teams){
            return teams.filter(team => team.id == values.mandateTeam)[0];
        }
    };

    return (
        <Fragment>
            <Box mt={2}>
                <Typography>Mandate Country</Typography>
                {loading ? <LinearProgress /> : null}
                <FilterAutocompleteComponent {...props} disabled={country} value={setCountry() || []} options={countries} setFilter={(value) => onChange('mandateCountry', value)} name="mandateCountry" />
            </Box>
            {loadingTeams ? <LinearProgress /> : null}
            {values?.mandateCountry && teams?.length && !loadingTeams ?
                <Box mt={2}>
                    <Typography>Mandate Team</Typography>
                    <FilterAutocompleteComponent {...props} disabled={team} value={setTeam() || []} options={teams} setFilter={(value) => onChange('mandateTeam', value)} name="mandateTeam"/>
                </Box> : null}
        </Fragment>
    )
}

export default MandateReferencesContainer