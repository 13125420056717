import React, { Fragment, useState, useCallback, useEffect } from 'react'
import { Box, Divider, Typography } from '@material-ui/core'
import Requests from '../../core/requests'
import { useSelector } from 'react-redux'
import Loading from "../../components/Loading"
import TableList from "../../components/table/Table"
import { InboxRow } from "../../components/inbox/InboxRows"
import _ from 'lodash'
import ConfirmDeletionModalComponent from '../../components/club_partnership/ConfirmDeletionModal.component'
import CommentComponent from '../../components/chat/Comment.component'
import { style } from './Chat.container.style'
import { withStyles } from "@material-ui/core/styles";
import LeaveCommentComponent from '../../components/chat/LeaveComment.component'
import { isNotMarketOperatorUser, isNotPartnerUser } from '../../utils/CheckLoggedUser'

const ChatContainer = ({data, loading, mutate, identifier, id, classes}) => {

    const { token, user } = useSelector(state => state.user)
    const [errorDeleteComment, setErrorDeleteComment] = useState(false)
    const [disable, setDisable] = useState(false);
    const [openCreate, setOpenCreate] = useState(false)
    const [selectedItemId, setSelectedItemId] = useState(false)
    const [callerComponent, setCallerComponent] = useState(null);

    useEffect(() => {
        setCallerComponent(callers[identifier]);
    }, [])

    const deleteComment = useCallback((commentId) => {
        if (commentId) {
            setDisable(true);
            callerComponent.deleteItemEndPoint({ token: token.access, id, commentId})
                .then(() => {
                    enableDeleteButtonAndMutate()
                })
                .catch((error) => {
                    setErrorDeleteComment(error);
                    enableDeleteButtonAndMutate()
                })
        }
    }, [callerComponent])


    const enableDeleteButtonAndMutate = useCallback(() => {
        setOpenCreate(false);
        setDisable(false);
        mutate();
    }, [])

    const openConfirmDeletionPopup = useCallback((id) => {
        setOpenCreate(true);
        setSelectedItemId(id);
    }, [])

    return (
        <Fragment>
            <ConfirmDeletionModalComponent openCreate={openCreate} setOpenCreate={setOpenCreate} deleteFunction={() => deleteComment(selectedItemId)} />
            <Box width={"100%"}>
                <Box mt={2}>
                    <Typography className={classes.centeredTitle}>
                        Chat
                    </Typography>
                    { loading && <Loading />}
                    {
                        data?.length > 0 ? <TableList count={ data?.length } items={data} page={0} rowsPerPage={10} enableSearch={false} rowsPerPageOptions={[5, 10, 25, 50]}>
                            {(item) => <InboxRow key={item.id}><CommentComponent item={item} classes={classes} disable={disable} openConfirmDeletionPopup={openConfirmDeletionPopup} user={user} /></InboxRow>}
                        </TableList> :  <Typography>Nessun commento trovato</Typography>
                    }
                </Box>
                {(!isNotPartnerUser(user?.groups) && identifier === "baPortfolio") || !isNotMarketOperatorUser(user?.groups) ? null :
                    <>
                        <Divider />
                        <LeaveCommentComponent classes={classes} id={id} mutate={mutate} callerComponent = {callerComponent} errorDeleteComment = {errorDeleteComment} setErrorDeleteComment = {setErrorDeleteComment}/>
                    </>
                }
            </Box>
        </Fragment>
    )
}

export default withStyles(style)(ChatContainer);

const callers = 
{ 
    activity: {
        name: 'Activity',
        id: 0,
        endpoint: 'activity',
        saveEndpoint: Requests.activity.addActivityComment,
        deleteItemEndPoint: Requests.activity.deleteComment,
        taggableUsersEndPoint: Requests.inbox.tagUsers 
    },
    monitoring: {
        name: 'Monitoring',
        id: 1,
        endpoint: 'monitoring',
        saveEndpoint: Requests.players.addPlayerComment,
        deleteItemEndPoint: Requests.players.deletePlayerComment,
        taggableUsersEndPoint: Requests.inbox.tagUsers 
    },
    club: {
        name: 'ClubPartnership',
        id: 2,
        endpoint: 'club_partnership',
        saveEndpoint: Requests.club_partnership.addMandateComment,
        deleteItemEndPoint: Requests.club_partnership.deleteMandateComment,
        taggableUsersEndPoint: Requests.inbox.tagUsers 
    },
    agency: {
        name: 'AgencyPartnership',
        id: 3,
        endpoint: 'agency_partnership',
        saveEndpoint: Requests.agency_partnership.addMandateComment,
        deleteItemEndPoint: Requests.agency_partnership.deleteMandateComment,
        taggableUsersEndPoint: Requests.inbox.tagUsers 
    },
    baPortfolio: {
        name: 'baPortfolio',
        id: 4,
        endpoint: 'ba_portfolio',
        saveEndpoint: Requests.ba_portfolio.addMandateComment,
        deleteItemEndPoint: Requests.ba_portfolio.deleteMandateComment,
        taggableUsersEndPoint: Requests.inbox.tagPlayerRequestUsers 
    },
    partnerProposal: {
        name: 'partnerProposal',
        id: 5,
        endpoint: 'partner_proposal',
        saveEndpoint: Requests.partner_proposal.addProposalComment,
        deleteItemEndPoint: Requests.partner_proposal.deleteProposalComment,
        taggableUsersEndPoint: Requests.inbox.tagPlayerRequestUsers 
    },
    clubProposal: {
        name: 'clubProposal',
        id: 6,
        endpoint: 'notes',
        saveEndpoint: Requests.notes.addProposalComment,
        deleteItemEndPoint: Requests.notes.deleteProposalComment,
        taggableUsersEndPoint: Requests.inbox.tagPlayerRequestUsers 
    },
    partnerPlayer: {
        name: 'partnerPlayer',
        id: 7,
        endpoint: 'partnerPlayer',
        saveEndpoint: Requests.players.addPlayerComment,
        deleteItemEndPoint: Requests.players.deletePlayerComment,
        taggableUsersEndPoint: Requests.inbox.tagUsers 
    }
}

