import React, { createContext, useState } from 'react';
import cookie from 'js-cookie'

const AuthContext = createContext();
const AuthContextDispatchContext = createContext()

const AuthProvider = ({
    children
}) => {

    const [isLogged, setIsLogged] = useState(cookie.get('token') ? true : false)

    const setCookieToken = (data) => {
        setIsLogged(true)        
        cookie.set('token', JSON.stringify(data), { expires: 1 })
    }

    const getCookieToken = () => {
        return cookie.get('token') ? JSON.parse(cookie.get('token')) : null
    }

    const deleteCookieToken = () => {
        setIsLogged(false)
        cookie.remove('token')
    }


    return (
        <AuthContext.Provider value={{ token: getCookieToken(), isLogged }}>
            <AuthContextDispatchContext.Provider value={{ setToken: setCookieToken, deleteToken: deleteCookieToken }}>
                {children}
            </AuthContextDispatchContext.Provider>
        </AuthContext.Provider>
    )
}

export {
    AuthContext,
    AuthContextDispatchContext,
    AuthProvider,
}
