import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import ContentLayout from "../../layout/content/Content.layout";
import MandatesContainer from "../agency_partnership/Mandates.container";
import { useHistory } from "react-router";
import Modal from "../../components/Modal";
import AddMandateContainer from "../agency_partnership/AddMandate.container";

const AgencyMandatesContainer = ({ caller }) => {
	const history = useHistory();
	const { agencyId, countryId } = useParams();
	const [openCreate, setOpenCreate] = useState(false);

	const onSelectItem = useCallback((mandateId) => {
		switch (caller) {
			case "agencyPartnership":
				history.push(`/agency_partnership/mandate/${mandateId}`);
				break;
			case "partnerProposal":
				history.push(`/partner_proposal/proposal/${mandateId}`);
				break;
			case "baPortfolio":
				history.push(`/ba_portfolio/mandate/${mandateId}`);
				break;
		}
	}, []);

	return (
		<ContentLayout>
			<MandatesContainer
				caller={caller}
				agencyId={agencyId}
				countryId={countryId}
				onAddAction={() => setOpenCreate(true)}
				onSelectItem={onSelectItem}
				open={openCreate}
			/>
			<Modal
				open={openCreate}
				onClose={() => setOpenCreate(false)}
				title={caller === "partnerProposal" ? "Add Proposal" : "Add Mandate"}
			>
				<AddMandateContainer
					identifier={caller}
					agencyId={agencyId}
					countryId={countryId}
					onClose={() => setOpenCreate(false)}
				/>
			</Modal>
		</ContentLayout>
	);
};

export default AgencyMandatesContainer;
