import React from 'react';
import { Box, IconButton } from '@material-ui/core';
import SearchBar from './SearchBar';
import Add from '@material-ui/icons/AddCircle';
import Sync from '@material-ui/icons/Sync';

const TableToolbar = ({
    searchLabel = '',
    searchChange = () => { },
    add = true,
    addLabel = '',
    addAction = () => { },
    refresh = false,
    refreshLabel = true,
    refreshAction = () => { }
}) => {


    return (
        <Box display="flex" mb={3} alignItems='flex-end'>
            <Box width={1 / 2}>
                <SearchBar label={searchLabel} handleChange={searchChange} />
            </Box>
            <Box flexGrow={1} />
            {add ?
                <Box>
                    <IconButton aria-label={addLabel} color='primary' onClick={addAction}>
                        <Add fontSize='large' />
                    </IconButton>
                </Box> : null}
            {refresh ?
                <Box>
                    <IconButton aria-label={refreshLabel} color='primary' onClick={refreshAction}>
                        <Sync fontSize='large' />
                    </IconButton>
                </Box> : null}
        </Box>
    );
};


export default TableToolbar;