import React from 'react'
import MainLayout from '../../layout/main/Main.layout'
import ContentLayout from '../../layout/content/Content.layout'
import AgencyPartnershipContainer from '../../containers/agency_partnership/AgencyPartnership.container'

const PartnerProposalPage = () => {

    return (
        <MainLayout title='Partner Proposal' logo={true}>
            <ContentLayout>
                <AgencyPartnershipContainer caller="partnerProposal" />
            </ContentLayout>
        </MainLayout>
    )
}

export default PartnerProposalPage