import React, { useCallback, useState, Fragment } from "react";
import { Box, Paper, Typography, makeStyles } from "@material-ui/core";
import MainLayout from "../../layout/main/Main.layout";
import ContentLayout from "../../layout/content/Content.layout";
import ScoutingPlayerContainer from "../../containers/player/ScountingPlayer.container";
import ElitePlayersContainer from "../../containers/elite/ElitePlayers.container";
import ScoutingFilterContainer from "../../containers/scouting/ScoutingFilter.container";

const useStyles = makeStyles({
    container: {
        margin: "0rem 0rem 5rem 0rem",
        padding: "0rem 2rem 2rem 0rem",
    },
    title: {
        fontSize: "30px",
        fontFamily: "Anton-Regular",
    },
});

const PlayersElite = () => {
    const classes = useStyles();
    const [overlay, setOverlay] = useState(false);
    const [player, setPlayer] = useState(null);
    const [filter, setFilter] = useState(null);
    const identifier = "elitePortfolioPage";

    const onSubmit = useCallback((values) => {
        setFilter(values);
    }, []);


    const openOverlay = (player) => {
        setOverlay(true);
        setPlayer(player);
    };

    const closeOverlay = () => {
        setOverlay(false);
        setPlayer(null);
    };

    return (
        <ContentLayout>
            <Box mt={2} width={1}>
                <Typography className={classes.title}>Players</Typography>
                <Paper>
                    <Box padding={4}>
                        <ScoutingFilterContainer identifier={identifier} onSubmit={onSubmit} />
                    </Box>
                </Paper>
            </Box>
            <Box mt={2} width={1}>
                <ElitePlayersContainer
                    filter={filter}
                    openOverlay={(player) => openOverlay(player)}
                    open={overlay}
                    identifier = {identifier}
                />
                {overlay ? (
                    <ScoutingPlayerContainer
                        id={player.id}
                        closeOverlay={closeOverlay}
                        open={overlay}
                        identifier = {identifier}
                    />
                ) : null}
            </Box>
        </ContentLayout>
    );
};

const ElitePortfolioPage = () => {

    return (
        <MainLayout title="Elite Portfolio" elite={true}>
            <Fragment>
                <PlayersElite />
            </Fragment>
        </MainLayout>
    );

};

export default ElitePortfolioPage;
