import React, { useState, Fragment } from "react";
import Loading from "../../components/progress/Progress.component";
import { Formik } from "formik";
import AddClubRequest from "../../components/requests/AddClubRequest";
import {
	RequestAddInitialValues,
	RequestAddValidationShema,
} from "../../conf/Request.conf";
import { useSelector } from "react-redux";
import Requests from "../../core/requests";
import CallOutcomeComponent from "../../components/club_partnership/CallOutcome.component";

const RequestAddContainer = ({
	onClose = () => {},
	nationality,
	teamId,
	competitionName,
	competitionId,
	mutate,
}) => {
	const { token } = useSelector((state) => state.user);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const onSubmit = (values, actions) => {
		setLoading(true);
		actions.setSubmitting(true);
		Requests.notes
			.add({
				token: token.access,
				deadline: values.deadline,
				team: values.team,
				position_text: values.position,
				work_status: values.status,
				...(values.operators && { operators: values.operators }),
			})
			.then(() => {
				setLoading(false);
				setError(null);
				actions.setSubmitting(false);
				onClose();
				mutate();
			})
			.catch((error) => {
				setLoading(false);
				setError(error?.response?.data?.error);
				actions.setSubmitting(false);
			});
	};

	return (
		<Fragment>
			<CallOutcomeComponent error={error} />
			{loading ? <Loading /> : null}
			<Formik
				initialValues={RequestAddInitialValues({
					nationality: Number(nationality),
					team: Number(teamId),
					competition: competitionName,
					competitionId: competitionId
				})}
				validationSchema={RequestAddValidationShema}
				enableReinitialize={true}
				onSubmit={onSubmit}
			>
				{(props) => (
					<AddClubRequest
						competitionName={competitionName}
						competitionId={competitionId}
						disableNationalityField={nationality ? true : false}
						disableClubField={teamId ? true : false}
						{...props}
					/>
				)}
			</Formik>
		</Fragment>
	);
};

export default RequestAddContainer;
