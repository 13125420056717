import React, { useCallback, useEffect, useState, Fragment } from "react";
import Loading from "../../components/progress/Progress.component";
import { Box, Grid } from "@material-ui/core";
import MandatesList from "../../components/club_partnership/ClubPartnershipList.component";
import { useSelector } from "react-redux";
import useSWRRequest from "../../core/useSWRRequest";
import Requests from "../../core/requests";
import CallOutcomeComponent from "../../components/club_partnership/CallOutcome.component";
import ConfirmDeletionModalComponent from "../../components/club_partnership/ConfirmDeletionModal.component";
import { isNotMarketOperatorUser } from "../../utils/CheckLoggedUser";

const MandatesContainer = ({
    open,
    onAddAction = () => { },
    onSelectItem = () => { },
    agencyId,
    countryId,
    caller,
}) => {
    const setEndPointOnLoad = useCallback(() => {
        switch (caller) {
            case "baPortfolio":
                return Requests.ba_portfolio.agencyMandates;
            case "partnerProposal":
                return Requests.partner_proposal.agencyProposals;
            default:
                return Requests.agency_partnership.agencyMandates;
        }
    }, []);

    const { token, user } = useSelector((state) => state.user);
    const { data, loading, mutate } = useSWRRequest(
        setEndPointOnLoad(),
        { token: token.access, agencyId: agencyId, countryId: countryId }
    );
    const [openCreate, setOpenCreate] = useState(false);
    const [errorBE, setErrorBE] = useState(null);

    let selectedMandateId = 0;

    const onDeleteItem = useCallback((id) => {
        if (checkIsNotMarketOperatorUser()){
            selectedMandateId = id;
            setOpenCreate(true);
        }
    }, []);

    const setDeleteEndpoint = () => {
        switch (caller) {
            case "agencyPartnership":
                return Requests.agency_partnership.deleteMandate;
            case "partnerProposal":
                return Requests.partner_proposal.deleteProposal;
            case "baPortfolio":
                return Requests.ba_portfolio.deleteMandate;
        }
    };

    const deleteMandate = useCallback(() => {
        setErrorBE(null);
        setDeleteEndpoint()({ token: token.access, id: selectedMandateId })
            .then(() => {
                mutate();
                setOpenCreate(false);
            })
            .catch((error) => {
                setOpenCreate(false);
                setErrorBE(error?.response?.data?.error);
            });
    }, []);

    const checkIsNotMarketOperatorUser = useCallback(()=>{
        return isNotMarketOperatorUser(user?.groups)
    }, [])

    useEffect(() => {
        if (!open) {
            mutate();
        }
    }, [open]);

    return (
        <Fragment>
            <CallOutcomeComponent error={errorBE} />
            <Grid xs={12}>{loading ? <Loading /> : null}</Grid>
            <Box mt={2} width={1}>
                <MandatesList
                    caller={caller}
                    items={data}
                    onAddAction={onAddAction}
                    onSelectItem={onSelectItem}
                    onDeleteItem={onDeleteItem}
                    checkIsNotMarketOperatorUser = {checkIsNotMarketOperatorUser}
                />
            </Box>
            <ConfirmDeletionModalComponent
                openCreate={openCreate}
                setOpenCreate={setOpenCreate}
                deleteFunction={deleteMandate}
            />
        </Fragment>
    );
};

export default MandatesContainer;
