import React from 'react'
import MainLayout from '../../layout/main/Main.layout'
import ContentLayout from '../../layout/content/Content.layout'
import ClubProposalContainer from '../../containers/club_proposal/ClubProposal.container'

const ClubProposalPage = () => {

    return (
        <MainLayout title='Club Proposal' logo={true}>
            <ContentLayout>
                <ClubProposalContainer identifier = {"clubProposal"} />
            </ContentLayout>
        </MainLayout>
    )
}

export default ClubProposalPage