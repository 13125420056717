import React, { useState } from 'react';
import RowToDoValidProposalComponent from './RowToDoValidProposal.component';
import TableList from '../table/Table';

const headCells = [
    {
        id: 'team', label: 'Team', sortable: true
    },
    {
        id: 'position', label: 'Position', sortable: false
    },
    {
        id: 'deadline', label: 'Deadline', sortable: false
    },
    {
        id: 'action', label: '', sortable: false
    }
]

const AllToDoValidProposalsComponents = ({
    items = [],
    setOpenCreate,
    setProposalId
}) => {
    const [searchValue, setSearchValue] = useState('')
    const filterItems = (items) => items?.filter((item) => item?.team?.name?.toLowerCase().includes(searchValue.toLowerCase()) || item?.position_text?.toLowerCase().includes(searchValue.toLowerCase()))

    return (
        <TableList
            add={false}
            headCells={headCells}
            items={filterItems(items)}
            page={0}
            rowsPerPage={25}
            setSearchValue={setSearchValue} >
            {(item) => <RowToDoValidProposalComponent key={item.id} item={item} setOpenCreate={setOpenCreate} setProposalId = {setProposalId} />}
        </TableList>
    );
};


export default AllToDoValidProposalsComponents;