import React from 'react'
import { Paper, Box, Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import { style } from './DetailClubRequest.style';
import { withStyles } from "@material-ui/core/styles"

const DetailClubRequest = ({
    classes,
    request,
}) => {


    return (
        <Paper>
            <Box padding={2}>
                <Grid container>
                    <Grid container item xs={12}>
                        <Box display="flex" flexDirection="row" alignItems='end'>
                            <Typography className={classes.label}>Request Time:</Typography>
                            <Typography className={classes.value}>{moment(request.created_at).format('DD-MM-YYYY')}</Typography>
                        </Box>
                    </Grid>                                                            
                    {/* <Grid container item xs={12}>
                        <Box display="flex" flexDirection="row" alignItems='end' mt={1}>
                            <Typography className={classes.label}>Priority:</Typography>
                            <Typography className={classes.value}>{request.priority_level}</Typography>
                        </Box>
                    </Grid> */}
                    {/* <Grid container item xs={12}>
                        <Box display="flex" flexDirection="row" alignItems='end' mt={1}>
                            <Typography className={classes.label}>Role:</Typography>
                            <Typography className={classes.value}>{request.role.map((item) => `${item.full_name}, `)}</Typography>
                        </Box>
                    </Grid> */}
                    <Grid container item xs={12}>
                        <Box display="flex" flexDirection="row" alignItems='end' mt={1}>
                            <Typography className={classes.label}>Team:</Typography>
                            <Typography className={classes.value}>{request.team.name}</Typography>
                        </Box>
                    </Grid>
                    <Grid container item xs={12}>
                        <Box display="flex" flexDirection="row" alignItems='end' mt={1}>
                            <Typography className={classes.label}>Operators:</Typography>
                            <Typography className={classes.value}>{request?.operators?.map((item, index) => request.operators.length - 1 !== index ? `${item.first_name} ${item.last_name}, ` : `${item.first_name} ${item.last_name}`)}</Typography>
                        </Box>
                    </Grid>
                    <Grid container item xs={12}>
                        <Box display="flex" flexDirection="row" alignItems='end' mt={1}>
                            <Typography className={classes.label}>Work Status:</Typography>
                            <Typography className={classes.value}>{request.work_status}</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    );
};

export default withStyles(style)(DetailClubRequest)