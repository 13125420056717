import React, { Fragment } from 'react'
import MandateOverviewComponent from '../../components/agency_partnership/MandateOverview.component'

const MandateOverviewContainer = ({
    mandate,
    identifier
}) => {

    return (
        <Fragment>
            {mandate ? <MandateOverviewComponent identifier = {identifier} mandate={mandate} /> : null}
        </Fragment>
    )
}

export default MandateOverviewContainer