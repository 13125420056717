import { color } from '../../config/colors/colors.js';

export const style = theme => ({

    root: {
        backgroundColor: color.other.main,
        bottom: 0,
        marginTop: '35%',
        alignItems: 'center',
        justifyContent: 'center',
        display: "flex"
    },
    logo: {
        padding: "1px",
        height: '75px'
    }
});