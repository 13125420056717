import React, { useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Card, Table, makeStyles } from "@material-ui/core";
import TableContent from "./TableContent";
import TableHeader from "./TableHeader";
import CustomTableFooter from "./TableFooter";
import { Fragment } from "react";

const useStyles = makeStyles((theme) => ({
	root: {
		borderRadius: "0px",
		padding: "0rem",
		boxShadow: "none",
	},
}));

const TableList = ({
	items = [],
	headCells = [],
	rowsPerPage: firstRowsPerPage,
	children,
	rawData,
	pageIndex,
	setPageIndex,
}) => {
	const classes = useStyles();
	const [rowsPerPage, setRowsPerPage] = useState(firstRowsPerPage);
	const [page, setPage] = useState(0);

	return (
		<Fragment>
			<Card className={classes.root}>
				<PerfectScrollbar>
					<Table>
						<TableHeader
							headCells={headCells}
						/>
						<TableContent
							items={items}
							page={page}
							rowsPerPage={rowsPerPage}
						>
							{(item) => children(item)}
						</TableContent>
					</Table>
				</PerfectScrollbar>
				<CustomTableFooter
					count={rawData?.count}
					page={pageIndex - 1}
					pageIndex={pageIndex}
					setPageIndex={setPageIndex}
				/>
			</Card>
		</Fragment>
	);
};

export default TableList;
