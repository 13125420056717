import React from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'

const Progress = () => {

    return (
        <LinearProgress color='secondary' />
    )
}

export default Progress