export const OptionsOrderBy = [
    {
        id: -1,
        name: 'Name',
        value: 'fullName'
    },
    {
        id: 1,
        name: 'Year of birth',
        value: 'age'
    }
]


export const RoleAll = {
    id: -1,
    short_name: 'All'
}

export const PositionAll = {
    id: -1,
    full_name: 'All'
}

export const CompetitionsAll = {
    id: -1,
    name: 'All'
}

export const CountryAll = {
    id: -1,
    name: 'All'
}

export const OptionsFoot = [    
    {
        id: 'TwoFooted',
        name: 'Two-Footed',
    },
    {
        id: 'Left',
        name: 'Left',
    },
    {
        id: 'Right',
        name: 'Right',
    }
]