import React from 'react';
import { TableCell, TableRow, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        color: '#16282f',
        fontWeight: "bold",
        textAlign: 'center',
        fontSize: '1rem',
        overflow: 'visible',
        textTransform: 'capitalize',
        "& .MuiTableRow:nth-child(odd)": {
            backgroundColor: "#fff"
        },
        "& .MuiTableRow:nth-child(even)": {
            backgroundColor: "#f3f5f6"
        },
        "& .MuiTableCell-root": {
            color: '#16282f',
            textAlign: 'left',
            fontSize: '1rem',

        },
        "& .MuiTableCell-root:first-child": {
            textTransform: 'uppercase',
            fontWeight: "bold"
        },

    }
})

const Row = ({
    player,
    onClickDetail = () => { },
}) => {

    const classes = useStyles()

    return (
        <TableRow key={player.id} className={classes.root}>
            <TableCell>{player.fullName}</TableCell>
            <TableCell>{player.role}</TableCell>
            <TableCell>{player.age}</TableCell>
            <TableCell>{player.nationality}</TableCell>
            <TableCell>{player.club}</TableCell>
            <TableCell>{player.league}</TableCell>
            <TableCell onClick={() => onClickDetail(player)} style={{ cursor: 'pointer' }}>Detail</TableCell>
        </TableRow>
    )
}

export default Row;