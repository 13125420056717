import React, { useState, Fragment } from 'react'
import { makeStyles } from '@material-ui/styles';
import { Button, Typography } from '@material-ui/core'
import Edit from '@material-ui/icons/Edit';
import UpdateCommentContainer from '../../containers/activity/UpdateComment.container.js'

const useStyles = makeStyles(() => ({
    actionOnCommentButton: {
        backgroundColor: "transparent",
        backgroundRepeat: "no-repeat",
        border: "none",
        cursor: "pointer",
        overflow: "hidden",
        outline: "none",
        float: "right",
    }
}));


const EditCommentContainer = ({
    activityId,
    commentIdArg,
    refreshActivity,
    comment
}) => {

    const classes = useStyles();
    const [editIconClicked, setEditIconClicked] = useState(false);
    const [commentId, setCommentId] = useState(false);

    const showEditCommentBox = (commentId) => {
        setEditIconClicked(!editIconClicked)
        setCommentId(commentId)
    }

    return (
        <Fragment>
            <Button className={classes.actionOnCommentButton} onClick={() => showEditCommentBox(commentIdArg) }>
                <Edit/>
            </Button>
            { editIconClicked && commentId === commentIdArg ?
                <UpdateCommentContainer comment={comment} commentId = {commentIdArg} showEditCommentBox={showEditCommentBox} refreshActivity = {refreshActivity} activityId = {activityId} />
                :
                <Typography className={classes.commentText}>{comment}</Typography>
            }
        </Fragment>
    )
}

export default EditCommentContainer