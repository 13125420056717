import React, { Fragment, useState, useEffect, useCallback } from "react";
import ClubProposalTeamsSectionComponent from "../../components/club_proposal/ClubProposalTeamsSection.component";
import { Grid } from "@material-ui/core";
import ClubPartnershipTeamsContainer from "../club_partnership/ClubPartnershipTeams.container";
import useSWRRequest from "../../core/useSWRRequest";
import Requests from "../../core/requests";
import { useSelector } from "react-redux";
import Loading from "../../components/Loading";
import Modal from '../../components/Modal'
import RequestAddContainer from '../requests/RequestAdd.container'
import { Partner } from "../../conf/Auth.config";

const ClubProposalContainer = ({identifier}) => {

    const { token, user } = useSelector((state) => state.user);
    const { data, loading, mutate } = useSWRRequest(Requests.competitions.competitionsList, { token: token.access });
    const [openCreate, setOpenCreate] = useState(false);

    useEffect(() => {
        refreshComponent();
    }, [openCreate])


    const refreshComponent = useCallback(() => {
        if (!openCreate) {
            mutate()
        }
    }, [])

    const isNotPartnerUser = useCallback(() => {
        return user?.groups?.find(element => element.name !== Partner);
    }, [])

    return (
        <Fragment>
            <Grid item xs={12}>
                {loading && <Loading />}
            </Grid>
            <Grid container>

                <Modal
                    open={openCreate}
                    onClose={() => setOpenCreate(false)}
                    title='Create Club Proposal'>
                    <RequestAddContainer onClose={() => setOpenCreate(false)} />
                </Modal>
                <Grid item xs={12}>
                    {data.map((item) => (
                        <ClubProposalTeamsSectionComponent refreshComponent={refreshComponent} isNotPartnerUser={isNotPartnerUser} nationality={item.country.id} key={item.country.id} title={item.country.name}>
                            <ClubPartnershipTeamsContainer identifier={identifier} elements={item.competitions} countryId={item.country.id} />
                        </ClubProposalTeamsSectionComponent>
                    ))}
                </Grid>

            </Grid>
        </Fragment>
    );
}

export default ClubProposalContainer