export const InboxEndpoints = {
    basePath: 'api/v1/',
    endpoints: {
        calendar: {
            path: 'inbox/calendar/',
            method: 'GET',
            get: 'data',
            fallback: [],
            auth: true,
            swr: {
                revalidateOnFocus: false
            }
        },
        saveCalendar: {
            path: 'inbox/calendar/',
            method: 'POST',
            get: 'data',
            fallback: null,
            auth: true,
        },
        mandates: {
            path: 'inbox/mandate/',
            method: 'GET',
            get: 'data',
            fallback: {},
            auth: true,
            swr: {
                revalidateOnFocus: false
            }
        },
        saveMandates: {
            path: 'inbox/mandate/',
            method: 'POST',
            get: 'data',
            fallback: null,
            auth: true,
        },
        clubRequest: {
            path: 'inbox/club-request/',
            method: 'GET',
            get: 'data',
            fallback: [],
            auth: true,
            swr: {
                revalidateOnFocus: false
            }
        },
        saveClubRequest: {
            path: 'inbox/club-request/',
            method: 'POST',
            get: 'data',
            fallback: null,
            auth: true,
        },
        chat: {
            path: 'inbox/chat/',
            method: 'GET',
            get: 'data',
            fallback: [],
            auth: true,
            swr: {
                revalidateOnFocus: false
            }
        },
        saveChat: {
            path: 'inbox/chat/',
            method: 'POST',
            get: 'data',
            fallback: null,
            auth: true,
        },
        tagUsers: {
            path: 'users/?groups=HeadCompany',
            method: 'GET',
            get: 'data',
            fallback: [],
            auth: true,
            swr: {
                revalidateOnFocus: false
            }
        },
        tagPlayerRequestUsers: {
            path: '/users/?groups=HeadCompany&groups=Partner&groups=PartnerOperator&groups=MarketOperator',
            method: 'GET',
            get: 'data',
            fallback: [],
            auth: true,
            swr: {
                revalidateOnFocus: false
            }
        },
        deleteChatItem: {
            path: 'inbox/chat/{{id}}/',
            method: 'DELETE',
            fallback: null,
            auth: true
        },
        deleteCalendarItem: {
            path: 'inbox/calendar/{{id}}/',
            method: 'DELETE',
            fallback: null,
            auth: true
        },
        deleteMandatesItem: {
            path: 'inbox/mandate/{{id}}/',
            method: 'DELETE',
            fallback: null,
            auth: true
        },
        deleteClubRequestItem: {
            path: 'inbox/club-request/{{id}}/',
            method: 'DELETE',
            fallback: null,
            auth: true
        },

    }
}

export default InboxEndpoints;