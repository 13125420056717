import React, { useState, Fragment } from 'react'
import Loading from '../../components/progress/Progress.component'
import AddLinkComponent from '../../components/club_partnership/AddLink.component'
import { LinkInitialValues, LinkAddValidationSchema } from '../../conf/Link.conf'
import { useSelector } from 'react-redux'
import Requests from '../../core/requests'
import CallOutcomeComponent from '../../components/club_partnership/CallOutcome.component'

const AddLinkContainer = ({
    onClose,
    playerId,
    mutate,
    showUsersInvolved
}) => {

    const { token } = useSelector(state => state.user)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const onSubmit = (values, actions) => {
        if (playerId) {
            setLoading(true)
            actions.setSubmitting(true)
            Requests.reports.addLink({
                token: token.access,
                player: parseInt(playerId),
                url: values.url,
                name: values.name,
                ...(values.operators && values.operators.length && { users: values.operators })
            }).then(() => {
                setLoading(false)
                setError(null)
                actions.setSubmitting(false)
                actions.resetForm();
                mutate();
                onClose();
            }).catch((error) => {
                setLoading(false)
                setError(error?.response?.data?.error)
                actions.setSubmitting(false)
                actions.resetForm();
            })
        }
    }

    return (
        <Fragment>
            <CallOutcomeComponent error={error} />
            {loading ? <Loading /> : null}
            <AddLinkComponent showUsersInvolved={showUsersInvolved} initialValues={LinkInitialValues()} validationSchema={LinkAddValidationSchema} onSubmit={onSubmit} />
        </Fragment>
    )
}

export default AddLinkContainer