import _ from 'lodash';

export const PlayersEndpoints = {
    basePath: 'api/v1/players/',
    endpoints: {
        assisted: {
            path: '/assisted/?team_type={{team_type}}',
            method: 'GET',
            get: 'data',
            fallback: [],
            auth: true,
            swr: {
                revalidateOnFocus: false
            }
        },
        player: {
            path: '/players/{{id}}/',
            method: 'GET',
            get: 'data',
            fallback: {},
            auth: true,
            swr: {
                revalidateOnFocus: false
            }
        },
        monitored: {
            path: '/assisted/?team_type={{team_type}}&monitored=True',
            method: 'GET',
            get: 'data',
            fallback: [],
            auth: true,
            swr: {
                revalidateOnFocus: false
            }
        },
        assistedPlayer: {
            path: '/assisted/{{id}}/',
            method: 'GET',
            get: 'data',
            fallback: null,
            auth: true,
            swr: {
                revalidateOnFocus: false
            }
        },
        assistedPlayerStatus: {
            path: '/assisted/{{id}}/',
            method: 'PATCH',
            get: 'data',
            fallback: null,
            auth: true
        },
        interesting: {
            path: '/players/{{query}}&page={{pageIndex}}&page_size=20',
            method: 'GET',
            get: 'data',
            fallback: [],
            auth: true,
            swr: {
                revalidateOnFocus: false,
                revalidateOnMount: false,
                revalidateOnReconnect: false,
                dedupingInterval: 1000,
            },
            transform: (data) => data.results
                .map((item) => ({
                    fullName: item.full_name,
                    name: item.full_name,
                    role: item.roles.map((role, index) => index === item.roles.length - 1 ? `${role.short_name}` : `${role.short_name}-`),
                    age: item.age,
                    nationality: item.birth_country.name,
                    club: item.team.name,
                    league: item.team.country.name,
                    id: item.id,
                    player: item,
                    isSelected: item.selection_changed_by ? 'Yes' : '',
                    evalutation: item?.marks?.mean || 0
                }))
                .sort((a, b) => a.name.localeCompare(b.name))
        },
        interestingRaw: {
            path: '/players/{{query}}&page={{pageIndex}}&page_size=20',
            method: 'GET',
            get: 'data',
            fallback: [],
            auth: true,
            swr: {
                revalidateOnFocus: false,
                revalidateOnMount: false,
                revalidateOnReconnect: false,
                dedupingInterval: 1000,
            },
        },
        elite: {
            path: '/players/{{query}}',
            method: 'GET',
            get: 'data',
            fallback: [],
            auth: true,
            swr: {
                revalidateOnFocus: false,
                revalidateOnMount: false,
                revalidateOnReconnect: false,
                dedupingInterval: 1000,
            },
            transform: (data) => data.map((item) => ({
                fullName: item?.full_name,
                name: item?.full_name,
                role: item?.roles?.map((role, index) => index === item?.roles?.length - 1 ? `${role.short_name}` : `${role.short_name}-`),
                age: item?.age,
                nationality: item?.birth_country?.name,
                club: item?.team?.name,
                league: item?.team?.country?.name,
                id: item?.id,
                player: item,
                isSelected: item?.is_selected ? 'Yes' : '',
                evalutation: item?.marks?.mean || 0
            })).sort((a, b) => a.name.localeCompare(b.name))
        },
        players: {
            path: '/players/{{query}}',
            method: 'GET',
            get: 'data',
            fallback: [],
            auth: true
        },
        elitePortfolio: {
            path: '/elite-portfolio/{{query}}',
            method: 'GET',
            get: 'data',
            fallback: [],
            auth: true,
            swr: {
                revalidateOnFocus: false,
                revalidateOnMount: false,
                revalidateOnReconnect: false,
                dedupingInterval: 1000,
            },
            transform: (data) => data.map((item) => ({
                fullName: item?.full_name,
                name: item?.full_name,
                role: item?.roles?.map((role, index) => index === item?.roles?.length - 1 ? `${role.short_name}` : `${role.short_name}-`),
                age: item?.age,
                nationality: item?.birth_country?.name,
                club: item?.team?.name,
                league: item?.team?.country?.name,
                id: item?.id,
                player: item,
                isSelected: item?.is_selected ? 'Yes' : '',
                evalutation: item?.marks?.mean || 0
            })).sort((a, b) => a.name.localeCompare(b.name))
        },
        interestingPlayer: {
            path: '/players/{{id}}/',
            method: 'GET',
            get: 'data',
            fallback: null,
            auth: true,
            swr: {
                revalidateOnFocus: false
            }
        },
        interestingSelectFlag: {
            path: '/players/{{id}}/update-selected/',
            method: 'POST',
            fallback: null,
            auth: true,
        },
        interestingSelectFlagElite: {
            path: '/players/{{id}}/update-elite-flag/',
            method: 'POST',
            fallback: null,
            auth: true,
        },
        interestingSelectFlagMonitored: {
            path: '/assisted/{{id}}/update-monitored-flag/',
            method: 'POST',
            fallback: null,
            auth: true,
        },
        addPlayer: {
            path: '/assisted/',
            method: 'POST',
            fallback: null,
            auth: true,
        },
        updatePlayer: {
            path: '/players/{{id}}/',
            method: 'PATCH',
            fallback: null,
            auth: true,
        },
        playersByTeam: {
            path: '/players/?teams={{teamId}}',
            method: 'GET',
            get: 'data',
            fallback: [],
            auth: true,
            swr: {
                revalidateOnFocus: false
            }
        },
        getCommentsByPlayer: {
            path: '/players/{{playerId}}/comments/',
            method: 'GET',
            get: 'data',
            fallback: [],
            auth: true,
            swr: {
                revalidateOnFocus: false
            }
        },
        addPlayerComment: {
            path: '/players/{{id}}/comments/',
            method: 'POST',
            get: 'data',
            fallback: null,   
            auth: true,    
        },
        deletePlayerComment: {
            path: '/players/{{id}}/comments/{{commentId}}/',
            method: 'DELETE',
            fallback: null,   
            auth: true
        }
    }
}

export default PlayersEndpoints;