import _ from 'lodash';

export const CompetitionsEndpoints = {
    basePath: 'api/v1/competitions/',
    endpoints: {
        competitions: {
            path: '/competitions/',
            method: 'GET',
            get: 'data',
            fallback: [],
            transform: (data) => data.sort((a, b) => a.name.localeCompare(b.name)),
            auth: true,
            swr: {
                revalidateOnFocus: false
            }
        },
        competitionsByCountry: {
            path: '/competitions/?countries={{countryId}}',
            method: 'GET',
            get: 'data',
            fallback: [],
            transform: (data) => data.sort((a, b) => a.name.localeCompare(b.name)),
            auth: true,
            swr: {
                revalidateOnFocus: false
            }
        },
        competitionsList: {
            path: 'area-competitions/',
            method: 'GET',
            get: 'data',
            fallback: [],
            transform: (data) => data?.sort((a, b) => a?.country?.name?.localeCompare(b?.country?.name)), 
            auth: true,    
        },
        getCompetition: {
            path: 'competitions/{{competitionId}}/',
            method: 'GET',
            get: 'data',
            fallback: [],   
            auth: true,    
        }   
    }
}

export default CompetitionsEndpoints;