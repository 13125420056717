import React, { useCallback, useMemo, useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import InputIcon from "@material-ui/icons/Input";
import {
    AppBar,
    Toolbar,
    Typography,
    Grid,
    CssBaseline,
    Box,
    IconButton,
    Menu,
    MenuItem,
} from "@material-ui/core";
import { style } from "./MenuBar.component.style";
import {
    MenuHeadCompanyConf,
    MenuHeadScountingConf,
    MenuScountingConf,
    MenuExternalAgentConf,
    MenuExternalMarketOperatorConf,
    MenuDefault,
    MenuPartnerConf,
} from "../../conf/Menu.conf";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    HeadCompany,
    HeadScout,
    Scout,
    Partner,
    MarketOperator,
} from "../../conf/Auth.config";
import { logout } from "../../store/user.store";

const MenuBar = ({ classes }) => {
    const { url } = useRouteMatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentItem, setCurrentItem] = useState(null);
    const dispatch = useDispatch();
    const { token, user } = useSelector((state) => state.user);

    const onSubmitLogout = useCallback(() => {
        dispatch(logout(token));
    }, []);

    const handleClick = useCallback((event, item) => {
        setAnchorEl(event.currentTarget);
        setCurrentItem(item);
    }, []);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
        setCurrentItem(null);
    }, []);

    const menu = useMemo(() => {
        if (user) {
            const groups = user.groups[0].name;

            if (HeadCompany.includes(groups)) {
                return MenuHeadCompanyConf;
            }

            if (HeadScout.includes(groups)) {
                return MenuHeadScountingConf;
            }

            if (Scout.includes(groups)) {
                return MenuScountingConf;
            }

            if (Partner.includes(groups)) {
                return MenuPartnerConf;
            }

            if (MarketOperator.includes(groups)) {
                return MenuExternalMarketOperatorConf;
            }
        }

        return MenuDefault;
    }, [user]);

    return (
        <Fragment>
            <CssBaseline />
            <AppBar position="fixed">
                <Toolbar className={classes.toolbar}>
                    <Grid container alignItems="center">
                        <Grid item xs={1} />
                        <Grid container item xs={3}>
                            <Grid item xs>
                                <Typography className={classes.title}>{menu.title}</Typography>
                                <Typography className={classes.subtitle}>
                                    {user.first_name} {user.last_name}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container item xs={7} justifyContent="center">
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                height={1}
                                width={1}
                            >
                                {menu.menu.map((menu, index) => (
                                    <Grid key={index} item>
                                        {menu.items ? (
                                            <Fragment>
                                                <Typography
                                                    className={
                                                        url.split("/")[1] === menu.url.substring(1)
                                                            ? classes.selected
                                                            : classes.menu
                                                    }
                                                    onClick={(event) => handleClick(event, menu.title)}
                                                >
                                                    {menu.title}
                                                </Typography>
                                                <Menu
                                                    id="nested-menu"
                                                    anchorEl={anchorEl}
                                                    keepMounted
                                                    open={Boolean(anchorEl) && currentItem === menu.title}
                                                    onClose={handleClose}
                                                >
                                                    {menu.items.map((submenu, index) => (
                                                        <Link
                                                            to={submenu.url}
                                                            key={index}
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            <MenuItem onClick={handleClose}>
                                                                <Typography
                                                                    className={
                                                                        url.split("/")[1] === menu.url.substring(1)
                                                                            ? classes.selected
                                                                            : classes.nestedMenu
                                                                    }
                                                                >
                                                                    {submenu.title}
                                                                </Typography>
                                                            </MenuItem>
                                                        </Link>
                                                    ))}
                                                </Menu>
                                            </Fragment>
                                        ) : (
                                            <Link to={menu.url} style={{ textDecoration: "none" }}>
                                                <Typography
                                                    className={
                                                        url.split("/")[1] === menu.url.substring(1)
                                                            ? classes.selected
                                                            : classes.menu
                                                    }
                                                >
                                                    {menu.title}
                                                </Typography>
                                            </Link>
                                        )}
                                    </Grid>
                                ))}
                            </Box>
                        </Grid>
                        <Grid item xs={1} justifyContent='center' >
                            <IconButton color="inherit" onClick={() => onSubmitLogout()}>
                                <InputIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </Fragment>
    );
};

export default withStyles(style)(MenuBar);
