import React, { useState, Fragment, useEffect, useCallback } from 'react'
import { Box, LinearProgress, Typography } from '@material-ui/core'
import FilterAutocompleteComponent from '../../components/filter/FilterAutocomplete.component'
import { useSelector } from 'react-redux'
import useSWRRequest from '../../core/useSWRRequest'
import Requests from '../../core/requests'

const FilterTeamContainer = ({
    onChange = () => { },
    values,
    disableNationalityField,
    disableClubField,
    competitionId,
    ...props
}) => {

    const { token } = useSelector(state => state.user)
    const { data: countries, loading } = useSWRRequest(Requests.areas.countriesHasTeams, { token: token.access })
    const [teams, setTeams] = useState(false)
    const [loadingTeams, setLoadingTeams] = useState(false)

    useEffect(() => {
        if(values?.nationality && values?.competitionId){
            searchTeamsByCountryAndCompetition(values?.nationality, values?.competitionId);
        }

    }, [values?.nationality]);

    const searchTeamsByCountryAndCompetition = useCallback((countryId, competitionId) => {
        setLoadingTeams(true)
        if(countryId && competitionId){
            Requests.teams.teamsByCountryAndCompetition({ token: token.access, countryId, competitionId}).then((data) => {
                setTeams(data.map((item) => ({
                    id: item.id,
                    name: item.name
                })))
                setLoadingTeams(false)
            }).catch((error) => {
                setLoadingTeams(false)
            })
        }
    }, []);
    
    const setNationality = () => {
        if(values.nationality && !loading && countries){
            return countries.filter(nationality => nationality.id == values.nationality)[0];
        }
    };
    const setClub = () => {
        if(values.team && !loadingTeams && teams){
            return teams.filter(club => club.id == values.team)[0];
        }
    };


    return (
        <Fragment>
            <Box mt={2}>
                <Typography>Nations</Typography>
                {loading ? <LinearProgress /> : null}
                <FilterAutocompleteComponent disabled={disableNationalityField} value={setNationality() || []} options={countries} setFilter={(value) => onChange('nationality', value)} name = 'nationality' {...props} />
            </Box>
            {loadingTeams ? <LinearProgress /> : null}
            {values?.nationality && teams?.length && !loadingTeams ?
                <Box mt={2}>
                    <Typography>Club</Typography>
                    <FilterAutocompleteComponent disabled={disableClubField} value={setClub() || []} options={teams} setFilter={(value) => onChange('team', value)} name="team" {...props}/>
                </Box> : null}
        </Fragment>
    )
}

export default FilterTeamContainer