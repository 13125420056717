import React, { useCallback, useState, useEffect, Fragment } from "react";
import { Box, Paper, Typography, makeStyles } from "@material-ui/core";
import MainLayout from "../../layout/main/Main.layout";
import ContentLayout from "../../layout/content/Content.layout";
import ScoutingPlayerContainer from "../../containers/player/ScountingPlayer.container";
import ElitePlayersContainer from "../../containers/elite/ElitePlayers.container";
import ScoutingFilterContainer from "../../containers/scouting/ScoutingFilter.container";
import RequestsFilterContainer from "../../containers/requests/RequestsFilter.container";
import { useHistory } from "react-router";
import RequestListContainer from "../../containers/requests/RequestsList.container";
import { useSelector } from "react-redux";
import { MarketOperator } from "../../conf/Auth.config";
import RequestListOperatorContainer from "../../containers/requests/RequestListOperator.container";

const useStyles = makeStyles({
    container: {
        margin: "0rem 0rem 5rem 0rem",
        padding: "0rem 2rem 2rem 0rem",
    },
    title: {
        fontSize: "30px",
        fontFamily: "Anton-Regular",
    },
});

const PlayersElite = () => {
    const classes = useStyles();
    const [overlay, setOverlay] = useState(false);
    const [player, setPlayer] = useState(null);
    const [filter, setFilter] = useState(null);
    const identifier = "elitePage";

    const onSubmit = useCallback((values) => {
        setFilter(values);
    }, []);


    const openOverlay = (player) => {
        setOverlay(true);
        setPlayer(player);
    };

    const closeOverlay = () => {
        setOverlay(false);
        setPlayer(null);
    };

    return (
        <ContentLayout>
            <Box mt={2} width={1}>
                <Typography className={classes.title}>Players</Typography>
                <Paper>
                    <Box padding={4}>
                        <ScoutingFilterContainer identifier={identifier} onSubmit={onSubmit} />
                    </Box>
                </Paper>
            </Box>
            <Box mt={2} width={1}>
                <ElitePlayersContainer
                    filter={filter}
                    openOverlay={(player) => openOverlay(player)}
                    open={overlay}
                    identifier={identifier}
                />
                {overlay ? (
                    <ScoutingPlayerContainer
                        id={player.id}
                        closeOverlay={closeOverlay}
                        open={overlay}
                        identifier = {identifier}
                    />
                ) : null}
            </Box>
        </ContentLayout>
    );
};

/* const ProposalElite = () => {
    const history = useHistory();
    const classes = useStyles();
    const [filter, setFilter] = useState(null);

    const onSubmit = useCallback((values) => {
        setFilter(values);
    }, []);

    const onSelectItem = useCallback((id) => {
        history.push(`/club_requests/${id}`);
    }, []);

    return (
        <ContentLayout>
            <Box mt={2} width={1}>
                <Typography className={classes.title}>Proposal</Typography>
                <Paper>
                    <Box padding={4}>
                        <RequestsFilterContainer onSubmit={onSubmit} />
                    </Box>
                </Paper>
            </Box>
            <Box mt={2} width={1}>
                <RequestListContainer
                    filter={filter}
                    onAddAction={() => { }}
                    onSelectItem={onSelectItem}
                    open={false}
                />
            </Box>
        </ContentLayout>
    );
};

const ProposalMarketOperator = () => {
    const classes = useStyles();

    return (
        <ContentLayout>
            <Box mt={2} width={1}>
                <Typography className={classes.title}>Proposal</Typography>
            </Box>
            <Box mt={2} width={1}>
                <RequestListOperatorContainer />
            </Box>
        </ContentLayout>
    );
};
 */
const ElitePage = () => {
    const { user } = useSelector((state) => state.user);
    const [marketOperator, setMarketOperator] = useState(false);

    useEffect(() => {
        if (user) {
            const groups = user.groups[0].name;
            if (MarketOperator.includes(groups)) {
                setMarketOperator(true);
            }
        }
    }, [user]);

    return (
        <MainLayout title="Elite Scouting List" elite={true}>
            {/* {marketOperator ? (
                <ProposalMarketOperator />
            ) : ( */}
            <Fragment>
                <PlayersElite />
                {/* <ProposalElite /> */}
            </Fragment>
            {/* )} */}
        </MainLayout>
    );
};

export default ElitePage;
