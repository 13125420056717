import React, { useState, Fragment, useCallback } from 'react'
import Loading from '../../components/progress/Progress.component'
import { Formik } from 'formik';
import AddMandateComponent from '../../components/agency_partnership/AddMandate.component'
import { AgencyPartnershipMandateInitialValues, AgencyPartnershipMandateValidationSchema, BAPortfolioMandateValidationSchema, PartnerProposalValidationSchema } from '../../conf/AgencyPartnershipMandate.config';
import { useSelector } from 'react-redux'
import Requests from '../../core/requests'
import CallOutcomeComponent from '../../components/club_partnership/CallOutcome.component'

const AddMandateContainer = ({
    onClose = () => { },
    agencyId,
    countryId,
    identifier
}) => {

    const { token } = useSelector(state => state.user)
    const [loading, setLoading] = useState(false)
    const [team, setTeam] = useState(null)
    const [error, setError] = useState(null)

    const setEndpointAndRequestObj = (values) => {
        switch (identifier) {
            case "agencyPartnership":
                return Requests.agency_partnership.addMandate(({
                    token: token.access,
                    agency: values.agency,
                    team: values.team,
                    type: values.typeStatus,
                    players: values.players,
                    work_status: values.workStatus,
                    users: values.usersInvolved,
                    deadline: values.deadline,
                    note: values.note
                }))
            case "partnerProposal":
                return Requests.partner_proposal.addProposal(({
                    token: token.access,
                    agency: values.agency,
                    team: team,
                    players: [values.players],
                    users: values.usersInvolved,
                    note: values.note
                }))
            case "baPortfolio":
                return Requests.ba_portfolio.addMandate(({
                    token: token.access,
                    agency: values.agency,
                    team: team,
                    type: values.typeStatus,
                    players: [values.players],
                    work_status: values.workStatus,
                    users: values.usersInvolved,
                    deadline: values.deadline,
                    note: values.note
                }))
        }
    }

    const onSubmit = (values, actions) => {
        setLoading(true)
        actions.setSubmitting(true)
        setEndpointAndRequestObj(values).then(() => {
            setLoading(false)
            setError(null)
            actions.setSubmitting(false)
            onClose()
        }).catch((error) => {
            setLoading(false)
            setError(error?.response?.data?.error)
            actions.setSubmitting(false)
        })
    }

    const setValidationSchema = useCallback((identifier) => {
        if(identifier === "partnerProposal"){
            return PartnerProposalValidationSchema;
        }else if(identifier === "baPortfolio"){
            return BAPortfolioMandateValidationSchema;
        }else{
            return AgencyPartnershipMandateValidationSchema;
        }
    }, []);

    return (
        <Fragment>
            <CallOutcomeComponent error = {error} />
            {loading ? <Loading /> : null}
            <Formik
                initialValues={AgencyPartnershipMandateInitialValues({country: Number(countryId), agency: {id: Number(agencyId)}})}
                validationSchema={setValidationSchema(identifier)}
                enableReinitialize={true}
                onSubmit={onSubmit} >
                {(props) => <AddMandateComponent identifier = { identifier } country = {Number(countryId)} agencyId = {Number(agencyId)} setTeam={setTeam} {...props} />}
            </Formik>
        </Fragment>
    )
}

export default AddMandateContainer