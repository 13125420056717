import React, { Fragment } from 'react'
import { Grid, makeStyles } from '@material-ui/core';
import { Form } from 'formik'
import { Box, Card } from '@material-ui/core';
import EditText from '../../components/EditText';
import Submit from '../../components/Submit';
import BranchiniImg from '../../assets/images/branchini-login.png'
import DeskLogo from '../../assets/images/footure-desk.png'
import BackgroundImg from '../../assets/images/login.png'

const useStyles = makeStyles((theme) => ({
    imageWrapper: {
        backgroundImage: `url(${BackgroundImg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        position: 'relative',
        height: "100vh",
        justifyContent: 'center',
        textAlign: 'center'
    },
    imageBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: "2rem auto 0rem auto"
    },
    card: {
        padding: '0rem 3rem 3rem 3rem',
        borderRadius: '2px',
        boxShadow: 'none',
        width: '100%',
        maxWidth: '465px',
        margin: 'auto',
        marginTop: '2rem'
    },
    title: {
        padding: theme.spacing(1),
        fontSize: theme.spacing(4),
        fontWeight: 'bold',
        color: theme.palette.text.secondary,
    },
}));


const LoginComponent = (props) => {

    const classes = useStyles();

    return (
        <Grid container className={classes.imageWrapper}>
            <Grid item xs={12}>
                <img src={DeskLogo} height="100px" style={{ marginTop: '1rem' }} />
                <Card className={classes.card}>
                    <Box style={{ display: 'flex', justifyContent: 'center', paddingTop: '1rem' }}>
                        <img src={BranchiniImg} height='100px' />
                    </Box>
                    <Form onSubmit={props.handleSubmit}>
                        <Box mt={2}>
                            <EditText {...props} label='Username' name="username" />
                        </Box>
                        <Box mt={2}>
                            <EditText {...props} type='password' label='Password' name="password" />
                        </Box>
                        <Box my={2}>
                            <Submit {...props} title='Login' />
                        </Box>
                    </Form>
                </Card>

            </Grid >

        </Grid >
    )
}

export default LoginComponent