import React from 'react'
import MainLayout from '../../layout/main/Main.layout'
import AgencyMandateContainer from '../../containers/agency_partnership/AgencyMandate.container'

const ProposalPage = () => {

    return (
        <MainLayout title="Proposal">
            <AgencyMandateContainer caller="partnerProposal" />
        </MainLayout>
    )
}

export default ProposalPage