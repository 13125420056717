import React, { useState } from 'react'
import Loading from '../../components/progress/Progress.component'
import { Formik } from 'formik';
import UpdateClubRequest from '../../components/requests/UpdateClubRequest'
import { RequestUpdateInitialValues } from '../../conf/Request.conf'
import { useSelector } from 'react-redux'
import Requests from '../../core/requests'
import { Paper } from '@material-ui/core';
import useSWRRequest from '../../core/useSWRRequest'
import { useParams } from 'react-router-dom';
import CallOutcomeComponent from '../../components/club_partnership/CallOutcome.component'

const RequestUpdateContainer = () => {

    const { id } = useParams();
    const { token } = useSelector(state => state.user)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const { data, mutate } = useSWRRequest(Requests.notes.note, { token: token.access, id })

    const onSubmit = (values, actions) => { 
        setLoading(true)
        actions.setSubmitting(true)
        Requests.notes.update({
            id,
            token: token.access
        }, {
            deadline: values.deadline,
            note: values.note,
            work_status: values.work_status,
            operators: values.operators
        }).then(() => {
            setLoading(false)
            setError(null)
            actions.setSubmitting(false)
            mutate()
        }).catch((error) => {
            setLoading(false)
            setError(error?.response?.data?.error)
            actions.setSubmitting(false)
        })
    }

    return (
        <Paper>
            <CallOutcomeComponent error = {error} />
            {loading ? <Loading /> : null}
            {data &&
                <Formik
                    initialValues={RequestUpdateInitialValues(data)}
                    onSubmit={onSubmit} >
                    {(props) => <UpdateClubRequest {...props} />}
                </Formik>}
        </Paper>
    )
}

export default RequestUpdateContainer