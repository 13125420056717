import React from 'react'
import { Formik } from 'formik'
import ScountingFilterComponent from '../../components/filter/ScountingFilter.component'

const ScoutingFilterContainer = ({
    onSubmit = () => { },
    onChange = () => { },
    identifier
}) => {

    return (

        <Formik
            initialValues={{
                roles: [],
                leagues: null,
                league_countries: null,
                footedness: null,
                min_height: 150,
                max_height: 210,
                min_age: 14,
                max_age: 46,
                full_name: ''
            }}
            onSubmit={onSubmit}>
            {(props) => <ScountingFilterComponent identifier = {identifier} {...props} onChange={onChange} />}
        </Formik>
    )
}

export default ScoutingFilterContainer