import _ from 'lodash';

export const TeamsEndpoints = {
    basePath: 'api/v1/teams',
    endpoints: {
        teams: {
            path: '/teams/',
            method: 'GET',
            get: 'data',
            fallback: {
                competitions: [],
                teams: [],
                nationalities: []
            },
            auth: true,
            transform: (data) => ({
                teams: data,
                nationalities: _.uniqBy(data.map((item) => ({
                    id: item.country.id,
                    name: item.country.name
                })), function (element) {
                    return element.id
                }).sort((a, b) =>
                    a.name.localeCompare(b.name)
                ),
                competitions: _.uniqBy(data.map((item) => item.competitions.length > 0 ? item.competitions[0] : null).filter((item) => item !== null), function (element) {
                    return element.id
                }).sort((a, b) =>
                    a.name.localeCompare(b.name)
                )
            }),
            swr: {
                revalidateOnFocus: false
            }
        },
        teamById: {
            path: '/teams/{{id}}/',
            method: 'GET',
            get: 'data',
            fallback: {
                competitions: [],
                teams: [],
                nationalities: []
            },
            auth: true,
        },
        teamsByCompetition: {
            path: '/teams/?competitions={{competitionId}}',
            method: 'GET',
            get: 'data',
            fallback: {
                competitions: [],
                teams: [],
                nationalities: []
            },
            auth: true,
        },
        teamsByCountry: {
            path: '/teams/?countries={{countryId}}',
            method: 'GET',
            get: 'data',
            fallback: {
                competitions: [],
                teams: [],
                nationalities: []
            },
            auth: true,
        },
        teamsByCountryAndCompetition: {
            path: '/teams/?countries={{countryId}}&competitions={{competitionId}}',
            method: 'GET',
            get: 'data',
            fallback: {
                competitions: [],
                teams: [],
                nationalities: []
            },
            auth: true,
        }

    }
}

export default TeamsEndpoints;