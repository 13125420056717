import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import MainLayout from '../../layout/main/Main.layout'
import InboxContainer from '../../containers/inbox/Inbox.container'
import ContentLayout from '../../layout/content/Content.layout'

const InboxPage = () => {

    let history = useHistory()

    const openDetail = useCallback((player) => {
        history.push(`inbox/${player.id}`)
    }, [])

    return (
        <MainLayout title='Inbox' logo={true}>
            <ContentLayout>
                <InboxContainer openDetail={openDetail} />
            </ContentLayout>
        </MainLayout>
    )
}

export default InboxPage