import React, { Fragment } from 'react'
import { LinearProgress } from '@material-ui/core'
import FilterAutocompleteComponent from '../../components/filter/FilterAutocomplete.component'
import useSWRRequest from '../../core/useSWRRequest'
import Requests from '../../core/requests'
import { useSelector } from 'react-redux'

const PlayerNationalityContainer = (props) => {

    const { token } = useSelector(state => state.user)
    const { data, loading } = useSWRRequest(Requests.areas.countries, { token: token.access })

    return (
        <Fragment>
            {loading ? <LinearProgress /> : null}
            <FilterAutocompleteComponent
                options={data}
                setFilter={(value) => props.setFieldValue('player_nationalities', value)} />
        </Fragment>
    )
}

export default PlayerNationalityContainer