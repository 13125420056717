import React from 'react';
import RowProposalComponent from './RowProposal.component';
import TableList from '../../components/table/Table';
import { isNotMarketOperatorUser } from '../../utils/CheckLoggedUser';
import { useSelector } from 'react-redux';

const headCells = [
    {
        id: 'players', label: 'Players', sortable: true
    },
    {
        id: 'position', label: 'Position', sortable: false
    },
    {
        id: 'age', label: 'Age', sortable: true
    },
    {
        id: 'team', label: 'Team', sortable: true
    },
    {
        id: 'country', label: 'Country', sortable: true
    },
    {
        id: 'delete', label: '', sortable: false
    },
    {
        id: 'detail', label: '', sortable: false
    },
]

const List = ({
    items = [],
    onAddAction = () => { },
    onSelectItem = () => { },
    onDeleteItem = () => { }
}) => {

    const { user } = useSelector(state => state.user)

    return (
        <TableList
            add={isNotMarketOperatorUser(user?.groups)}
            headCells={headCells}
            items={items}
            page={0}
            rowsPerPage={25}
            addAction={onAddAction}>
            {(item) => item.players.map((player) => <RowProposalComponent key={player.id} proposal={item.id} player={player} onSelectItem={onSelectItem} onDeleteItem={onDeleteItem}/>)}
        </TableList>
    );
};


export default List;
