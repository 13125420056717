import _ from 'lodash';

export const RolesEndpoints = {
    basePath: 'api/v1/roles/',
    endpoints: {
        positions: {
            path: '/positions/',
            method: 'GET',
            get: 'data',
            fallback: [],
            auth: true,
            swr: {
                revalidateOnFocus: false
            }
        },
        roles: {
            path: '/roles/',
            method: 'GET',
            get: 'data',
            fallback: [],
            auth: true,
            transform: (data) => data
                .map((item) => ({
                    name: item.short_name,
                    ...item
                }))
                .sort((a, b) => a.name.localeCompare(b.name)),
            swr: {
                revalidateOnFocus: false
            }
        },
    }
}

export default RolesEndpoints;