import { color } from '../../config/colors/colors.js';

export const style = theme => ({
    root: {
        minHeight: '320px',
        display: 'flex',
        alignItems: 'center'
    },

    container: {
        paddingTop: "0.8rem",
        paddingBottom: "0.8rem",

    },
    subtitle: {
        fontSize: theme.spacing(2),
        color: color.white,
    },
    divider: {
        marginTop: theme.spacing(1),
        height: theme.spacing(0.2),
        backgroundColor: color.white,
        width: theme.spacing(14)
    },
    title: {
        fontWeight: 'bold',
        fontSize: theme.spacing(6),
        color: color.white,
        marginTop: '0.5rem'
    },
    branchini: {
        position: 'relative',
        height: '125px',
        bottom: "4rem",
        // left: "65rem",
        width: '100%',
        // backgroundColor: 'red'
    }
});


