import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import Moment from "moment"
import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionIcon from '@material-ui/icons/Description';
import { isNotMarketOperatorUser } from '../../utils/CheckLoggedUser';
import { useSelector } from 'react-redux';

const Row = ({
    item,
    onSelectItem = () => { },
    onDeleteItem = () => { }
}) => {

    const { user } = useSelector(state => state.user)

    return (
        <TableRow key={item.id}>
            <TableCell>{item.team.name}</TableCell>
            {/* <TableCell>{item.role.map((item) => `${item.full_name} `)}</TableCell> */}
            <TableCell>{item?.position_text || item?.position?.name || "Not specified"}</TableCell>
            <TableCell>{Moment(item.created_at).format('YYYY-MM-DD')}</TableCell>
            <TableCell>{item.deadline}</TableCell>
            <TableCell>{item.work_status}</TableCell>
            <TableCell onClick={() => onDeleteItem(item.id)} style={{ cursor: 'pointer' }}><DeleteIcon style={isNotMarketOperatorUser(user?.groups) ? {} : {color: "#dddddd"}} /></TableCell>
            <TableCell onClick={() => onSelectItem(item.id)} style={{ cursor: 'pointer' }}><DescriptionIcon /></TableCell>
        </TableRow>
    )
}

export default Row;