import React, { useState, useCallback, useEffect } from 'react'
import { FormControl, Input, Box, Typography, Select, MenuItem, Button, Chip, Grid } from '@material-ui/core'
import Requests from '../../core/requests'
import { useSelector } from 'react-redux'
import _ from 'lodash'

const LeaveCommentComponent = ({classes, id, mutate, callerComponent, errorDeleteComment, setErrorDeleteComment}) => {

    const { token } = useSelector(state => state.user)
    const [tagUsers, setTagUsers] = useState([])
    const [taggedUsers, setTaggedUsers] = useState([])
    const [comment, setComment] = useState('')
    const [error, setError] = useState(false)
    const [disableCommentButton, setDisableCommentButton] = useState(false)

    // get tag users
    useEffect(() => {
        if(callerComponent){
            handleInboxRequest({ token }, callerComponent.taggableUsersEndPoint, (resp) => {
                    setTagUsers(resp)
                }, () => {
                    //todo handle error
                }
            )
        }
    }, [callerComponent])

    const addTaggedUser = useCallback((tagUser) => {
        if (_.includes(taggedUsers, tagUser) === false) {
            setTaggedUsers([...taggedUsers, tagUser])
        }
        setComment(comment)
    }, [taggedUsers, comment])

    const handleDelete = useCallback((item) => {
        setError(false)
        setErrorDeleteComment(false);
        setTaggedUsers((chips) => chips.filter((chip) => chip !== item));
    }, [taggedUsers, setTaggedUsers])

    const tagBaUsers = useCallback(() => {
        Requests.users.baUsers({
            token: token.access,
        }).then((response) => {
            setTaggedUsers(response)
        }).catch((error) => {
            setError(error?.response?.data?.error || "error")
        })

    }, [])


    const saveComment = useCallback(() => {
        if(comment){
            setDisableCommentButton(true);
            callerComponent.saveEndpoint({ token: token.access, id} , { users: taggedUsers.map(taggedUser => taggedUser.id), text: comment })
                .then(() => {
                    setDisableCommentButton(false);
                    setComment('')
                    setTaggedUsers([])
                    setError(null)
                    setErrorDeleteComment(null)
                    mutate()
                })
                .catch((error) => {
                    setDisableCommentButton(false);
                    setError(error)
                })
        }
    }, [taggedUsers, comment])    

    return (
        <>
            <Typography variant={"h5"} className={classes.comment}>Leave a comment</Typography>
            <FormControl className={classes.input} onClick={() => setError(false)}>
                <Input multiline={true} value={comment} onChange={(event) => setComment(event.target.value)} />
                <Box dispaly="flex">
                    {taggedUsers?.map((item, index) => <Chip
                        key={index}
                        label={` @${item['first_name']} ${item['last_name']}`}
                        variant="outlined"
                        onDelete={() => handleDelete(item)}
                        id={item.id}
                        className={classes.chip}
                    />)}
                </Box>
            </FormControl>
            <Grid container>
                <Grid container item xs={12} sm={12} md={7} lg={5}>
                    <Grid item xs={2}>
                        <Typography variant={"subtitle1"} className={classes.comment}>Tag users</Typography>
                    </Grid>
                    <Grid item xs={10} align="center">
                        <Box height={1} display="flex" alignItems="center" justifyContent="flex-start">
                            <Select
                                value={''}
                                onChange={(event) => addTaggedUser(event.target.value)}
                                onClick={() => setError(false)}
                                className={classes.selectTag}
                                variant={"outlined"}
                                inputProps={{
                                    classes: { select: classes.select },
                                }}>
                                {tagUsers.map((tagUser, index) => <MenuItem key={index} value={tagUser} className={classes.tagUserStyle} >{`${tagUser['first_name']} ${tagUser['last_name']}`}</MenuItem>)}
                            </Select>
                        </Box>

                    </Grid>
                </Grid>
                <Box ml={1} mt={3} height={1} display="flex" alignItems="center" >
                    <Button color='primary' size="large" variant="contained" onClick={() => setTaggedUsers(tagUsers)}>
                        Tagga tutti
                    </Button>
                </Box>
                <Box ml={1} mt={3} height={1} display="flex" alignItems="center" justifyContent="flex-start">
                    <Button color='primary' size="large" variant="contained" onClick={() => tagBaUsers()}>
                        Tagga BA
                    </Button>
                </Box>
            </Grid>

            { error || errorDeleteComment ? <Box align="right" mb={1} className={classes.error}>Si è verificato un errore. Si prega di riprovare.</Box> : null }
            <Box className={classes.buttonComment}>
                <Button color={"secondary"} size="large" variant="contained" onClick={saveComment} disabled={disableCommentButton}>
                    Add comment
                </Button>
            </Box>
        </>
    )
}

export default LeaveCommentComponent;

const handleInboxRequest = ({ token, payload }, taggableUsersEndpoint, callback, onError) => {
    taggableUsersEndpoint({ token: token.access, ...payload })
        .then((resp) => {
            callback(resp)
        })
        .catch((error) => {
            onError(error)
        })
}