import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { style } from "../players/PlayersSection.component.style";
import { Grid, Typography, Button } from "@material-ui/core";
import PortiereIcon from "../../assets/icons/portiere.svg";
import DifesaIcon from "../../assets/icons/difensore.svg";
import CentrocampoIcon from "../../assets/icons/centrocampista.svg";
import TrequartiIcon from '../../assets/icons/trequarti.svg'
import AttaccoIcon from "../../assets/icons/attaccante.svg";
import { AddCircleRounded } from '@material-ui/icons';
import Modal from '../../components/Modal'
import AddMandateContainer from '../../containers/club_partnership/AddMandate.container'


const ClubPartnershipTeamsSectionComponent = ({
  classes,
  title = "",
  children,
  countryId,
  refreshComponent,
  mutate
}) => {

  const [openCreate, setOpenCreate] = useState(false)

  return (
    <Grid container borderRadius={8} className={classes.root}>
      <Grid item xs style={{ display: "flex", alignItems: "center" }}>
        {/* <img
          src={
            title === "Portiere"
              ? PortiereIcon
              : title === "Difesa"
                ? DifesaIcon
                : title === "Centrocampo"
                  ? CentrocampoIcon
                  : title === "Attacco"
                    ? AttaccoIcon
                    : title === 'Trequarti'
                      ? TrequartiIcon
                      : null
          }
          height="40px"
        /> */}
        <Typography className={classes.title}>{title.toUpperCase()}</Typography>
      </Grid>
      <Grid item>
          <Button color="primary" component="span" onClick={() => setOpenCreate(true)}>
              <AddCircleRounded className={classes.icon} />
          </Button>
      </Grid>
      <Modal
          open={openCreate}
          onClose={() => setOpenCreate(false)}
          title='Add Mandate'>
        <AddMandateContainer countryId={countryId} onClose={() => setOpenCreate(false)} mutate={mutate} />
      </Modal>
      {children}
    </Grid>
  );
};

export default withStyles(style)(ClubPartnershipTeamsSectionComponent);
