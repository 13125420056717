import React, { useCallback, useState, Fragment } from 'react'
import { withStyles } from "@material-ui/core/styles"
import { style } from './PlayerOverlayScouting.component.style';
import StarIcon from '@material-ui/icons/Star';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import ImageContainer from '../../containers/image/Image.container';
import Loading from '../Loading'
import StarRatingComponent from 'react-star-rating-component';
import ScoutingPlayerAddNoteContainer from '../../containers/scouting/ScoutingPlayerAddNote.container';
import FilesContainer from '../../containers/club_partnership/Files.container';
import PlayerAddFileContainer from '../../containers/player/PlayerAddFile.container';
import FilterAgencyByName from '../../containers/filters/FilterAgencyByName.container';
import Requests from '../../core/requests'
import { useSelector } from 'react-redux'
import CallOutcomeComponent from '../../components/club_partnership/CallOutcome.component'
import { useHistory } from 'react-router'

const PlayerOverlayScountingComponent = ({
    classes,
    open,
    closeOverlay,
    data,
    loading,
    onSelect = () => { },
    elite = false,
    onSelectElite = () => { },
    onEvaluationChange = () => { },
    selectArea = false,
    identifier,
    playerId,
    mutate
}) => {

    const history = useHistory()
    const { token } = useSelector(state => state.user)

    const [rating, setRating] = useState(0)
    const [agencyId, setAgencyId] = useState(0)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(null)
    

    const openReportLink = useCallback((url) => {
        window.open(url, '_blank');
    }, [])

    const changeRating = useCallback((nextValue) => {
        setRating(nextValue)
        onEvaluationChange(nextValue)
    }, [])

    const proposePlayer = useCallback((agencyId, playerId, teamId) => {
        Requests.partner_proposal.addProposal(({
            token: token.access,
            agency: agencyId,
            team: teamId,
            players: [playerId],
        })).then(() => {
            setSuccess("Player proposed to partner");
        }).catch((error) => {
            setError(error);
        })
    }, [])

    const proposePlayerToProposal = useCallback((playerId) => {
        history.push(`/proposals/${playerId}`);
    }, [])

    return (
        <Dialog onClose={closeOverlay} open={open} maxWidth='sm' fullWidth={true} className={classes.root}>
            <MuiDialogTitle disableTypography >
                <Typography className={classes.title}>Quick View</Typography>
                <IconButton className={classes.close} onClick={closeOverlay}>
                    <CloseIcon />
                </IconButton>
            </MuiDialogTitle>
            {loading ? <Loading /> :
                <Fragment>
                    <MuiDialogContent>
                        <Grid container>
                            <Grid container item xs={12}>
                                <Grid item xs={2} className={classes.container}>
                                    <ImageContainer src={data?.image_url} className={classes.icon} />
                                </Grid>
                                <Grid item xs={5} className={classes.container}>
                                    <Typography className={classes.name}>{data?.full_name}</Typography>
                                </Grid>
                                <Grid item xs={5} className={classes.container}>
                                    {data?.is_selected ?
                                        <Box display="flex" flexDirection="row" alignItems='center'>
                                            <StarIcon className={classes.star} />
                                            <Typography className={classes.monitored}>Monitored</Typography>
                                        </Box> : null}
                                </Grid>
                            </Grid>
                            <Grid className={classes.divider} item xs={12} />

                            <Grid container>
                                <Grid item xs>
                                    <Box className={classes.valueContainer}>
                                        <Typography className={classes.label}>Age:</Typography>
                                        <Typography className={classes.value}>{data?.age}</Typography>
                                    </Box>
                                    <Box className={classes.valueContainer}>
                                        <Typography className={classes.label}>Native Country:</Typography>
                                        <Typography className={classes.value}>{data?.birth_country?.name}</Typography>
                                    </Box>

                                    <Box className={classes.valueContainer}>
                                        <Typography className={classes.label}>Passport:</Typography>
                                        <Typography className={classes.value}>{data?.passports?.map((item, index) => index === data?.passports?.length - 1 ? `${item?.name}` : `${item?.name}-`)}</Typography>
                                    </Box>

                                    <Box className={classes.valueContainer}>
                                        <Typography className={classes.label}>Height:</Typography>
                                        <Typography className={classes.value}>{data?.height}</Typography>
                                    </Box>

                                    <Box className={classes.valueContainer}>
                                        <Typography className={classes.label}>Current club:</Typography>
                                        <Typography className={classes.value}>{data?.team?.name}</Typography>
                                    </Box>
                                    <Box className={classes.valueContainer}>
                                        <Typography className={classes.label}>Contract expiry:</Typography>
                                        <Typography className={classes.value}>{data?.contract_expires}</Typography>
                                    </Box>
                                    <Box className={classes.valueContainer}>
                                        <Typography className={classes.label}>Evaluation:</Typography>
                                        <StarRatingComponent className={classes.value} name={data?.id} starCount={5} value={data?.marks?.mean ? data?.marks?.mean : 0} />
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid className={classes.divider} item xs={12} />

                            <Grid container>
                                <Grid item>
                                    <Box className={classes.valueContainer}>
                                        <Typography className={classes.label}>Your Evaluation:</Typography>
                                        <StarRatingComponent className={classes.value} name={data?.id} starCount={5} value={rating} onStarClick={changeRating} />
                                    </Box>
                                    <Box className={classes.valueContainer}>
                                        <Typography className={classes.label}>Reports:</Typography>
                                        {data?.links?.map((link) => <Typography key={link.id} onClick={() => openReportLink(link.url)} className={classes.link}>Report {link.name}</Typography>)}
                                    </Box>
                                </Grid>
                            </Grid>

                            {identifier === "elitePage" || identifier === "elitePortfolioPage" || identifier === "selectedList" ?
                                <>
                                    <Grid className={classes.divider} item xs={12} />

                                    <Grid container item xs={12} alignItems='center'>
                                        <PlayerAddFileContainer playerId={playerId} mutate={mutate} />
                                    </Grid>

                                    <FilesContainer identifier={identifier} fullWidth={true} mutate={mutate} files={data?.files || null} />
                                </> : null
                            }

                            {identifier === "elitePortfolioPage" || identifier === "selectedList"  ?
                                <>
                                    <Grid className={classes.divider} item xs={12} />
                                    <CallOutcomeComponent success = {success} error = {error} />
                                    <FilterAgencyByName setAgencyId={setAgencyId} />
                                    <Box>
                                        <Button className={classes.marginLeftNone} onClick={()=>{proposePlayer(agencyId, playerId, data?.team?.id)}}>Propose</Button>
                                    </Box>

                                    <Grid className={classes.divider} item xs={12} />

                                    <Box>
                                        <Typography className={classes.title}>
                                            Propose player to proposal
                                        </Typography>
                                        <Button className={classes.marginLeftNone} onClick={()=>{proposePlayerToProposal(playerId)}}>Propose</Button>
                                    </Box>
                                </> : null
                            }

                            <Grid className={classes.divider} item xs={12} />

                            <Grid item xs={12}>
                                <ScoutingPlayerAddNoteContainer id={data?.id} note={data?.note} />
                            </Grid>

                            <Grid className={classes.divider} item xs={12} />

                        </Grid>
                    </MuiDialogContent>
                    {selectArea ? null :
                        <MuiDialogActions>
                            <Box display="flex" flexDirection="row" justifyContent='center' flexGrow={1}>
                                <Button onClick={() => onSelect(!data?.is_selected)}>{data?.is_selected ? 'Deselect' : 'Select'}</Button>
                                {elite && <Button onClick={() => onSelectElite(!data?.is_elite)}>{data?.is_elite ? 'Deselect Elite' : 'Select Elite'}</Button>}
                            </Box>
                        </MuiDialogActions>
                    }
                </Fragment>
            }

        </Dialog>
    )

}

export default withStyles(style)(PlayerOverlayScountingComponent)