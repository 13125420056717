import React from 'react'
import MainLayout from '../../layout/main/Main.layout'
import ClubMandatesContainer from '../../containers/club_partnership/ClubMandates.container'

const ClubMandatesPage = () => {

    return (
        <MainLayout title='Club Mandates' logo={true}>
            <ClubMandatesContainer />
        </MainLayout>
    )
}

export default ClubMandatesPage