import React, { Fragment } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const Error = ({ message, open, onDismiss = () => { }, onResetError }) => {

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        onDismiss(false);
    };

    return (
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open}
            autoHideDuration={6000}
            onClose={() => onResetError()}
            message={message}
            action={
                <Fragment>
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={() => onResetError()}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </Fragment>
            }
        ></Snackbar>
    );
};

export default Error;
