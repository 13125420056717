import React, { Fragment, useState , useCallback} from 'react'
import PlayersSection from '../../components/players/PlayersSection.component'
import { LinearProgress, Tabs, Tab, Grid, makeStyles, Button } from '@material-ui/core'
import MonitoringPlayersContainer from './MonitoringPlayers.container'
import useSWRRequest from '../../core/useSWRRequest'
import Requests from '../../core/requests'
import { useSelector } from 'react-redux'
import { AddCircleRounded } from '@material-ui/icons';
import Modal from '../../components/Modal'
import AddPlayerContainer from './AddPlayer.container'

const useStyles = makeStyles({
    title: {
        fontSize: '30px',
        fontFamily: 'Anton-Regular',
        color: '#761200'
    },
    icon: {
        width: 'auto',
        height: '2.5rem',
        '&:hover': {
            background: 'transparent'
        }
    }
})

const MonitoringContainer = ({
    openDetail = () => { },
    identifier
}) => {

    const classes = useStyles();
    const { token } = useSelector(state => state.user)
    const { data, loading } = useSWRRequest(Requests.roles.positions, { token: token.access })
    const [tab, setTab] = useState(0);
    const { data: players, loading: loadingPlayers, mutate } = useSWRRequest(identifier === "partnerPlayer" ? Requests.agency_partnership.agenciesPlayersList : Requests.players.assisted, { token: token.access, team_type: tab === 0 ? 'First' : 'Youth' })
    const [openCreate, setOpenCreate] = useState(false);

    const handleChange = useCallback((event, newValue) => {
        setTab(newValue);
    }, []);

    return (
        <Fragment>
            {loading ? <LinearProgress /> : null}
            {identifier !== "partnerPlayer" &&
                <>
                    <Grid item xs>
                        <Tabs value={tab} onChange={handleChange}>
                            <Tab label="First Team" />
                            <Tab label="Youth Team" />
                        </Tabs>
                    </Grid>
                    <Grid item>
                        <Button color="primary" component="span" onClick={() => setOpenCreate(true)}>
                            <AddCircleRounded className={classes.icon} />
                        </Button>
                    </Grid>
                </>
            }
            <Modal
                open={openCreate}
                onClose={() => setOpenCreate(false)}
                title='Add Player'>
                <AddPlayerContainer selectedTab={tab} onClose={() => setOpenCreate(false)} mutate={mutate} />
            </Modal>
            {data.map((position) =>
                <PlayersSection key={position.id} title={position.name}>
                    <MonitoringPlayersContainer data = {players} loading= {loadingPlayers} openDetail={openDetail} position={position} identifier={identifier}/>
                </PlayersSection>
            )}
        </Fragment>
    )
}

export default MonitoringContainer