import React, { useEffect, Fragment, useMemo, useState } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import ScoutingPlayersListAlertComponent from "../../components/scouting/ScoutingPlayersListAlert.component";
import _sortBy from "lodash/sortBy";
import { useSelector } from "react-redux";
import useSWRRequest from "../../core/useSWRRequest";
import Requests from "../../core/requests";
import _ from "lodash";
import qs from "qs";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
	loading: {
		margin: "1rem 0rem 1.8rem 0rem",
	},
});

const ScoutingPlayersAlertContainer = ({ open, filter, openOverlay }) => {
	const classes = useStyles();
	const { token } = useSelector((state) => state.user);
	const [pageIndex, setPageIndex] = useState(1);
	const query = useMemo(
		() =>
			qs.stringify(
				_.pickBy(filter, (value) => value !== null),
				{ encode: false }
			),
		[filter]
	);

	const {
		data: players,
		loading,
		mutate,
	} = useSWRRequest(Requests.players.interesting, {
		token: token.access,
		query: `?${query}`,
		pageIndex: `${pageIndex}`,
	});

	const {
		data: rawData,
		loading: loadingData,
		mutate: mutateData,
	} = useSWRRequest(Requests.players.interestingRaw, {
		token: token.access,
		query: `?${query}`,
		pageIndex: `${pageIndex}`,
	});

	useEffect(() => {
		if (!open) {
			mutate();
			mutateData();
		}
	}, [open, pageIndex]);

	return (
		<Fragment>
			{loading && filter ? (
				<LinearProgress className={classes.loading} />
			) : null}
			<ScoutingPlayersListAlertComponent
				onClickDetail={openOverlay}
				rawData={rawData}
				players={players}
				pageIndex={pageIndex}
				setPageIndex={setPageIndex}
			/>
		</Fragment>
	);
};

export default ScoutingPlayersAlertContainer;
