import * as Yup from 'yup';

export const PlayerStatusInitialValues = (data) => {
    let expiration_date, expiration_time = null;
    if(data?.expiration_date){
        let splittedExpirationDate = data.expiration_date.split(" ");
        expiration_date = splittedExpirationDate ? splittedExpirationDate[0] : null;
        expiration_time = splittedExpirationDate ? splittedExpirationDate[1] : null;
    }
    return {
        player_status: data && data.player_status ?  data.player_status : 'OnSale',
        work_status: data && data.work_status ?  data.work_status : 'NotPriority',
        usersInvolved: data && data.operators ? data.operators.map(operator => operator.id) : [],

        note: data && data.note? data.note : '',
        ...(expiration_date && { expiration_date }),
        ...(expiration_date && expiration_time && { expiration_time }),
    }
} 

export const PlayerStatusValidationShema = Yup.object().shape({
    player_status: Yup.string(),
    work_status: Yup.string(),
    note: Yup.string(),    
})