import React from 'react'
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core'
import { Form, Formik } from 'formik';
import ActivityAddFileComponent from '../../components/activity/ActivityAddFile.component';
import { color } from '../../config/colors/colors'

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: theme.spacing(3),
        color: color.title,
        fontWeight: 'bold',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    }
}));

const ActivityAddFileContainer = ({
    activityId,
    onSubmit
}) => {

    const classes = useStyles();

    return (
        <>
            <Grid item xs>
                <Typography className={classes.title}>
                    Add Files
                </Typography>
            </Grid>
            <Grid item>
                <Formik
                    initialValues={{ file: null , calendar_activity: activityId }}
                    onSubmit={onSubmit}
                    validateOnBlur={true}
                    validateOnChange={false}>
                    {(props) =>
                        <Form>
                            <ActivityAddFileComponent {...props} />
                        </Form>
                    }
                </Formik>
            </Grid>
        </>
    )
}

export default ActivityAddFileContainer