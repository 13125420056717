import React, { useState, Fragment } from 'react'
import Error from '../../components/error/Error.component'
import Loading from '../../components/progress/Progress.component'
import { Formik } from 'formik';
import Requests from '../../core/requests'
import { useSelector } from 'react-redux'
import { ScountingPlayerNoteInitialValues, ScountingPlayerNoteValidationSchema } from '../../conf/ScoutingPlayerNote.conf';
import ScountingPlayerAddNote from '../../components/scouting/ScoutingPlayerAddNote.component';
import useSWRRequest from '../../core/useSWRRequest'

const ScoutingPlayerAddNoteContainer = ({
    id,
    note
}) => {

    const { token } = useSelector(state => state.user)
    const { mutate } = useSWRRequest(Requests.players.interestingPlayer, { token: token.access, id })

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const onSubmit = (values, actions) => {
        actions.setSubmitting(true)
        setLoading(true)
        Requests.players.updatePlayer({
            id,
            token: token.access
        }, {
            note: values.note,
        }).then(() => {
            setLoading(false)
            actions.setSubmitting(false)
            mutate()
        }).catch((error) => {
            setLoading(false)
            actions.setSubmitting(false)
            setError(error)
        })
    }

    return (
        <Fragment>
            {error ? <Error message={error} /> : null}
            {loading ? <Loading /> : null}
            <Formik
                initialValues={ScountingPlayerNoteInitialValues(note)}
                validationSchema={ScountingPlayerNoteValidationSchema}
                onSubmit={onSubmit} >
                {(props) => <ScountingPlayerAddNote {...props} />}
            </Formik>
        </Fragment>
    )
}

export default ScoutingPlayerAddNoteContainer