import React, { Fragment, useMemo } from 'react'
import FilterAutocompleteComponent from '../../components/filter/FilterAutocomplete.component'
import { useSelector } from 'react-redux'
import _ from 'lodash'

const ScountingFilterLeagueContainer = (props) => {

    const { user } = useSelector(state => state.user)

    const competitions = useMemo(() => {

        const items = user.competitions        
        if(props.values.league_countries) {
            return items.filter((competition) => competition.country.id === props.values.league_countries)
        }

        return items

    }, [user, props.values.league_countries])

    return (
        <Fragment>
            <FilterAutocompleteComponent
                options={competitions}
                setFilter={(value) => props.setFieldValue('leagues', value)} />            
        </Fragment>
    )
}

export default ScountingFilterLeagueContainer