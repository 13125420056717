import React from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));


const Modal = ({
    title,
    onClose,
    open,
    children
}) => {

    const classes = useStyles();

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth>
            <DialogTitle className={classes.root} disableTypography>
                <Typography variant='h4'>
                    {title}
                </Typography>
                <IconButton onClick={onClose} className={classes.closeButton}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                {children}
            </DialogContent>
        </Dialog>
    );
}

export default Modal