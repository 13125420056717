import React, { useState, Fragment } from 'react'
import Loading from '../../components/progress/Progress.component'
import { Formik } from 'formik';
import { ProposalAddInitialValues, ProposalAddValidationSchema } from '../../conf/Proposal.conf'
import { useParams } from 'react-router';
import AddProposal from '../../components/proposal/AddProposal.component';
import useSWRRequest from '../../core/useSWRRequest'
import Requests from '../../core/requests'
import { useSelector } from 'react-redux'
import CallOutcomeComponent from '../../components/club_partnership/CallOutcome.component'

const ProposalAddContainer = ({
    onClose = () => { }
}) => {

    const { token } = useSelector(state => state.user)
    const { id } = useParams();
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const { data: request, loading: loadingRequest } = useSWRRequest(Requests.notes.note, { token: token.access, id })

    const onSubmit = (values, actions) => {
        actions.setSubmitting(true)
        setLoading(true)
        Requests.proposal.add(({
            token: token.access,
            agenda_note: values.agenda_note,
            players: values.players.map((player) => player),          
        })).then(() => {
            setLoading(false)
            actions.setSubmitting(false)
            onClose()
        }).catch((error) => {
            setLoading(false)
            actions.setSubmitting(false)
            setError(error?.response?.data?.error)
        })        
    }

    return (
        <Fragment>
            <CallOutcomeComponent error = {error} />
            {loading || loadingRequest ? <Loading /> : null}
            {request &&
                <Formik
                    initialValues={ProposalAddInitialValues(request)}
                    validationSchema={ProposalAddValidationSchema}
                    enableReinitialize={true}
                    onSubmit={onSubmit} >
                    {(props) => <AddProposal {...props} roles={request?.role?.id} positions={request?.position?.id} />}
                </Formik>}
        </Fragment>
    )
}

export default ProposalAddContainer