import React from 'react'
import MainLayout from '../../layout/main/Main.layout'
import CalendarContainer from '../../containers/calendar/Calendar.container'
import ContentLayout from '../../layout/content/Content.layout'

const CalendarPage = () => {

    return (
        <MainLayout title='Calendar' logo={true}>
            <CalendarContainer />
        </MainLayout>
    )
}

export default CalendarPage