import moment from 'moment'
import * as Yup from 'yup';

export const CalendarAddInitialValues = (data) => ({
    type: data?.type || "",
    usersInvolved: data?.operators ? data?.operators.map(item => item.id) : [],
    activityDetail : data?.detail || "",
    note: data?.note || "",
    date: data?.date || moment().format("YYYY-MM-DD"),
    startTime: data?.start || null,
    endTime: data?.end || null,
})

export const CalendarAddValidationSchema = Yup.object().shape({
    type: Yup.string().required('Type is required'),
    activityDetail : Yup.string().required('Activity Detail is required'),
    date: Yup.string().required('Date is required'),
    endTime: Yup.string().nullable().when('startTime', {
        is: (startTime) => startTime && startTime.length > 0,
        then: Yup.string()
        .test(
            'not empty',
            'End time cant be empty',
            function(value) {
                return !!value;
            }
        )
        .test(
            "start_time_test",
            "The end time must be later than start time",
            function(value) {
                const { startTime } = this.parent;
                return moment(startTime, 'HH:mm').isSameOrBefore(moment(value, 'HH:mm'));
            }
        ),            
    })
})