import React, { useCallback, useEffect, useState } from "react";
import { Box, Paper } from "@material-ui/core";
import MainLayout from "../../layout/main/Main.layout";
import ScoutingFilterContainer from "../../containers/scouting/ScoutingFilter.container";
import ContentLayout from "../../layout/content/Content.layout";
import ScoutingPlayerContainer from "../../containers/player/ScountingPlayer.container";
import ScoutingPlayersAlertContainer from "../../containers/scouting/ScoutingPlayersAlert.container";

const ScoutingAlertPage = () => {
	const [overlay, setOverlay] = useState(false);
	const [player, setPlayer] = useState(null);
	const [filter, setFilter] = useState(null);
	const [showResults, setShowResults] = useState(false);

	const openOverlay = useCallback((player) => {
		setOverlay(true);
		setPlayer(player);
	}, []);

	const closeOverlay = useCallback(() => {
		setOverlay(false);
		setPlayer(null);
	}, []);

	const onSubmit = useCallback((values) => {
		setFilter(values);
	}, []);

	useEffect(() => {
		if (filter) {
			setShowResults(true);
		}
	}, [filter]);

	const onChange = useCallback(() => {
		setShowResults(false);
	}, []);

	return (
		<MainLayout title="Alert">
			<ContentLayout>
				<Box mt={2} width={1}>
					<Paper>
						<Box padding={4}>
							<ScoutingFilterContainer
								onSubmit={onSubmit}
								onChange={onChange}
							/>
						</Box>
					</Paper>
				</Box>
				<Box mt={2} width={1}>
					{showResults && (
						<ScoutingPlayersAlertContainer
							filter={filter}
							openOverlay={(player) => openOverlay(player)}
							open={overlay}
						/>
					)}
					{overlay && (
						<ScoutingPlayerContainer
							id={player.id}
							closeOverlay={closeOverlay}
							open={overlay}
						/>
					)}
				</Box>
			</ContentLayout>
		</MainLayout>
	);
};

export default ScoutingAlertPage;
