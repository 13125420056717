import React from 'react';
import './App.css';
import { AuthProvider } from './contexts/auth/Auth.context';
import Routing from './routing/Routing';

import { Provider } from 'react-redux'
import store from "./store/app.store";
import Endpoints from './core/endpoints'
Endpoints.baseURL = process.env.REACT_APP_BASE_URL

function App() {

	return (
		<Provider store={store}>
			<Routing />
		</Provider>
	);
}

export default App;
