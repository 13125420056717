import React from 'react'
import MainLayout from '../../layout/main/Main.layout'
import ContentLayout from '../../layout/content/Content.layout'
import AgencyPartnershipContainer from '../../containers/agency_partnership/AgencyPartnership.container'

const BaPortfolioPage = () => {

    return (
        <MainLayout title='B.A. Portfolio' logo={true}>
            <ContentLayout>
                <AgencyPartnershipContainer caller="baPortfolio" />
            </ContentLayout>
        </MainLayout>
    )
}

export default BaPortfolioPage