import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import InboxPage from '../pages/inbox/Inbox.page';
import CalendarPage from '../pages/calendar/Calendar.page';
import ActivityPage from '../pages/activity/Activity.page';
import ElitePage from '../pages/elite/Elite.page';
import ElitePortfolioPage from '../pages/elite/ElitePortfolio.page';
import MonitoringPage from '../pages/monitoring/Monitoring.page';
import ScoutingPage from '../pages/scouting/Scouting.page';
import MonitoringPlayerPage from '../pages/monitoring/MonitoringPlayer.page';
import ScoutingAlertPage from '../pages/scouting/ScoutingAlert.page';
import ScoutingSelectedPage from '../pages/scouting/ScoutingSelected.page';
import LoginPage from '../pages/login/Login.page';
import PrivateRoute from './PrivateRoute';
import RequestsPage from '../pages/requests/Requests.page';
import RequestPage from '../pages/requests/Request.page';
import PublicRoute from './PublicRoute';
import ClubPartnershipPage from '../pages/club_partnership/ClubPartnership.page';
import AgencyPartnershipPage from '../pages/agency_partnership/AgencyPartnership.page';
import ClubMandatesPage from '../pages/club_partnership/ClubMandates.page';
import ClubMandatePage from '../pages/club_partnership/ClubMandate.page';
import AgencyMandatesPage from '../pages/agency_partnership/AgencyMandates.page';
import AgencyMandatePage from '../pages/agency_partnership/AgencyMandate.page';
import ClubProposalPage from '../pages/club_proposal/ClubProposal.page';
import TeamsByCompetitionPage from '../pages/club_proposal/TeamsByCompetition.page';
import PartnerProposalPage from '../pages/partner_proposal/PartnerProposal.page';
import AgencyProposalsPage from '../pages/partner_proposal/AgencyProposals.page';
import ProposalPage from '../pages/partner_proposal/Proposal.page';
import BaPortfolioPage from '../pages/ba_portfolio/BaPortfolio.page';
import BaPortfolioMandatesPage from '../pages/ba_portfolio/BaPortfolioMandates.page';
import BaPortfolioMandatePage from '../pages/ba_portfolio/BaPortfolioMandate.page';
import EliteClubProposalPage from '../pages/elite/EliteClubProposal.page';
import EliteTeamsByCompetitionPage from '../pages/elite/EliteTeamsByCompetition.page';
import AllToDoValidProposalsPage from '../pages/proposals/AllToDoValidProposals.page';
import PartnerPlayerPage from '../pages/partner_players/PartnerPlayer.page'

const Routing = () => {

    return (
        <Router>
            <Switch>
                <PublicRoute exact path="/login" component={LoginPage} />
                <PrivateRoute exact path="/inbox" component={InboxPage} />
                <PrivateRoute exact path="/calendar" component={CalendarPage} />
                <PrivateRoute exact path="/activity/:id" component={ActivityPage} />
                <PrivateRoute exact path="/mandates/desk" component={MonitoringPage} />
                <PrivateRoute exact path="/mandates/partner_players" component={PartnerPlayerPage} />
                <PrivateRoute exact path="/mandates/club_partnership" component={ClubPartnershipPage} />
                <PrivateRoute exact path="/mandates/club_partnership/:clubId/:countryId" component={ClubMandatesPage} />
                <PrivateRoute exact path="/mandates/mandate/:id" component={ClubMandatePage} />
                <PrivateRoute exact path="/mandates/agency_partnership" component={AgencyPartnershipPage} />
                <PrivateRoute exact path="/mandates/agency_partnership/:agencyId/:countryId" component={AgencyMandatesPage} />
                <PrivateRoute exact path="/agency_partnership/mandate/:id" component={AgencyMandatePage} />
                <PrivateRoute exact path="/elite/scoutinglist" component={ElitePage} />
                <PrivateRoute exact path="/elite/eliteportfolio" component={ElitePortfolioPage} />
                <PrivateRoute exact path="/elite/eliteclubproposal" component={EliteClubProposalPage} />
                <PrivateRoute exact path="/elite/eliteclubproposal/teams_competition/:competitionId/:countryId" component={EliteTeamsByCompetitionPage} />
                <PrivateRoute exact path="/elite/club_requests/:id" component={RequestPage} />
                <PrivateRoute exact path="/player_detail/:id/:playerId?" component={MonitoringPlayerPage} />
                <PrivateRoute exact path="/scouting" component={ScoutingPage} />
                <PrivateRoute exact path="/scouting/alert" component={ScoutingAlertPage} />
                <PrivateRoute exact path="/scouting/selected" component={ScoutingSelectedPage} />
                <PrivateRoute exact path="/club_proposal/:teamId/:countryId/:competitionId" component={RequestsPage} />
                <PrivateRoute exact path="/eliteclubproposal/:teamId/:countryId/:competitionId" component={RequestsPage} />
                <PrivateRoute exact path="/proposals/:playerId" component={AllToDoValidProposalsPage} />
                <PrivateRoute exact path="/player_requests/club_proposal/teams_competition/:competitionId/:countryId" component={TeamsByCompetitionPage} />
                <PrivateRoute exact path="/club_requests/:id" component={RequestPage} />
                <PrivateRoute exact path="/player_requests/club_proposal" component={ClubProposalPage} />
                <PrivateRoute exact path="/player_requests/partner_proposal" component={PartnerProposalPage} />
                <PrivateRoute exact path="/player_requests/partner_proposal/:agencyId/:countryId" component={AgencyProposalsPage} />
                <PrivateRoute exact path="/partner_proposal/proposal/:id" component={ProposalPage} />
                <PrivateRoute exact path="/player_requests/ba_portfolio" component={BaPortfolioPage} />
                <PrivateRoute exact path="/player_requests/ba_portfolio/:agencyId/:countryId" component={BaPortfolioMandatesPage} />
                <PrivateRoute exact path="/ba_portfolio/mandate/:id" component={BaPortfolioMandatePage} />
                <Route render={() => <Redirect to="/login" />} />
            </Switch>
        </Router>
    );
}

export default Routing;