import React, { useState, Fragment } from 'react'
import Loading from '../../components/progress/Progress.component'
import { CalendarAddInitialValues, CalendarAddValidationSchema } from '../../conf/Calendar.conf'
import { useSelector } from 'react-redux'
import Requests from '../../core/requests'
import { Paper, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import { color } from '../../config/colors/colors'
import useSWRRequest from '../../core/useSWRRequest'
import CalendarActivityComponent from '../../components/calendar/CalendarActivity.component'

const useStyles = makeStyles(() => ({
    paperCommentOk: {
        padding : "16px",
        backgroundColor: "#d4edda",
        borderColor: "#c3e6cb"
    },
    labelOk: {
        textAlign: "center",
        color: "#155724",
    },
    paperCommentKo: {
        padding : "16px",
        backgroundColor: "#f8d7da",
        borderColor: "#f5c6cb"
    },
    labelKo: {
        textAlign: "center",
        color: color.title,
    }
}));

const UpdateActivityContainer = ({
    activityId
}) => {

    const { token } = useSelector(state => state.user)
    const { data } = useSWRRequest(Requests.activity.activities, { token: token.access, id: activityId })

    const classes = useStyles();
    const [loadingBar, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(null)

    const onSubmit = (values, actions) => {
        if(activityId){
            setLoading(true)
            actions.setSubmitting(true)
            Requests.activity.updateActivity({ token: token.access, id: activityId },
            {
                type: values.type,
                operators: values.usersInvolved,
                detail: values.activityDetail,
                note: values.note,
                start: values.startTime || null,
                end: values.endTime || null
            }).then(() => {
                setLoading(false)
                setError(null)
                setSuccess("Activity update completed successfully")
                actions.setSubmitting(false)
            }).catch((errorBE) => {
                setLoading(false)
                setError(errorBE.response.data.error)
                actions.setSubmitting(false)
            })
        }        
    }

    return (
        <Fragment>
            {error && error.message ? 
                <Grid item xs={12}>
                    <Paper className={classes.paperCommentKo}>
                        <Typography className={classes.labelKo}>{error.message}</Typography>
                    </Paper>
                </Grid>
            : null}
            {success ? 
                <Grid item xs={12}>
                    <Paper className={classes.paperCommentOk}>
                        <Typography className={classes.labelOk}>{success}</Typography>
                    </Paper>
                </Grid>
            : null}
            {loadingBar ? <Loading /> : null}
            <CalendarActivityComponent initialValues={CalendarAddInitialValues(data)} validationSchema ={CalendarAddValidationSchema} onSubmit={onSubmit} />
        </Fragment>
    )
}

export default UpdateActivityContainer