import React from 'react'
import MainLayout from '../../layout/main/Main.layout'
import AgencyMandatesContainer from '../../containers/agency_partnership/AgencyMandates.container'

const BaPortfolioMandatesPage = () => {

    return (
        <MainLayout title='B.A. Portfolio' logo={true}>
            <AgencyMandatesContainer caller = "baPortfolio" />
        </MainLayout>
    )
}

export default BaPortfolioMandatesPage