import React, { useState, useEffect, useCallback, useMemo } from 'react'
import useSWRRequest from '../../core/useSWRRequest'
import Requests from '../../core/requests'
import { useSelector } from 'react-redux'
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment'
import ContentLayout from '../../layout/content/Content.layout'
import Modal from '../../components/Modal'
import CalendarAddActivityContainer from '../../containers/calendar/CalendarAddActivity.container.js'
import { useHistory } from 'react-router'
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  calendarStyle: {
    height: "100vh",
    width: "100%"
  }
}));


const CalendarContainer = () => {

  const { token } = useSelector(state => state.user)
  const classes = useStyles();
  const { data, mutate } = useSWRRequest(Requests.calendar.activities, { token: token.access })
  const history = useHistory()
  const [openCreate, setOpenCreate] = useState(false)
  const [eventDate, setEventDate] = useState(null)
  const localizer = momentLocalizer(moment)

  useEffect(() => {
    if (!openCreate) {
      mutate()
    }
  }, [openCreate])


  const onSelectSlot = useCallback(({ start, end }) => {
    setEventDate({
      start: moment(new Date(start)).format("YYYY-MM-DD"),
      end: moment(new Date(end)).subtract(1, "days").format("YYYY-MM-DD")
    });
    setOpenCreate(true);
  }, [setEventDate, setOpenCreate])


  const onSelectEvent = useCallback(({ id }) => {
    history.push(`/activity/${id}`);
  }, [])

  let events = useMemo(() => {
    return data.map(event => {
      let activityDateSplitted = event.date.split("-");
      let activityStartTimeSplitted = event.start ? event.start.split(":") : null;
      let activityEndTimeSplitted = event.end ? event.end.split(":") : null;
      let elaboratedEvent = {
        title : event.start ? event.detail + " | " + event.start : event.detail,
        startDate : activityStartTimeSplitted ? new Date(activityDateSplitted[0], activityDateSplitted[1] - 1, activityDateSplitted[2], activityStartTimeSplitted[0], activityStartTimeSplitted[1]) : new Date(activityDateSplitted[0], activityDateSplitted[1] - 1, activityDateSplitted[2]),
        endDate : activityEndTimeSplitted ? new Date(activityDateSplitted[0], activityDateSplitted[1] - 1, activityDateSplitted[2], activityEndTimeSplitted[0], activityEndTimeSplitted[1]) : new Date(activityDateSplitted[0], activityDateSplitted[1] - 1, activityDateSplitted[2]),
      }
      return {
        id: event.id,
        title: elaboratedEvent.title,
        allDay: event.all_day,
        start: elaboratedEvent.startDate,
        end: elaboratedEvent.endDate,
        hasAttachments: event.has_attachments
      }
    })
  }, [data]);

  return (
    <ContentLayout>
      <Modal
        open={openCreate}
        onClose={() => setOpenCreate(false)}
        title='New Activity'>
        <CalendarAddActivityContainer eventDate={eventDate} onClose={() => setOpenCreate(false)} />
      </Modal>
      <Calendar
        selectable
        onSelectSlot={onSelectSlot}
        onSelectEvent={onSelectEvent}
        localizer={localizer}
        className={classes.calendarStyle}
        events={events}
        defaultView={Views.MONTH}
        views={['month', 'agenda', 'day']}
        step={60}
        defaultDate={new Date()}
        eventPropGetter={
          (event) => {
            return {
              style: event.hasAttachments && { backgroundColor : "green"}
            };
          }
        }
      />
    </ContentLayout>
  )
}

export default CalendarContainer