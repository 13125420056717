import _ from 'lodash';

export const NotesEndpoints = {
    basePath: 'api/v1/club-requests',
    endpoints: {
        areaAgendaNotes: {
            path: '/area-agenda-notes/?competition={{competitionId}}{{otherParams}}',
            method: 'GET',
            get: 'data',
            fallback: [],
            auth: true,
            swr: {
                revalidateOnFocus: false
            }
        },
        agendaNotesByTeam: {
            path: '/agenda-notes/?teams={{teamId}}{{otherParams}}',
            method: 'GET',
            get: 'data',
            fallback: [],
            auth: true,
            swr: {
                revalidateOnFocus: false,
                revalidateOnMount: false,
                revalidateOnReconnect: false,
                dedupingInterval: 1000,
            }
        },        
        notes: {
            path: '/agenda-notes/',
            method: 'GET',
            get: 'data',
            fallback: [],
            auth: true,
            swr: {
                revalidateOnFocus: false
            }
        },        
        note: {
            path: '/agenda-notes/{{id}}/',
            method: 'GET',
            get: 'data',
            fallback: null,
            auth: true,
            swr: {
                revalidateOnFocus: false
            }
        },
        allToDoValidNotes: {
            path: '/agenda-notes/?is_todo=True&has_deadline_valid=True&player={{playerId}}',
            method: 'GET',
            get: 'data',
            fallback: null,
            auth: true,
            swr: {
                revalidateOnFocus: false
            }
        },        
        add: {
            path: '/agenda-notes/',
            method: 'POST',
            fallback: null,
            auth: true,
        },
        update: {
            path: '/agenda-notes/{{id}}/',
            method: 'PATCH',
            fallback: null,
            auth: true,
        },
        delete: {
            path: '/agenda-notes/{{id}}/',
            method: 'DELETE',
            fallback: null,
            auth: true,
        },
        updateProposal: {
            path: '/proposals/{{id}}/',
            method: 'PATCH',
            fallback: null,
            auth: true,
        },
        getCommentsByProposal: {
            path: '/agenda-notes/{{mandateId}}/comments/',
            method: 'GET',
            get: 'data',
            fallback: [],
            auth: true,
            swr: {
                revalidateOnFocus: false
            }
        },
        addProposalComment: {
            path: '/agenda-notes/{{id}}/comments/',
            method: 'POST',
            get: 'data',
            fallback: null,   
            auth: true,    
        },
        deleteProposalComment: {
            path: '/agenda-notes/{{id}}/comments/{{commentId}}/',
            method: 'DELETE',
            fallback: null,   
            auth: true
        }
    }
}

export default NotesEndpoints;