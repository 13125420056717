import React, { Fragment, useCallback } from 'react'
import { Grid, Typography } from '@material-ui/core';
import ClubPartnershipTeamThumbComponent from '../../components/club_partnership/ClubPartnershipTeamThumb.component';
import { useHistory } from 'react-router'


const ClubPartnershipTeamsContainer = ({
    elements,
    countryId,
    identifier,
    competitionId
}) => {

    const history = useHistory()

    const openDetail = useCallback((itemId) => {
        switch (identifier) {
            case "clubProposal":
                history.push(`/player_requests/club_proposal/teams_competition/${itemId}/${countryId}`);
                break;
            case "eliteClubProposal":
                history.push(`/elite/eliteclubproposal/teams_competition/${itemId}/${countryId}`);
                break;
            case "agencyPartnership":
                history.push(`/mandates/agency_partnership/${itemId}/${countryId}`);
                break;
            case "teamsByCompetition":
                history.push(`/club_proposal/${itemId}/${countryId}/${competitionId}`);
                break;
            case "eliteTeamsByCompetition":
                history.push(`/eliteclubproposal/${itemId}/${countryId}/${competitionId}`);
                break;    
            case "partnerProposal":
                history.push(`/player_requests/partner_proposal/${itemId}/${countryId}`);
                break;
            case "clubPartnership":
                history.push(`/mandates/club_partnership/${itemId}/${countryId}`);
                break;
            case "baPortfolio":
                history.push(`/player_requests/ba_portfolio/${itemId}/${countryId}`);
                break;
        }
    }, []);

    return (
        <Fragment>
            <Grid container>
                {elements?.map((item) =>
                    <Grid key={item.id} item xs={6} sm={4} md={2} onClick={() => openDetail(item.id)}>
                        <ClubPartnershipTeamThumbComponent identifier={identifier} data={item} />
                    </Grid>
                )}
                {elements?.length === 0 ? <Typography style={{ padding: '2rem' }}>Nessun elemento</Typography> : null}
            </Grid>
        </Fragment>
    )
}

export default ClubPartnershipTeamsContainer