import React from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/styles';
import { Paper, Box, Grid, Typography } from '@material-ui/core'
import DeleteCommentContainer from '../../containers/activity/DeleteComment.container.js'
import EditCommentContainer from '../../containers/activity/EditComment.container.js'
import { color } from '../../config/colors/colors'

const useStyles = makeStyles(theme => ({
    gridComment: {
        marginBottom: theme.spacing(2)
    },
    paperComment: {
        padding : "16px"
    },
    label: {
        color: color.primary.main,
        fontWeight: 'bold',
        fontSize:"1rem",
        display: 'inline'
    },
    commentText:{
        color: '#0F2027'
    },
    boxDeleteEditComment: {
        display: "inline"
    }
}));

const CommentsActivityContainer = ({
    activityId,
    comments,
    refreshActivity
}) => {

    const { user } = useSelector(state => state.user)
    const classes = useStyles();

    return (
        <Box>
            {comments?.map((comment) =>
                <Grid key={comment.id} item xs={12} className={classes.gridComment}>
                    <Paper className={classes.paperComment}>
                        <Box className={classes.label}>
                            {comment.user.first_name + " " +  comment.user.last_name}
                        </Box>
                        { user.id === comment.user.id ?
                            <Box className={classes.boxDeleteEditComment}>
                                <DeleteCommentContainer activityId = {activityId} commentId = {comment.id} refreshActivity = {refreshActivity} />
                                <EditCommentContainer activityId = {activityId} commentIdArg = {comment.id} refreshActivity = {refreshActivity} comment= {comment.comment}/>
                            </Box> 
                            :
                            <Typography className={classes.commentText}>{comment.comment}</Typography>
                        }
                    </Paper>
                </Grid>            
            )}
        </Box>
    )
}

export default CommentsActivityContainer