import React, { Fragment, useState, useEffect, useCallback } from "react";
import { useParams } from 'react-router-dom'
import ClubProposalTeamsSectionComponent from "../../components/club_proposal/ClubProposalTeamsSection.component";
import { Grid, Typography, makeStyles, Button } from "@material-ui/core";
import ClubPartnershipTeamsContainer from "../club_partnership/ClubPartnershipTeams.container";
import useSWRRequest from "../../core/useSWRRequest";
import Requests from "../../core/requests";
import { useSelector } from "react-redux";
import Loading from "../../components/Loading";
import { AddCircleRounded } from '@material-ui/icons';
import Modal from '../../components/Modal'
import RequestAddContainer from '../requests/RequestAdd.container'
import { Partner } from "../../conf/Auth.config";

const useStyles = makeStyles({
    title: {
        fontSize: '30px',
        fontFamily: 'Anton-Regular',
        color: '#761200'
    },
    icon: {
        width: 'auto',
        height: '2.5rem',
        '&:hover': {
            background: 'transparent'
        }
    }
})

const TeamsByCompetitionContainer = ({identifier}) => {

    const classes = useStyles();
    const { competitionId, countryId } = useParams()
    const [loaded, setLoaded] = useState(false)
    const { token, user } = useSelector((state) => state.user);
    const otherParams = identifier === "eliteTeamsByCompetition" ? "&is_elite=True" : null
    const { data, loading, mutate } = useSWRRequest(loaded ? Requests.notes.areaAgendaNotes : '', { token: token.access, competitionId, otherParams });
    const [openCreate, setOpenCreate] = useState(false);

    useEffect(() => {
        setLoaded(true)
    }, [])

    const isNotPartnerUser = useCallback(() => {
        return user?.groups?.find(element => element.name !== Partner);
    }, [])

    return (
        <Fragment>
            <Grid item xs={12}>
                {loading && <Loading />}
            </Grid>
            <Grid container>
              
                <Modal
                    open={openCreate}
                    onClose={() => setOpenCreate(false)}
                    title='Create Club Proposal'>
                    <RequestAddContainer onClose={() => setOpenCreate(false)} mutate={mutate} />
                </Modal>
                <Grid item xs={12}>
                    {data?.map((item) => (
                        <ClubProposalTeamsSectionComponent showAddProposalButton={true} nationality={item?.competition?.country?.id} key={item?.competition?.country?.id} title={item?.competition?.name} competition={item?.competition?.id} isNotPartnerUser={isNotPartnerUser} mutate={mutate}>
                            <ClubPartnershipTeamsContainer identifier={identifier} elements={item.teams} countryId={item?.competition?.country?.id} competitionId={item?.competition?.id} />
                        </ClubProposalTeamsSectionComponent>
                    ))}
                </Grid>
            </Grid>
        </Fragment>
    );
}

export default TeamsByCompetitionContainer