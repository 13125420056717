import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { style } from "./Header.component.style";
import { Typography, Grid } from "@material-ui/core";
import { Box } from "@material-ui/core";
import BranchiniLogo from "../../assets/images/branchini-logo.svg";

const Header = ({ classes, title, elite, logo }) => {
    return (
        <>
            <div
                className={classes.root}
                style={{
                    background:
                        elite === true
                            ? "linear-gradient(256deg, #0f2027, #986f21 30%, #000)"
                            : "linear-gradient(256deg, #0f2027, #2c5364 30%, #000)",
                }}
            >
                <Grid container>
                    <Grid item xs={1} />
                    <Grid container item xs={10} className={classes.container}>
                        <Grid item xs={12}>
                            <Typography className={classes.subtitle} noWrap>
                                BEYOND THE RESULT
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.divider} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.title} noWrap>
                                {title}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            {logo === true ? (
                <Box display='flex' justifyContent="flex-end" className={classes.branchini}>
                    <img src={BranchiniLogo} style={{ marginRight: '5rem' }} />
                </Box>

            ) : null}
        </>
    );
};

export default withStyles(style)(Header);
