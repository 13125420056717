import React, { useCallback, Fragment, useState } from "react";
import Requests from "../../core/requests";
import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import { color } from "../../config/colors/colors";
import { Box } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import CallOutcomeComponent from "../../components/club_partnership/CallOutcome.component";
import ConfirmDeletionModalComponent from "../../components/club_partnership/ConfirmDeletionModal.component";
import { isNotMarketOperatorUser } from "../../utils/CheckLoggedUser";

const useStyles = makeStyles((theme) => ({
    subtitle: {
        fontSize: theme.spacing(2),
        color: color.black,
        fontWeight: "bold",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    link: {
        cursor: "pointer",
        overflowWrap: "anywhere",
    },
    fileItem: {
        marginBottom: "8px",
        overflowWrap: "break-word",
    },
    deleteIcon: {
        cursor: "pointer",
    },
}));

const FilesContainer = ({ files, mutate, identifier, fullWidth }) => {
    const classes = useStyles();
    const { token, user } = useSelector((state) => state.user);
    const [openCreate, setOpenCreate] = useState(false);
    const [error, setError] = useState(null);
    const [selectedFileId, setSelectedFileId] = useState(0);

    const setEndPoint = useCallback(() => {
        switch (identifier) {
            case "activity":
                return Requests.mandate_reports.deleteActivityFile;
            case "club":
            case "agency":
                return Requests.mandate_reports.deleteFile;
            case "countryAttachments":
                return Requests.reports.deleteCountryFile;
            case "competitionAttachments":
                return Requests.reports.deleteCompetitionFile;
            case "partnerProposal":
                return Requests.mandate_reports.deleteProposalFile;
            case "proposal":
            case "monitoring":
            case "partnerPlayer":
            case "elitePage":
            case "elitePortfolioPage":
            case "selectedList":
                return Requests.reports.deleteFile;
            case "proposalList":
                return Requests.mandate_reports.deleteTeamFile;
            case "baPortfolio":
                return Requests.mandate_reports.deleteBaMandateFile;
        }
    }, []);

    const deleteFile = useCallback(() => {
        const deleteFunction = setEndPoint();
        deleteFunction({
            token: token.access,
            file_id: selectedFileId,
        })
            .then(() => {
                mutate();
                setOpenCreate(false);
            })
            .catch((error) => {
                setError(error?.response?.data?.error);
            });
    }, [selectedFileId]);

    const confirmDeleteFile = useCallback(
        (id) => {
            setSelectedFileId(id);
            setOpenCreate(true);
            setError(null);
        },
        [selectedFileId]
    );

    const openReportLink = useCallback((url) => {
        if (!url.includes(process.env.REACT_APP_BASE_URL)) {
            url = `${process.env.REACT_APP_BASE_URL}${url}`
        }
        window.open(url, "_blank");
    }, []);

    return (
        <Fragment>
            <Grid item xs={fullWidth ? 12 : 6}>
                <Typography className={classes.subtitle}>Files</Typography>
                {files?.map((file) => (
                    <Box
                        key={file.id}
                        className={classes.fileItem}
                        display="flex"
                        alignItems="center"
                    >
                        {isNotMarketOperatorUser(user?.groups) && (
                            <DeleteIcon
                                className={classes.deleteIcon}
                                onClick={() => confirmDeleteFile(file.id)}
                            />
                        )}
                        <Typography
                            key={file.id}
                            onClick={() =>
                                openReportLink(file.file)
                            }
                            className={classes.link}
                        >
                            {file.file.substring(file.file.lastIndexOf("/") + 1)}
                        </Typography>
                    </Box>
                ))}
            </Grid>
            <ConfirmDeletionModalComponent
                openCreate={openCreate}
                setOpenCreate={setOpenCreate}
                deleteFunction={deleteFile}
            >
                <CallOutcomeComponent error={error} />
            </ConfirmDeletionModalComponent>
        </Fragment >
    );
};

export default FilesContainer;
