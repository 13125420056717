import React, { useEffect, useCallback } from 'react';
import { Grid, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AddCircleRounded } from '@material-ui/icons';
import FilterUsersContainer from '../../containers/filters/FilterUsers.container';
import { color } from '../../config/colors/colors'

const useStyles = makeStyles((theme) => ({    
    input: {
        display: 'none'
    },
    icon: {
        width: 'auto',
        height: '2.7rem',
        '&:hover': {
            background: 'transparent'
        }
    },
    title: {
        fontSize: theme.spacing(3),
        color: color.title,
        fontWeight: 'bold',
    },
    removeMarginTop:{
        marginTop: "6px"
    },
    fullWidthWithMargin: {
        marginTop: "10px",
        width: "100%",
        backgroundColor: "#223239",
        marginLeft: '0px !important'
    }  
}));

const PlayerAddFileComponent = ({
    onReportSubmit,
    ...props
}) => {

    const classes = useStyles();

    const onChange = useCallback((name, value) => {
        props.setFieldValue(name, value)
    }, [])

    useEffect(() => {
        if(props?.values?.file){
            const reader = new FileReader();            
            reader.readAsDataURL(props.values.file);
        }
    }, [props.values])

    return (
        <>
            <Grid item xs>
                <Typography className={classes.title} style={{marginTop: "6px"}}>
                    Add Reports
                </Typography>
            </Grid>
            <Grid item xs={1}>
                <input id='file' name='file' accept="application/pdf" className={classes.input} type="file" onChange={(event) => {
                    props.setFieldValue('file', event.currentTarget.files[0]); 
                }} />
                <label htmlFor='file'>
                    <Button color="primary" className='uploadfile' component="span">
                        <AddCircleRounded className={classes.icon} />
                    </Button>
                </label>
            </Grid>
            <Grid item xs={12}>
                {props?.values?.file?.name && `File to upload: ${props.values.file.name}` }
                <FilterUsersContainer {...props} onChange={onChange} />
            </Grid>
            <Button onClick={() => onReportSubmit(props?.values)} className={classes.fullWidthWithMargin} variant="contained" type='submit' color="primary">
                Upload File
            </Button>
        </>
    )
}

export default PlayerAddFileComponent;