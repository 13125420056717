import React from "react"
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

export const Spacing = ({
    mobile,
    browser
}) => {

    if (mobile > 0 && browser > 0) {        
        return (
            <Grid item xs={mobile} sm={browser} md={browser} lg={browser} xl={browser}/>
        )
    } else if (browser === 0) {
        return (
            <Hidden smUp>
                <Grid item xs={mobile}/>
            </Hidden>
        )
    } else if (mobile === 0) {
        return (
            <Hidden xsDown>
                <Grid item sm={browser} md={browser} lg={browser} xl={browser} />
            </Hidden>
        )
    }
}