import React, { useCallback } from 'react'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { isArray } from 'lodash';

const  FilterAutocompleteComponent = ({
    title,
    options = [],
    multiple = false,
    setFilter = () => { }, 
    value,
    name,
    disabled,
    ...props 
}) => {

    const handleChange = useCallback((event, newValue) => {        
        let selectedValue;
        if (newValue) {
            if (isArray(newValue)) {
                selectedValue = newValue.map((item) => item.id)
            } else {
                selectedValue = newValue.id
            }
        }
        setFilter(selectedValue)
    }, []);

    return (
        <Box alignItems="center" mt={2}>
            <Box marginRight={2}>
                <Typography>{title}</Typography>
            </Box>
            <Box>
                <Autocomplete
                    disabled = { disabled ? true : false }
                    value={value}             
                    multiple={multiple}
                    options={options}
                    onChange={handleChange}                    
                    getOptionLabel={(option) => option.name || ""}
                    getOptionSelected={(option, value) => option.id === value.id}
                    renderInput={(params) => <TextField
                        {...params}
                        error={props.errors && props.errors[name] ? Boolean(props.errors[name]) : false}
                        helperText={props.errors && props.errors[name] ? props.errors[name]: null}
                        label={""}
                        variant="outlined"
                        fullWidth />}
                />
            </Box>
        </Box>

    )
}

export default FilterAutocompleteComponent