import React, { Fragment, useState } from 'react'
import Loading from '../../components/progress/Progress.component'
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import useSWRRequest from '../../core/useSWRRequest'
import Requests from '../../core/requests'
import { ClubPartnershipMandateInitialValues, ClubPartnershipMandateValidationSchema } from '../../conf/ClubPartnershipMandate.config';
import AddMandateComponent from '../../components/club_partnership/AddMandate.component'
import CallOutcomeComponent from '../../components/club_partnership/CallOutcome.component'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import { color } from '../../config/colors/colors'

const useStyles = makeStyles(theme => ({
    centeredTitle: {
        fontSize: theme.spacing(3),
        color: color.title,
        fontWeight: 'bold',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        textAlign: 'center'
    },
    paperCommentOk: {
        padding : "16px",
        backgroundColor: "#d4edda",
        borderColor: "#c3e6cb"
    },
    labelOk: {
        textAlign: "center",
        color: "#155724",
    },
    paperCommentKo: {
        padding : "16px",
        backgroundColor: "#f8d7da",
        borderColor: "#f5c6cb"
    },
    labelKo: {
        textAlign: "center",
        color: color.title,
    }
}));



const MandateStatusContainer = ({
    mandate
}) => {

    const { token } = useSelector(state => state.user)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(null)
    const classes = useStyles();


    const onSubmit = (values, actions) => {
        setLoading(true)
        actions.setSubmitting(true)
        Requests.club_partnership.editMandate({ token: token.access, mandateId: mandate.id },
        {
            type: values.typeStatus,
            players: values.players,
            work_status: values.workStatus,
            users: values.usersInvolved,
            deadline: values.deadline,
            note: values.note
        }).then(() => {
            setLoading(false)
            setError(null)
            setSuccess("Mandate update completed successfully")
            actions.setSubmitting(false)
        }).catch((errorBE) => {
            setLoading(false)
            setError(errorBE?.response?.data?.error)
            actions.setSubmitting(false)
        })
    }

    return (
        <Fragment>
            <CallOutcomeComponent success = {success} error = {error} />
            {loading ? <Loading /> : null}
            <Grid item xs>
                <Typography className={classes.centeredTitle}>
                    Mandate Detail
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Formik
                    initialValues={ClubPartnershipMandateInitialValues(mandate)}
                    validationSchema={ClubPartnershipMandateValidationSchema}
                    enableReinitialize={true}
                    onSubmit={onSubmit}>
                    {(props) => <AddMandateComponent showMandateReferences = {false} country = {Number(mandate?.players?.[0]?.team?.country?.id)} team = {Number(mandate?.players?.[0]?.team?.id)} {...props} />}
                </Formik>
            </Grid>
        </Fragment>
    )
}

export default MandateStatusContainer