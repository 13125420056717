import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        "& .MuiTableCell-head": {
            backgroundColor: '#16282f',
            color: '#fff',
            textAlign: 'left',
            fontSize: "1rem",
            fontWeight: 'bold',
            padding: '10px 12px',
            overflow: "visible",
            border: '10px solid',
        },
        "& .MuiTableSortLabel-icon": {
            opacity: 1,
            color: '#fff',
        },
    },
    sort: {
        color: "#fff",
    }
})

const TableHeader = ({
    order,
    orderBy,
    onRequestSort = () => { },
    headCells
}) => {

    const classes = useStyles();

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead className={classes.root}>
            <TableRow>
                {headCells.map((headCell) => (
                    headCell.sortable ?
                        <TableCell
                            variant='head'
                            key={headCell.id}
                            align='right'
                            sortDirection={orderBy === headCell.id ? order : false}>
                            <TableSortLabel
                                className={classes.sort}
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}>
                                {headCell.label}
                            </TableSortLabel>
                        </TableCell> :
                        <TableCell
                            size='small'
                            key={headCell.id}>
                            {headCell.label}
                        </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default TableHeader