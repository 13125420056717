import React from 'react'
import { withStyles } from "@material-ui/core/styles"
import { style } from './ScoutingPlayersListSelected.component.style';
import TableList from "../../components/table/Table"
import Row from './ScoutingPlayerRowSelected.component';

const headCells = [
    {
        id: 'name', label: 'Player', sortable: true
    },
    {
        id: 'role', label: 'Role', sortable: true
    },
    {
        id: 'age', label: 'Age', sortable: true
    },
    {
        id: 'nationality', label: 'Nationality', sortable: true
    },
    {
        id: 'club', label: 'Club', sortable: true
    },
    {
        id: 'league', label: 'League', sortable: true
    },
    {
        id: 'evalutation', label: 'Evalutation', sortable: true
    },
    {
        id: 'report', label: 'Report', sortable: false
    },
]


const ScountingPlayersListSelectedComponent = ({
    players,
    onClickDetail = () => { },
    onClickReport = () => { }
}) => {

    return (
        <TableList headCells={headCells} count={ players?.length } items={players} page={0} rowsPerPage={10} enableSearch={false} rowsPerPageOptions={[5, 10, 25, 50]}>
            {(item) =>
                <Row player={item} onClickDetail={onClickDetail} onClickReport={onClickReport} />
            }
        </TableList>
        /* <TableList
            headCells={headCells}
            items={players}
            rowsPerPage={20}
            rowsPerPageOptions={[]}
            count={rawData?.count}
            rawData={rawData}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}>
            {(item) =>
                <Row player={item} onClickDetail={onClickDetail} onClickReport={onClickReport} />}
        </TableList> */
    )
}

export default withStyles(style)(ScountingPlayersListSelectedComponent)