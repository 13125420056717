import _ from 'lodash';

export const CalendarEndpoints = {
    basePath: '/api/v1/',
    endpoints: {
        activities: {
            path: 'calendar-activities/',
            method: 'GET',
            get: 'data',
            fallback: [],   
            auth: true,    
        },
        usersInvolved: {
            path: 'calendar-activities-users-involved/',
            method: 'GET',
            get: 'data',
            fallback: [],   
            auth: true,
            swr: {
                revalidateOnFocus: false
            },
            transform: (data) => data.map((item) => ({
                id: item.id,
                name: `${item.first_name} ${item.last_name}`
            })) 
        }, 
        addActivity: {
            path: 'calendar-activities/',
            method: 'POST',
            get: 'data',
            fallback: null,   
            auth: true,    
        }               
    }
}

export default CalendarEndpoints;