import React, { useState, useEffect, useCallback } from "react";
import { withStyles } from "@material-ui/core/styles";
import { style } from "../players/PlayersSection.component.style";
import { Grid, Typography, Button } from "@material-ui/core";
import { AddCircleRounded } from '@material-ui/icons';
import Modal from '../Modal'
import RequestAddContainer from '../../containers/requests/RequestAdd.container'
import CountryAttachmentsContainer from "../../containers/club_proposal/CountryAttachments.container";
import { isNotMarketOperatorUser } from "../../utils/CheckLoggedUser";
import { useSelector } from "react-redux";

const ClubProposalTeamsSectionComponent = ({
  classes,
  title = "",
  children,
  nationality,
  showAddProposalButton,
  competition,
  isNotPartnerUser,
  mutate
}) => {

  const { user } = useSelector(state => state.user)
  const [openCreate, setOpenCreate] = useState(false)
  const [openCreateCountryAttachments, setOpenCreateCountryAttachments] = useState(false)


  const handleOpen = useCallback(() => {
    if (isNotPartnerUser() && isNotMarketOperatorUser(user?.groups)) {
      setOpenCreateCountryAttachments(true)
    } else if (!isNotPartnerUser()) {
      setOpenCreateCountryAttachments(false)
    }
  })


  return (
    <Grid container borderRadius={8} className={classes.root}>
      <Grid item xs style={{ display: "flex", alignItems: "center" }}>
        <Typography className={`${classes.title} ${isNotPartnerUser() && isNotMarketOperatorUser(user?.groups) ? classes.pointer : ''}`} onClick={() => handleOpen()}>{title.toUpperCase()}</Typography>
      </Grid>
      {showAddProposalButton && isNotMarketOperatorUser(user?.groups) &&
        <Grid item>
          <Button color="primary" component="span" onClick={() => setOpenCreate(true)}>
            <AddCircleRounded className={classes.icon} />
          </Button>
        </Grid>
      }
      <Modal
        open={openCreate}
        onClose={() => setOpenCreate(false)}
        title='Create Club Proposal'>
        <RequestAddContainer nationality={nationality} competitionId={competition} competitionName={title} onClose={() => setOpenCreate(false)} mutate={mutate}/>
      </Modal>
      <Modal
        open={openCreateCountryAttachments}
        onClose={() => setOpenCreateCountryAttachments(false)}
        title={competition ? 'Competition attachments' : "Country attachments"}>
        <CountryAttachmentsContainer nationality={nationality} competition={competition} />
      </Modal>
      {children}
    </Grid>
  );
};

export default withStyles(style)(ClubProposalTeamsSectionComponent);
