import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import Submit from '../../components/Submit';
import { Form } from 'formik';
import FilterUsersContainer from '../../containers/filters/FilterUsers.container';
import Select from '../Select';
import FilterPriorityContainer from '../../containers/filters/FilterPriority.container';
import EditTextArea from '../../components/EditTextArea';
import EditText from '../../components/EditText';

const work_status = [{
    label: 'Backlog',
    value: 'Backlog'
}, {
    label: 'Todo',
    value: 'Todo'
}, {
    label: 'InProgress',
    value: 'InProgress'
}, {
    label: 'Elite',
    value: 'Elite'
}, {
    label: 'Done',
    value: 'Done'
}]

const UpdateClubRequest = ({
    ...props
}) => {

    const onChange = useCallback((name, value) => {
        props.setFieldValue(name, value)
    }, [])

    return (
        <Form onSubmit={props.handleSubmit}>
            <Box padding={2}>
                <Box mt={2}>
                    <EditText {...props} type="date" label='Deadline' name="deadline" />
                </Box>
                {/* <Box mt={2}>
                    <FilterPriorityContainer {...props} onChange={onChange} />
                </Box> */}
                <Box mt={2}>
                    <FilterUsersContainer {...props} onChange={onChange} />
                </Box>
                <Box mt={2}>
                    <EditTextArea {...props} label='Note' name="note" />
                </Box>
                <Box mt={2}>
                    <Select {...props} value={props.values.work_status} label='Work Status' name="work_status" options={work_status} />
                </Box>
                <Box my={2}>
                    <Submit {...props} title='Update' />
                </Box>
            </Box>
        </Form>
    );
};

export default UpdateClubRequest;