import React, { useState } from 'react';
import Row from './RowClubRequest';
import TableList from '../../components/table/Table';

const headCells = [
    {
        id: 'team', label: 'Team', sortable: true
    },
    /* {
        id: 'role', label: 'Role', sortable: true
    }, */
    {
        id: 'position', label: 'Position', sortable: false
    },
    {
        id: 'start', label: 'Start', sortable: false
    },
    {
        id: 'deadline', label: 'Deadline', sortable: false
    },
    {
        id: 'status', label: 'Status', sortable: false
    },
    {
        id: 'delete', label: '', sortable: false
    },
    {
        id: 'detail', label: '', sortable: false
    },
]

const List = ({
    items = [],
    onAddAction = () => { },
    onSelectItem = () => { },
    onDeleteItem = () => { },
    add = true
}) => {

    const [searchValue, setSearchValue] = useState('')
    const filterItems = (items) => items.filter((item) => item.team.name.toLowerCase().includes(searchValue.toLowerCase()) || `${item.role.full_name}`.toLowerCase().includes(searchValue.toLowerCase()))

    return (
        <TableList 
            add={add}
            headCells={headCells}
            items={filterItems(items)}
            page={0}
            rowsPerPage={25}
            setSearchValue={setSearchValue} addAction={onAddAction}>
            {(item) => <Row key={item.id} item={item} onSelectItem={onSelectItem} onDeleteItem={onDeleteItem}/>}
        </TableList>
    );
};


export default List;