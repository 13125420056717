import React, { useEffect } from 'react';
import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AddCircleRounded } from '@material-ui/icons';

const useStyles = makeStyles(() => ({    
    input: {
        display: 'none'
    },
    icon: {
        width: 'auto',
        height: '2.7rem',
        '&:hover': {
            background: 'transparent'
        }
    },    
}));

const ReportAddFileComponent = ({
    ...props
}) => {

    const classes = useStyles();


    useEffect(() => {
        if(props.values.file){
            const reader = new FileReader();            
            reader.readAsDataURL(props.values.file);
        }

    }, [props.values])

    return (
        <Box>
            <input id='file' name='file' accept="application/pdf" className={classes.input} type="file" onChange={(event) => {
                props.setFieldValue('file', event.currentTarget.files[0]); 
                props.handleSubmit()               
            }} />
            <label htmlFor='file'>
                <Button color="primary" component="span">
                    <AddCircleRounded className={classes.icon} />
                </Button>
            </label>
        </Box>
    )
}

export default ReportAddFileComponent;