import { color } from '../../config/colors/colors.js';

export const style = theme => ({
    root: {
        "& .MuiDialog-paperFullWidth": {
            padding: "1rem"
        },
        "& .MuiButton-root:not(.uploadfile)": {
            backgroundColor: color.primary.main,
            color: color.white,
            fontSize: theme.spacing(2),
            fontWeight: 600,
            width: '10rem',
            margin: theme.spacing(2),
            "& .MuiButton-root:hover": {
                backgroundColor: color.primary.main,
                color: color.white,
            }
        }
    },
    title: {
        fontSize: theme.spacing(3),
        color: color.title,
        fontWeight: 'bold'
    },
    close: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
    },
    icon: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        border: `2px solid ${theme.palette.primary.main}`,
        backgroundColor: '#fff',
        boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)"
    },
    container: {
        alignSelf: 'center',
    },
    name: {
        fontSize: "22px",
        color: color.primary.main,
        fontWeight: 600,
        textTransform: 'capitalize'
    },
    star: {
        color: color.primary.main,
        marginRight: '5px'
    },
    monitored: {
        color: color.primary.main,
        fontSize: "22px",
        fontWeight: '900',
        textTransform: 'uppercase'
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        backgroundColor: color.primary.main,
        height: theme.spacing(0.2)
    },
    valueContainer: {
        padding: '0.3rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    value: {
        color: color.secondary.main,
        textTransform: 'uppercase',
        paddingLeft: theme.spacing(0.6),
    },
    label: {
        color: color.primary.main,
        fontWeight: 'bold',
    },
    link: {
        cursor: 'pointer',
        color: color.secondary.main,
        textTransform: 'uppercase',
        textDecoration: 'underline'
    },
    marginLeftNone: {
        marginLeft: "0px !important"
    }
});


