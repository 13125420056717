import * as Yup from 'yup';

export const AddPlayerInitialValues = () => ({
    typeStatus: [],
    workStatus: '',
    usersInvolved: [],
    country : '',
    team : '',
    players : [],
})

export const AddPlayerValidationSchema = Yup.object().shape({
    typeStatus: Yup.string().required('Type is required'),
    usersInvolved: Yup.string().required('Users Involved is required'),
    country: Yup.string().required('Country is required'),
    team: Yup.string().required('Team is required').nullable(),
    players: Yup.string().required('Players is required')
})