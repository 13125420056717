import React from "react";
import TablePagination from "@material-ui/core/TablePagination";

const TableFooter = ({
    count,
    pageIndex,
    setPageIndex,
}) => {
    const [rowsPerPage, setRowsPerPage] = React.useState(20);


    const handleChangePage = (event, pageIndex) => {
        setPageIndex(pageIndex + 1);
    };

    return (
        <TablePagination
            component="div"
            count={count ? count : 0}
            onChangePage={handleChangePage}
            page={pageIndex - 1}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[]}
        />
    );
};

export default TableFooter;
