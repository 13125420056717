import _ from 'lodash';

export const BaPortfolioEndpoints = {
    basePath: '/api/v1/ba-portfolio-requests/',
    endpoints: {
        agenciesList: {
            path: 'area-ba-portfolio-proposals/',
            method: 'GET',
            get: 'data',
            fallback: [],
            auth: true,
        },
        agencyMandates: {
            path: 'ba-portfolio-proposals/?country={{countryId}}&agency={{agencyId}}',
            method: 'GET',
            get: 'data',
            fallback: [],
            swr: {
                revalidateOnFocus: false,
                revalidateOnMount: false,
                revalidateOnReconnect: false,
                dedupingInterval: 1000,
            },
            auth: true,
        },
        deleteMandate: {
            path: 'ba-portfolio-proposals/{{id}}/',
            method: 'DELETE',
            fallback: null,
            auth: true
        },
        getMandate: {
            path: 'ba-portfolio-proposals/{{id}}/',
            method: 'GET',
            get: 'data',
            fallback: [],
            auth: true,
        },
        addMandate: {
            path: 'ba-portfolio-proposals/',
            method: 'POST',
            get: 'data',
            fallback: null,
            auth: true,
        },
        editMandate: {
            path: 'ba-portfolio-proposals/{{id}}/',
            method: 'PATCH',
            get: 'data',
            fallback: null,
            auth: true,
        },
        getCommentsByMandate: {
            path: 'ba-portfolio-proposals/{{mandateId}}/comments/',
            method: 'GET',
            get: 'data',
            fallback: [],
            auth: true,
            swr: {
                revalidateOnFocus: false
            }
        },
        addMandateComment: {
            path: 'ba-portfolio-proposals/{{id}}/comments/',
            method: 'POST',
            get: 'data',
            fallback: null,   
            auth: true,    
        },
        deleteMandateComment: {
            path: 'ba-portfolio-proposals/{{id}}/comments/{{commentId}}/',
            method: 'DELETE',
            fallback: null,   
            auth: true
        }
    }
}

export default BaPortfolioEndpoints;