import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import EditText from '../../components/EditText';
import Submit from '../../components/Submit';
import { Form } from 'formik';
import FilterPlayerByName from '../../containers/filters/FilterPlayerByName.container';

const AddProposal = ({
    priority,
    roles,
    positions,
    ...props
}) => {

    const onChange = useCallback((name, value) => {
        props.setFieldValue(name, value)
    }, [])

    return (
        <Form onSubmit={props.handleSubmit}>
            <Box mt={2}>
                <EditText {...props} name='team' disabled={true} label='Team' />
            </Box>
            <Box mt={2}>
                <EditText {...props} name='position' disabled={true} label='Position' />
            </Box>
            <Box mt={2}>
                <FilterPlayerByName {...props} setFilter={(value) => onChange('players', value)} name="players" multiple={true} />
            </Box>
            <Box my={2}>
                <Submit {...props} title='Save' />
            </Box>
        </Form>
    );
};

export default AddProposal;