import React from 'react'
import { withStyles } from "@material-ui/core/styles"
import { style } from './Footer.component.style';
import FootureLogo from '../../assets/images/footurelab-logo.png'

const Footer = ({
    classes
}) => {
    return (
        <div className={classes.root}>
            <img src={FootureLogo} className={classes.logo} />
        </div>
    )

}

export default withStyles(style)(Footer)