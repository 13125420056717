import React, { Fragment } from 'react';
import { Route, Redirect } from "react-router-dom";
import { useSelector } from 'react-redux';

const PrivateRoute = ({
    component: Component,
    ...rest
}) => {

    const { token } = useSelector(state => state.user)

    return (
        <Route
            {...rest}
            render={props => {

                if (!token) {
                    return <Redirect to='/login' />
                }

                return (
                    <Fragment>
                        <Component {...props} />
                    </Fragment>
                )
            }}
        />
    )

}

export default PrivateRoute