import React, { Fragment, useState } from 'react'
import { AddCircleRounded } from '@material-ui/icons';
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import Modal from '../../components/Modal'
import AddLinkContainer from '../../containers/club_partnership/AddLink.container';
import { color } from '../../config/colors/colors'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: theme.spacing(3),
        color: color.title,
        fontWeight: 'bold',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    icon: {
        width: 'auto',
        height: '2.7rem',
        '&:hover': {
            background: 'transparent'
        }
    }
}));

const MandateAddLinkContainer = ({
    mandateId,
    mutate,
    identifier,
    nationality,
    competition,
    teamId
}) => {

    const classes = useStyles();
    const [openCreate, setOpenCreate] = useState(false)

    return (
        <Fragment>
            <Grid item xs>
                <Typography className={classes.title}>
                    Upload Links
                </Typography>
            </Grid>
            <Grid item>
                <Button color="primary" component="span" onClick={() => setOpenCreate(true)}>
                    <AddCircleRounded className={classes.icon} />
                </Button>
                <Modal
                    open={openCreate}
                    onClose={() => setOpenCreate(false)}
                    title='Add Link'>
                    <AddLinkContainer teamId = {teamId} identifier = {identifier} nationality = {nationality} competition = {competition} mandateId={mandateId} mutate = {mutate} onClose={() => setOpenCreate(false)} />
                </Modal>
            </Grid>
        </Fragment>
    )
}

export default MandateAddLinkContainer
