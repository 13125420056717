import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

const RowToDoValidProposalComponent = ({item, setOpenCreate, setProposalId}) => {

    return (
        <TableRow key={item?.id}>
            <TableCell>{item?.team?.name}</TableCell>
            <TableCell>{item?.position_text}</TableCell>
            <TableCell>{item?.deadline}</TableCell>
            <TableCell align='center' onClick={() => {setOpenCreate(true); setProposalId(item?.id)}} style={{ cursor: 'pointer' }}><AddIcon /></TableCell>
        </TableRow>
    )
}

export default RowToDoValidProposalComponent;