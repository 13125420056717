import React from 'react';
import { Box, Grid } from '@material-ui/core';
import EditText from '../EditText';
import EditTextArea from '../EditTextArea';
import Submit from '../Submit';
import { Form, Formik } from 'formik';
import CalendarActivityUsersContainer from '../../containers/calendar/CalendarActivityUsers.container';
import CalendarActivityTypeContainer from '../../containers/calendar/CalendarActivityType.container';


const CalendarActivityComponent = ({    
    onSubmit = () => {},
    initialValues,
    validationSchema 
}) => {
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={onSubmit} >
            {(props) =>
                <Form onSubmit={props.handleSubmit}>
                    <Box mt={2}>
                        <CalendarActivityTypeContainer {...props} onChange={(name, value) => props.setFieldValue(name, value)} name="type" />
                    </Box>
                    <Box mt={2}>
                        <CalendarActivityUsersContainer {...props} onChange={(name, value) => props.setFieldValue(name, value)} />
                    </Box>
                    <Box mt={2}>
                        <EditText {...props} label='Activity Detail' name="activityDetail" />
                    </Box> 
                    <Box mt={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <EditText {...props} type="time" label='Start Time' name="startTime"/>
                            </Grid>
                            <Grid item xs={6}>
                                <EditText {...props} type="time" label='End Time' name="endTime"/>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box mt={2}>
                        <EditTextArea {...props} label='Note' name="note" />
                    </Box>
                    <Box my={2}>
                        <Submit {...props} title='Save' />
                    </Box>
                </Form>
            }
        </Formik>
    );
};

export default CalendarActivityComponent;