import React, { Fragment } from 'react'
import PlayerThumbHorizontalComponent from '../../components/players/PlayerThumbHorizontal.component'
import { LinearProgress } from '@material-ui/core'

const AssistedPlayerContainer = ({
    id,
    data,
    loading,
    identifier
}) => {

    return (
        <Fragment>
            {loading && <LinearProgress />}
            {data ? <PlayerThumbHorizontalComponent data={data} identifier={identifier} /> : null}
        </Fragment>
    )
}

export default AssistedPlayerContainer