import React from 'react'
import LoginContainer from '../../containers/login/Login.container'
import LoginLayout from '../../layout/login/Login.layout'

const LoginPage = () => {

    return (
        <LoginLayout>
            <LoginContainer />
        </LoginLayout>
    )
}

export default LoginPage