import auth from './auth';
import users from './users';
import roles from './roles';
import players from './players'
import reports from './reports'
import areas from './areas'
import competitions from './competitions'
import notes from './notes'
import teams from './teams'
import proposal from './proposal'
import calendar from './calendar'
import activity from './activity'
import club_partnership from './club_partnership'
import mandate_reports from './mandate_reports'
import agency_partnership from './agency_partnership'
import agency from './agency'
import inbox from './inbox'
import partner_proposal from './partner_proposal'
import ba_portfolio from './ba_portfolio'

const settings = {
    baseURL: ''
}

export const Endpoints = {
    auth,  
    users,
    roles,
    players,
    reports,
    areas,
    competitions,
    notes,
    teams,
    proposal,
    calendar,
    activity,
    club_partnership,
    mandate_reports,
    agency_partnership,
    agency,
    inbox,
    partner_proposal,
    ba_portfolio
}

Object.defineProperty(Endpoints, 'baseURL', {
    enumerable: false,
    get() {
        return settings.baseURL
    },
    set(baseURL) {
        return settings.baseURL = baseURL
    }
})

export default Endpoints