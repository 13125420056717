import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import EditText from '../EditText';
import Submit from '../Submit';
import { Form } from 'formik';
import CountryContainer from '../../containers/agency_partnership/Country.container';
import UsersInvolvedContainer from '../../containers/club_partnership/UsersInvolved.container';
import AddAgencyLogoContainer from '../../containers/agency_partnership/AddAgencyLogo.container';
import FilterUsersContainer from '../../containers/filters/FilterUsers.container'

const AddAgencyComponent = ({
    caller,
    ...props
}) => {

    const onChange = useCallback((name, value) => {
        props.setFieldValue(name, value)
    }, [])

    return (
        <Form onSubmit={props.handleSubmit}>
            <Box mt={2}>
                <CountryContainer {...props} onChange={onChange} />
            </Box>
            <Box mt={2}>
                <EditText {...props} label='Agency Name' name="name" />
            </Box>
            <Box mt={2}>
                {caller === 'baPortfolio' || caller === 'partnerProposal' ? <FilterUsersContainer {...props} onChange={(name, value) => props.setFieldValue(name, value)} /> : <UsersInvolvedContainer {...props} onChange={(name, value) => props.setFieldValue(name, value)} />}
            </Box>
            <Box mt={2}>
                <AddAgencyLogoContainer {...props} onChange={(name, value) => props.setFieldValue(name, value)} />
            </Box>
            <Box my={2}>
                <Submit {...props} title='Save' />
            </Box>
        </Form>
    );
};

export default AddAgencyComponent;