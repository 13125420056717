import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import EditText from '../EditText';
import EditTextArea from '../EditTextArea';
import Submit from '../Submit';
import { Form } from 'formik';
import TeamContainer from '../../containers/club_partnership/Team.container';
import MandateReferencesContainer from '../../containers/club_partnership/MandateReferences.container';
import UsersInvolvedContainer from '../../containers/club_partnership/UsersInvolved.container';
import TypeStatusContainer from '../../containers/club_partnership/TypeStatus.container';
import Select from '../Select';
import useSWRRequest from '../../core/useSWRRequest'
import Requests from '../../core/requests'
import { useSelector } from 'react-redux'

const type_status =  [
    {
        id: 1,
        name: 'Player on Sale'
    },
    {
        id: 2,
        name: 'Player to Buy'
    },
    {
        id: 3,
        name: 'Contract extension'
    },
    {
        id: 4,
        name: 'Contract expired'
    }
]

const work_status =  [
    {
        label: 'NotPriority',
        value: 'NotPriority'
    },
    {
        label: 'Todo',
        value: 'Todo'
    },
    {
        label: 'InProgress',
        value: 'InProgress'
    },
    {
        label: 'Done',
        value: 'Done'
    }
]

const AddMandateComponent = ({
    country,
    team,
    showMandateReferences,
    ...props
}) => {

    const { token } = useSelector(state => state.user)

    const { data: countries, loading } = useSWRRequest(Requests.areas.countriesHasTeams, { token: token.access })


    const onChange = useCallback((name, value) => {
        props.setFieldValue(name, value)
    }, [])

    return (
        <Form onSubmit={props.handleSubmit}>
            <Box mt={2}>
                <EditText {...props} type="date" label='Deadline' name="deadline" />
            </Box>
            {showMandateReferences ? 
                <Box mt={2}>
                    <MandateReferencesContainer loading = {loading} countries = {countries} country = {country} team = {team} {...props} onChange={onChange} />
                </Box>
                :
                null
            }
            <Box mt={2}>
                <TeamContainer loading = {loading} countries = {countries} country = {country} team = {team} {...props} onChange={onChange} />
            </Box>            
            <Box mt={2}>
                <TypeStatusContainer {...props} onChange={(name, value) => props.setFieldValue(name, value)} options={type_status}/>
            </Box>
            <Box mt={2}>
                <Select {...props} label='Work Status' name="workStatus" options={work_status} value={props.values.workStatus}/>
            </Box>
            <Box mt={2}>
                <UsersInvolvedContainer {...props} onChange={(name, value) => props.setFieldValue(name, value)} />
            </Box>
            <Box mt={2}>
                <EditTextArea {...props} label='Note' name="note" />
            </Box>
            <Box my={2}>
                <Submit {...props} title='Save' />
            </Box>
        </Form>
    );
};

export default AddMandateComponent;