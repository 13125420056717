import _ from 'lodash';

export const MandateReportsEndpoints = {
    basePath: '/api/v1/reports/',
    endpoints: {
        addFile: {
            path: 'file-mandates/',
            method: 'POST',
            get: 'data',
            fallback: null,
            auth: true,
            multipart: true
        },
        deleteFile: {
            path: '/file-mandates/{{file_id}}/',
            method: 'DELETE',
            get: 'data',
            fallback: null,
            auth: true,            
        },
        addLink: {
            path: 'link-mandates/',
            method: 'POST',
            get: 'data',
            fallback: null,   
            auth: true,    
        },
        deleteLink: {
            path: '/link-mandates/{{link_id}}/',
            method: 'DELETE',
            get: 'data',
            fallback: null,
            auth: true,            
        },
        addActivityFile: {
            path: 'file-calendar-activities/',
            method: 'POST',
            get: 'data',
            fallback: null,
            auth: true,
            multipart: true
        },
        deleteActivityFile: {
            path: 'file-calendar-activities/{{file_id}}/',
            method: 'DELETE',
            get: 'data',
            fallback: null,
            auth: true 
        },
        addCountryFile: {
            path: 'file-countries/',
            method: 'POST',
            get: 'data',
            fallback: null,
            auth: true,
            multipart: true
        },
        addCountryLink: {
            path: 'link-countries/',
            method: 'POST',
            get: 'data',
            fallback: null,   
            auth: true,    
        },
        addCompetitionFile: {
            path: 'file-competitions/',
            method: 'POST',
            get: 'data',
            fallback: null,
            auth: true,
            multipart: true
        },
        addCompetitionLink: {
            path: 'link-competitions/',
            method: 'POST',
            get: 'data',
            fallback: null,   
            auth: true,    
        },
        addProposalFile: {
            path: 'file-partner-proposals/',
            method: 'POST',
            get: 'data',
            fallback: null,
            auth: true,
            multipart: true
        },
        addProposalLink: {
            path: 'link-partner-proposals/',
            method: 'POST',
            get: 'data',
            fallback: null,   
            auth: true,    
        },
        deleteProposalFile: {
            path: '/file-partner-proposals/{{file_id}}/',
            method: 'DELETE',
            get: 'data',
            fallback: null,
            auth: true,            
        },
        deleteProposalLink: {
            path: '/link-partner-proposals/{{link_id}}/',
            method: 'DELETE',
            get: 'data',
            fallback: null,
            auth: true,            
        },
        addTeamFile: {
            path: 'file-teams/',
            method: 'POST',
            get: 'data',
            fallback: null,
            auth: true,
            multipart: true
        },
        addTeamLink: {
            path: 'link-teams/',
            method: 'POST',
            get: 'data',
            fallback: null,   
            auth: true,    
        },
        deleteTeamFile: {
            path: '/file-teams/{{file_id}}/',
            method: 'DELETE',
            get: 'data',
            fallback: null,
            auth: true,            
        },
        deleteTeamLink: {
            path: '/link-teams/{{link_id}}/',
            method: 'DELETE',
            get: 'data',
            fallback: null,
            auth: true,            
        },
        addBaMandateFile: {
            path: '/file-ba-proposals/',
            method: 'POST',
            get: 'data',
            fallback: null,
            auth: true,
            multipart: true
        },
        addBaMandateLink: {
            path: '/link-ba-proposals/',
            method: 'POST',
            get: 'data',
            fallback: null,   
            auth: true,    
        },
        deleteBaMandateFile: {
            path: '/file-ba-proposals/{{file_id}}/',
            method: 'DELETE',
            get: 'data',
            fallback: null,
            auth: true,            
        },
        deleteBaMandateLink: {
            path: '/link-ba-proposals/{{link_id}}/',
            method: 'DELETE',
            get: 'data',
            fallback: null,
            auth: true,            
        }
    }
}

export default MandateReportsEndpoints;