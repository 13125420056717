import _ from 'lodash';

export const UsersEndpoints = {
    basePath: '/api/v1',
    endpoints: {
        me: {
            path: '/users/me/',
            method: 'GET',
            get: 'data',
            fallback: [],
            auth: true,
            swr: {
                revalidateOnFocus: false
            }
        },
        users: {
            path: '/users/admin-user-handlers/',
            method: 'GET',
            get: 'data',
            fallback: [],
            auth: true,
            swr: {
                revalidateOnFocus: false
            },
            transform: (data) => data.map((item) => ({
                id: item.id,
                name: `${item.first_name} ${item.last_name}`
            }))
        },
        baUsers: {
            path: '/users/?groups=HeadCompany',
            method: 'GET',
            get: 'data',
            fallback: [],
            auth: true,
            swr: {
                revalidateOnFocus: false
            }
        },
        getHeadCompanyUsers: {
            path: '/users/?groups=HeadCompany',
            method: 'GET',
            get: 'data',
            fallback: [],
            auth: true,
            swr: {
                revalidateOnFocus: false
            },
            transform: (data) => data.map((item) => ({
                id: item.id,
                name: `${item.first_name} ${item.last_name}`
            }))
        },
        getPlayerRequestUsers: {
            path: '/users/?groups=HeadCompany&groups=Partner&groups=MarketOperator',
            method: 'GET',
            get: 'data',
            fallback: [],
            auth: true,
            swr: {
                revalidateOnFocus: false
            },
            transform: (data) => data.map((item) => ({
                id: item.id,
                name: `${item.first_name} ${item.last_name}`
            }))
        },
        getPartnerProposalUsers: {
            path: '/users/?groups=HeadCompany&groups=Partner',
            method: 'GET',
            get: 'data',
            fallback: [],
            auth: true,
            swr: {
                revalidateOnFocus: false
            },
            transform: (data) => data.map((item) => ({
                id: item.id,
                name: `${item.first_name} ${item.last_name}`
            }))
        }
    }
}

export default UsersEndpoints;