import * as Yup from 'yup';

export const AgencyPartnershipAgencyInitialValues = () => ({
    name : '',
    country : '',
    usersInvolved: [],
    logo : '',
    
})

export const AgencyPartnershipAgencyValidationSchema = Yup.object().shape({
    country: Yup.string().required('Country is required'),
    name: Yup.string().required('Name is required'),
})