import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import createSagaMiddleware from 'redux-saga'
import sagas from './sagas.store'
import user from './user.store'

const sagaMiddleware = createSagaMiddleware()

const reducer = combineReducers({
   user
})

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
})

sagaMiddleware.run(sagas)

export default store;