import React, { useCallback, useEffect, useState } from 'react'
import MainLayout from '../../layout/main/Main.layout'
import ContentLayout from '../../layout/content/Content.layout'
import { Box, Grid } from '@material-ui/core'
import RequestListContainer from '../../containers/requests/RequestsList.container'
import Modal from '../../components/Modal'
import RequestAddContainer from '../../containers/requests/RequestAdd.container'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom'
import Requests from "../../core/requests";
import { useSelector } from "react-redux";
import useSWRRequest from "../../core/useSWRRequest";
import MandateAddFileContainer from '../../containers/club_partnership/MandateAddFile.container';
import MandateAddLinkContainer from '../../containers/club_partnership/MandateAddLink.container';
import FilesContainer from '../../containers/club_partnership/Files.container';
import LinksContainer from '../../containers/club_partnership/Links.container';
import { Partner } from "../../conf/Auth.config";
import { isNotMarketOperatorUser } from '../../utils/CheckLoggedUser'

const RequestsPage = () => {

    const history = useHistory()
    const [openCreate, setOpenCreate] = useState(false)
    const { token, user } = useSelector((state) => state.user);
    const [loaded, setLoaded] = useState(false)
    const [filter, setFilter] = useState(null)
    const { teamId, countryId, competitionId } = useParams();
    const isEliteClubProposal = window?.location?.pathname.startsWith("/eliteclubproposal/") ? true : false;
    const { data, loading, mutate } = useSWRRequest(Requests.competitions.getCompetition, { token: token.access, competitionId });
    const { data: teamData, mutate: teamMutate } = useSWRRequest(loaded ? Requests.teams.teamById : '', { token: token.access, id: teamId });
    const identifier = "proposalList";

    useEffect(() => {
    }, [openCreate])

    useEffect(() => {
        setLoaded(true)
    }, [])

    const onSelectItem = useCallback((teamId) => {
        isEliteClubProposal ? history.push(`/elite/club_requests/${teamId}`) : history.push(`/club_requests/${teamId}`)   
    }, [])

    return (
        <MainLayout title={isEliteClubProposal ? 'Elite Club Proposal' : 'Club Proposal'}>
            <ContentLayout>
                {user?.groups?.find(element => element.name !== Partner) && isNotMarketOperatorUser(user?.groups) &&
                    <>
                        <Grid container item xs={12} alignItems='center'>
                            <MandateAddFileContainer identifier={identifier} teamId={teamId} mutate={teamMutate} />
                            <MandateAddLinkContainer identifier={identifier} teamId={teamId} mutate={teamMutate} />
                        </Grid>
                    
                        <Grid container item xs={12}>
                            <FilesContainer identifier={identifier} files={teamData?.files} mutate={teamMutate} />
                            <LinksContainer identifier={identifier} links={teamData?.links} mutate={teamMutate} />
                        </Grid>
                    </>
                }

                <Box mt={2} width={1}>
                    <RequestListContainer teamId={teamId} isEliteClubProposal={isEliteClubProposal} filter={filter} onAddAction={() => setOpenCreate(true)} onSelectItem={onSelectItem} open={openCreate} />
                </Box>
                <Modal
                    open={openCreate}
                    onClose={() => setOpenCreate(false)}
                    title='Create Club Proposal'>
                    <RequestAddContainer competitionName={data?.name} competitionId={Number(competitionId)} nationality={countryId} teamId={teamId} onClose={() => setOpenCreate(false)} />
                </Modal>
            </ContentLayout>
        </MainLayout >
    )
}

export default RequestsPage