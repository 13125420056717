
import useSWR from 'swr'

export const useSWRRequest = (request, params = {}, swrOptions = undefined) => {

    const { data, error, mutate } = useSWR(
        () => request.getKey(params),
        () => request(params),
        swrOptions || request.swrOptions
    )

    return {
        error,
        loading: !error && !data,
        data: typeof data === 'undefined' ? request.settings?.fallback : data,
        mutate
    }
}

export default useSWRRequest