import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { style } from "./PlayersSection.component.style";
import { Grid, Typography } from "@material-ui/core";
import PortiereIcon from "../../assets/icons/portiere.svg";
import DifesaIcon from "../../assets/icons/difensore.svg";
import CentrocampoIcon from "../../assets/icons/centrocampista.svg";
import TrequartiIcon from '../../assets/icons/trequarti.svg'
import AttaccoIcon from "../../assets/icons/attaccante.svg";

const PlayersSectionComponent = ({ classes, title = "", children }) => {
  return (
    <Grid container borderRadius={8} className={classes.root}>
      <Grid item style={{ display: "flex", alignItems: "center" }}>
        <img
          src={
            title === "Portiere"
              ? PortiereIcon
              : title === "Difesa"
                ? DifesaIcon
                : title === "Centrocampo"
                  ? CentrocampoIcon
                  : title === "Attacco"
                    ? AttaccoIcon
                    : title === 'Trequarti'
                      ? TrequartiIcon
                      : null
          }
          height="40px"
        />
        <Typography className={classes.title}>{title.toUpperCase()}</Typography>
      </Grid>
      {children}
    </Grid>
  );
};

export default withStyles(style)(PlayersSectionComponent);
