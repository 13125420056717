import React, { useState, Fragment, useCallback } from 'react'
import Loading from '../../components/progress/Progress.component'
import { Formik } from 'formik';
import { AddPlayerInitialValues, AddPlayerValidationSchema } from '../../conf/AddPlayer.config';
import { useSelector } from 'react-redux'
import Requests from '../../core/requests'
import CallOutcomeComponent from '../../components/club_partnership/CallOutcome.component'
import AddPlayerComponent from '../../components/my_portfolio/AddPlayer.component';

const AddPlayerContainer = ({
    onClose,
    mutate,
    selectedTab
}) => {

    const { token } = useSelector(state => state.user)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const setTeamType = useCallback(() => {
        switch (selectedTab) {
            case 0:
                return "First"
            case 1:
                return "Youth"
        }
    }, [])

    const onSubmit = (values, actions) => {
        setLoading(true)
        actions.setSubmitting(true)
        Requests.players.addPlayer(({
            token: token.access,
            player: values.players,
            team_type: setTeamType(),
            player_status: values.typeStatus,
            work_status: values.workStatus ? values.workStatus : 'NotPriority',
            operators: values.usersInvolved
        })).then(() => {
            setLoading(false)
            setError(null)
            actions.setSubmitting(false)
            onClose()
            mutate()
        }).catch((error) => {
            setLoading(false)
            setError(error?.response?.data?.error)
            actions.setSubmitting(false)
        })
    }

    return (
        <Fragment>
            <CallOutcomeComponent error={error} />
            {loading ? <Loading /> : null}
            <Formik
                initialValues={AddPlayerInitialValues()}
                validationSchema={AddPlayerValidationSchema}
                enableReinitialize={true}
                onSubmit={onSubmit} >
                {(props) => <AddPlayerComponent {...props} />}
            </Formik>
        </Fragment>
    )
}

export default AddPlayerContainer