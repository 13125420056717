import React, { useEffect, Fragment, useMemo } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import ScoutingPlayersListSelectedComponent from "../../components/scouting/ScoutingPlayersListSelected.component";
import { useSelector } from "react-redux";
import useSWRRequest from "../../core/useSWRRequest";
import Requests from "../../core/requests";
import _ from "lodash";
import qs from "qs";

const ElitePlayersContainer = ({ open, filter, openOverlay, identifier }) => {
    const { token } = useSelector((state) => state.user);

    const query = useMemo(
        () =>
            identifier === "elitePage" ? 
            qs.stringify(
                _.pickBy({ ...filter, is_elite: "True" }, (value) => value !== null),
                { encode: false }
            ) 
            : 
            qs.stringify(
                _.pickBy({ ...filter }, (value) => value !== null),
                { encode: false }
            ),
        [filter]
    );


    const { data: players, loading, mutate } = useSWRRequest(identifier === "elitePortfolioPage" ? Requests.players.elitePortfolio : Requests.players.elite, {
        token: token.access,
        query: query && `?${query}`
    });

    useEffect(() => {
        if (!open) {
            mutate();
        }
    }, [open, filter]);

    return (
        <Fragment>
            {loading ? <LinearProgress /> : null}
            <ScoutingPlayersListSelectedComponent onClickDetail={openOverlay} players={players} />
        </Fragment>
    );
};

export default ElitePlayersContainer;
