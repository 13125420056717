import React from 'react'
import { withStyles } from "@material-ui/core/styles"
import { style } from './ScoutingSection.component.style';
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { Link } from "react-router-dom";

const ScoutingSectionComponent = ({
    classes,
    title,
    link
}) => {

    return (
        <Box p={8}>
            <Link to={link} className={classes.link}> 
                <Box display='flex' justifyContent='center'  className={classes.root}>
                    <Box boxShadow={1} borderRadius={1} width={1} height={200} display='flex' justifyContent='center' alignItems='center'>
                        <Typography className={classes.title}>
                            {title}
                        </Typography>
                    </Box>
                </Box>
            </Link>
        </Box>
    )
}

export default withStyles(style)(ScoutingSectionComponent)