import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import Moment from "moment"
import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionIcon from '@material-ui/icons/Description';
import {teal} from "@material-ui/core/colors";
import TableList from "../table/Table";

export const InboxRow = ({
                 children
             }) => {

    return (
            <TableRow>
                <TableCell>{children}</TableCell>
            </TableRow>
    )
}