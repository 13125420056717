import React, { Fragment, useState } from "react";
import Loading from "../../components/progress/Progress.component";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import Requests from "../../core/requests";
import {
	AgencyPartnershipMandateInitialValues,
	AgencyPartnershipMandateValidationSchema,
	PartnerProposalValidationSchema,
} from "../../conf/AgencyPartnershipMandate.config";
import AddMandateComponent from "../../components/agency_partnership/AddMandate.component";
import CallOutcomeComponent from "../../components/club_partnership/CallOutcome.component";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { color } from "../../config/colors/colors";

const useStyles = makeStyles((theme) => ({
	centeredTitle: {
		fontSize: theme.spacing(3),
		color: color.title,
		fontWeight: "bold",
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
		textAlign: "center",
	},
}));

const MandateStatusContainer = ({ mandate, identifier, mutate }) => {
	const { token } = useSelector((state) => state.user);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);
	const [team, setTeam] = useState(null);
	const [country, setCountry] = useState(null);
	const classes = useStyles();

	const setEndpointAndRequestObj = (values) => {
		switch (identifier) {
			case "agency":
				return Requests.agency_partnership.editMandate(
					{ token: token.access, id: mandate.id },
					{
						country: values.country,
						team: values.team,
						type: values.typeStatus,
						players: values.players,
						work_status: values.workStatus,
						users: values.usersInvolved,
						deadline: values.deadline,
						note: values.note,
					}
				);
			case "partnerProposal":
				return Requests.partner_proposal.editProposal(
					{ token: token.access, id: mandate.id },
					{
						country: country,
						team: team,
						players: [values.players],
						users: values.usersInvolved,
						note: values.note,
					}
				);
			case "baPortfolio":
				return Requests.ba_portfolio.editMandate(
					{ token: token.access, id: mandate.id },
					{
						country: country,
						team: team,
						type: values.typeStatus,
						players: [values.players],
						work_status: values.workStatus,
						users: values.usersInvolved,
						deadline: values.deadline,
						note: values.note,
					}
				);
		}
	};

	const onSubmit = (values, actions) => {
		setLoading(true);
		actions.setSubmitting(true);
		setEndpointAndRequestObj(values)
			.then(() => {
				setLoading(false);
				setError(null);
				let successMessage =
					identifier === "partnerProposal"
						? "Proposal update completed successfully"
						: "Mandate update completed successfully";
				setSuccess(successMessage);
				actions.setSubmitting(false);
				mutate();
			})
			.catch((errorBE) => {
				setLoading(false);
				setError(errorBE?.response?.data?.error);
				actions.setSubmitting(false);
			});
	};

	return (
		<Fragment>
			<CallOutcomeComponent success={success} error={error} />
			{loading ? <Loading /> : null}
			<Grid item xs>
				<Typography className={classes.centeredTitle}>
					{identifier === "partnerProposal"
						? "Proposal Detail"
						: "Mandate Detail"}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Formik
					initialValues={AgencyPartnershipMandateInitialValues(mandate)}
					validationSchema={
						identifier === "partnerProposal"
							? PartnerProposalValidationSchema
							: AgencyPartnershipMandateValidationSchema
					}
					enableReinitialize={true}
					onSubmit={onSubmit}
				>
					{(props) => (
						<AddMandateComponent
							identifier={identifier}
							country={Number(mandate?.players?.[0]?.team?.country?.id)}
							team={Number(mandate?.players?.[0]?.team?.id)}
							agencyId={Number(mandate?.agency?.id)}
							{...props}
							usersInvolved={mandate?.users}
							setTeam={setTeam}
							setCountry={setCountry}
						/>
					)}
				</Formik>
			</Grid>
		</Fragment>
	);
};

export default MandateStatusContainer;
