import React from 'react';
import { Box } from '@material-ui/core';
import EditTextArea from '../../components/EditTextArea';
import Submit from '../../components/Submit';
import { Form } from 'formik';

const ScountingPlayerAddNote = ({
    ...props
}) => {

    return (
        <Form onSubmit={props.handleSubmit}>
            <EditTextArea {...props} name='note' label='Note' />
            <Box mt={2}>
                <Submit {...props} title='Save' style={{marginLeft: "0px"}} />
            </Box>
        </Form>
    );
};

export default ScountingPlayerAddNote;