import React from 'react'
import MainLayout from '../../layout/main/Main.layout'
import ActivityContainer from '../../containers/activity/Activity.container'

const ActivityPage = () => {

    return (
        <MainLayout title = 'Activity'>
            <ActivityContainer />
        </MainLayout>
    )

}

export default ActivityPage