import * as Yup from 'yup';

export const LoginModel = {
    formId: 'loginForm',
    formField: {
        username: {
            name: 'username',
            label: 'Username',
            placeholder: 'Username',
            type: 'text'           
        },
        password: {
            name: 'password',
            label: 'Password',     
            placeholder: 'Password',
            type: 'password'
        }
    }
}

const {
    formField: {
        username,
        password,          
    }
} = LoginModel


export const LoginValidationSchema = {
    schema: Yup.object().shape({
        [username.name]: Yup.string().required('Campo obbligatorio'),
        [password.name]: Yup.string().required('Campo obbligatorio'),        
    }),    
}

export const LoginInitialValues = {
    [username.name]: '',
    [password.name]: '',    
}