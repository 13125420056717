import React, { useCallback, useState, useEffect } from "react";
import { Box, Paper } from "@material-ui/core";
import MainLayout from "../../layout/main/Main.layout";
import ContentLayout from "../../layout/content/Content.layout";
import ScoutingFilterContainer from "../../containers/scouting/ScoutingFilter.container";
import ScoutingPlayerContainer from "../../containers/player/ScountingPlayer.container";
import ScoutingPlayersSelectedContainer from "../../containers/scouting/ScountingPlayersSelected.container";

const ScoutingSelectedPage = () => {
	const [overlay, setOverlay] = useState(false);
	const [player, setPlayer] = useState(null);
	const [filter, setFilter] = useState(null);
	const [showResults, setShowResults] = useState(false);
	const identifier = "selectedList";

	const openOverlay = (player) => {
		setOverlay(true);
		setPlayer(player);
	};

	const closeOverlay = () => {
		setOverlay(false);
		setPlayer(null);
	};

	const onSubmit = useCallback((values) => {
		setFilter(values);
	}, []);

	useEffect(() => {
		if (filter) {
			setShowResults(true);
		}
	}, [filter]);

	const onChange = useCallback(() => {
		setShowResults(false);
	}, []);

	return (
		<MainLayout title="Selected List">
			<ContentLayout>
				<Box mt={2} width={1}>
					<Paper>
						<Box padding={4}>
							<ScoutingFilterContainer
								onSubmit={onSubmit}
								onChange={onChange}
							/>
						</Box>
					</Paper>
				</Box>
				<Box mt={2} width={1}>
					{showResults && (
						<ScoutingPlayersSelectedContainer
							filter={filter}
							openOverlay={(player) => openOverlay(player)}
							open={overlay}
						/>
					)}

					{overlay ? (
						<ScoutingPlayerContainer
							id={player.id}
							closeOverlay={closeOverlay}
							open={overlay}
							identifier={identifier}
						/>
					) : null}
				</Box>
			</ContentLayout>
		</MainLayout>
	);
};

export default ScoutingSelectedPage;
