import React from 'react'
import Error from '../../components/error/Error.component'
import Loading from '../../components/progress/Progress.component'
import { Fragment } from 'react'
import { useParams } from 'react-router'
import DetailClubRequest from '../../components/requests/DetailClubRequest'
import { useSelector } from 'react-redux'
import useSWRRequest from '../../core/useSWRRequest'
import Requests from '../../core/requests'

const RequestDetailContainer = () => {

    const { token } = useSelector(state => state.user)
    const { id } = useParams();
    const { data, loading, error } = useSWRRequest(Requests.notes.note, { token: token.access, id})


    return (
        <Fragment>
            {error ? <Error message={error.message} /> : null}
            {loading ? <Loading /> : null}
            {data ? <DetailClubRequest request={data} /> : null}
        </Fragment>
    )
}

export default RequestDetailContainer