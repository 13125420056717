import React, { Fragment } from 'react'
import { LinearProgress } from '@material-ui/core'
import FilterAutocompleteComponent from '../../components/filter/FilterAutocomplete.component'
import { useSelector } from 'react-redux'
import useSWRRequest from '../../core/useSWRRequest'
import Requests from '../../core/requests'

const AgenciesContainer = ({
    onChange = () => { },
    agencyId,
    countryId,
    ...props
}) => {

    const { token } = useSelector(state => state.user)
    const { data, loading } = useSWRRequest(Requests.agency.getListAgencies, { token: token.access })

    const agenciesFilter = () => data
        .filter((item) => item.country.id == countryId)
        .map((item) => ({
            id: item.id,
            name: item.name
        }))

    const getSelectedAgency = () => {
        if(data && props?.values?.agency){
            return data.filter(element => element.id === props.values.agency)[0];   
        }  
    }

    return (
        <Fragment>
            {loading ? <LinearProgress /> : null}
            <FilterAutocompleteComponent disabled={agencyId} value={getSelectedAgency() || null } title='Agency' options={agenciesFilter()} setFilter={(value) => onChange('agency', value)} />
        </Fragment>
    )
}

export default AgenciesContainer