import React, { useCallback, useEffect, useState, Fragment } from "react";
import Loading from "../../components/progress/Progress.component";
import { Box, Grid } from "@material-ui/core";
import ClubPartnershipList from "../../components/club_partnership/ClubPartnershipList.component";
import { useSelector } from "react-redux";
import useSWRRequest from "../../core/useSWRRequest";
import Requests from "../../core/requests";
import { makeStyles } from "@material-ui/styles";
import CallOutcomeComponent from "../../components/club_partnership/CallOutcome.component";
import ConfirmDeletionModalComponent from "../../components/club_partnership/ConfirmDeletionModal.component";
import { isNotMarketOperatorUser } from "../../utils/CheckLoggedUser"

const useStyles = makeStyles(() => ({
	deletePopupButton: {
		color: "#FFFFFF",
		width: "10rem",
		margin: "16px",
		fontSize: "16px",
		fontWeight: "600",
		backgroundColor: "#2C5364",
		"&:hover": {
			backgroundColor: "#233539",
			boxShadow:
				"0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
		},
	},
}));

const MandatesContainer = ({
	open,
	onAddAction = () => {},
	onSelectItem = () => {},
	clubId,
}) => {
	const { token, user } = useSelector((state) => state.user);
	const { data, loading, mutate } = useSWRRequest(
		Requests.club_partnership.clubMandates,
		{ token: token.access, clubId: clubId }
	);
	const classes = useStyles();
	const [openCreate, setOpenCreate] = useState(false);
	const [errorBE, setErrorBE] = useState(null);

	let selectedMandateId = 0;

	const onDeleteItem = useCallback((id) => {
		selectedMandateId = id;
		setOpenCreate(true);
	}, []);

	const deleteMandate = useCallback(() => {
		Requests.club_partnership
			.deleteMandate({ token: token.access, mandateId: selectedMandateId })
			.then(() => {
				mutate();
				setOpenCreate(false);
			})
			.catch((error) => {
				setOpenCreate(false);
				setErrorBE(error?.response?.data?.error);
			});
	}, []);

	useEffect(() => {
		if (!open) {
			mutate();
		}
	}, [open]);

	const checkIsNotMarketOperatorUser = useCallback(()=>{
        return isNotMarketOperatorUser(user?.groups)
    }, [])

	return (
		<Fragment>
			<CallOutcomeComponent error={errorBE} />
			<Grid xs={12}>{loading ? <Loading /> : null}</Grid>
			<Box mt={2} width={1}>
				<ClubPartnershipList
					items={data}
					onAddAction={onAddAction}
					onSelectItem={onSelectItem}
					onDeleteItem={onDeleteItem}
					checkIsNotMarketOperatorUser = {checkIsNotMarketOperatorUser}
				/>
			</Box>
			<ConfirmDeletionModalComponent
				openCreate={openCreate}
				setOpenCreate={setOpenCreate}
				deleteFunction={deleteMandate}
			/>
		</Fragment>
	);
};

export default MandatesContainer;
