import React, { useState } from 'react'
import MainLayout from '../../layout/main/Main.layout'
import ContentLayout from '../../layout/content/Content.layout'
import RequestDetailContainer from '../../containers/requests/RequestDetail.container'
import { Box } from '@material-ui/core'
import ProposalListContainer from '../../containers/proposal/ProposalList.container'
import Modal from '../../components/Modal'
import ProposalAddContainer from '../../containers/proposal/ProposalAdd.container'
import RequestPlayerContainer from '../../containers/player/RequestPlayer.container'
import RequestUpdateContainer from '../../containers/requests/RequestUpdate.container'
import { isNotMarketOperatorUser } from '../../utils/CheckLoggedUser'
import { useSelector } from 'react-redux'
import ChatContainer from '../../containers/chat/Chat.container'
import useSWRRequest from '../../core/useSWRRequest'
import Requests from '../../core/requests'
import { useParams } from 'react-router-dom'

const RequestPage = () => {

    const [openCreate, setOpenCreate] = useState(false)
    const [detail, setDetail] = useState(null)
    const { user, token } = useSelector(state => state.user)
    const { id } = useParams()
    const { data: comments, mutate: mutateComments } = useSWRRequest(Requests.notes.getCommentsByProposal, { token: token.access, mandateId: id })

    return (
        <MainLayout title='Club Proposal'>
            <ContentLayout>                
                <Box mt={2} padding={2}  width={1}>
                    <RequestDetailContainer />
                </Box>
                {isNotMarketOperatorUser(user?.groups) &&
                    <Box mt={2} padding={2} width={1}>
                        <RequestUpdateContainer open={openCreate} onSelectItem={(id) => setDetail(id)} onAddAction={() => setOpenCreate(true)} />
                    </Box>
                }                
                <Box mt={2} padding={2} width={1}>
                    <ProposalListContainer open={openCreate} onSelectItem={(id) => setDetail(id)} onAddAction={() => setOpenCreate(true)} />
                </Box>
                <Box mt={2} padding={2} width={1}>
                    <ChatContainer data={comments} identifier = "clubProposal" id = {id} mutate = {mutateComments}/>
                </Box>
                <Modal
                    open={openCreate}
                    onClose={() => setOpenCreate(false)}
                    title='Create new Proposal'>
                    <ProposalAddContainer onClose={() => setOpenCreate(false)} />
                </Modal>

                <Modal
                    open={detail ? true : false}
                    onClose={() => setDetail(null)}
                    title='Detail Proposal'>
                    <RequestPlayerContainer id={detail} />
                </Modal>

            </ContentLayout>
        </MainLayout >
    )
}

export default RequestPage