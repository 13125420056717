import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
    typography: {
        fontFamily: "Montserrat, sans-serif"
    },
    palette: {
        primary: {
            main: "#2C5364",
        },
        secondary: {
            main: "#223239",
        },
    },
});


export default theme