import React, { Fragment } from 'react'
import Box from '@material-ui/core/Box'
import { Layout, Spacing } from '../../components/abi-layout'


const LoginLayout = ({
    children
}) => {

    return (
        <Fragment>
            {children}
        </Fragment>


    )
}

export default LoginLayout