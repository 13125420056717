import React, { Fragment } from 'react'
import { LinearProgress } from '@material-ui/core'
import FilterAutocompleteComponent from '../../components/filter/FilterAutocomplete.component'
import useSWRRequest from '../../core/useSWRRequest'
import Requests from '../../core/requests'
import { useSelector } from 'react-redux'

const ScountingFilterRoleContainer = (props) => {

    const { token } = useSelector(state => state.user)
    const { data: roleOptions, loading } = useSWRRequest(Requests.roles.roles, { token: token.access })

    return (
        <Fragment>
            {loading ? <LinearProgress /> : null}
            <FilterAutocompleteComponent
                options={roleOptions}
                setFilter={(value) => props.setFieldValue('roles', value)} />
        </Fragment>
    )
}

export default ScountingFilterRoleContainer