import React, { Fragment, useState, useRef, useCallback, useEffect } from 'react'
import { Box, LinearProgress, Typography, Grid } from '@material-ui/core'
import { useSelector } from 'react-redux'
import Requests from '../../core/requests'
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from "@material-ui/core/TextField";
import Avatar from '@material-ui/core/Avatar';
import { isArray } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    flex: {
        display: 'flex',
        alignItems: 'center'
    },
    m3: {
        margin: '3px'
    },
}));

const FilterPlayerByName = ({
    name,
    setFilter = () => { },
    multiple,
    setTeam,
    setCountry,
    ...props
}) => {

    const classes = useStyles();
    const { token } = useSelector(state => state.user)
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [timer, setTimer] = useState(null)
    const previousController = useRef();

    useEffect(() => {
        if(props?.values?.players && props?.values?.players?.length && props?.values?.players[0]){
            Requests.players.players(({ token: token.access, query: `${props.values.players[0]}/` })).then((data) => {
                setOptions([data]);
            }).catch(() => {
                setOptions([])
            })
        }
    },[props.values.players])

    const handleChange = useCallback((event, newValue) => {
        if(setTeam && newValue){
            setTeam(newValue?.team?.id)
        }
        if(setCountry && newValue){
            setCountry(newValue?.team?.country?.id)
        }
        let selectedValue;
        if (newValue) {
            if (isArray(newValue)) {
                selectedValue = newValue.map((item) => item.id)
            } else {
                selectedValue = newValue.id
            }
        }
        setFilter(selectedValue)
    }, []);
  
    const getData = useCallback((searchTerm) => {
        setLoading(true);
        if (previousController.current) {
            previousController.current.abort();
        }
        const controller = new AbortController();
        previousController.current = controller;

        Requests.players.players(({ token: token.access, query: "?full_name=" + searchTerm })).then((data) => {
            setOptions(data);
            setLoading(false)
        }).catch(() => {
            setOptions([]);
            setLoading(false)
        })
    }, []);
    
    const onInputChange = (event, value) => {
        clearTimeout(timer)
        
        const newTimer = setTimeout(() => {
            if (value && value?.length >= 3) {
                getData(value);
            } else {
                setOptions([]);
            }
        }, 800)

        setTimer(newTimer)
    };

    const setSelectPlayers = () => {
        let players = props?.values?.players;
        if(players && options){
            if(isArray(players)){
                return options.filter(player => player.id == props.values.players[0])[0];
            }else{
                return options.filter(player => player.id == props.values.players)[0];
            }
        }
    }

    return (
        <Fragment>
            <Box mt={2}>
                {loading && <LinearProgress />}
                <Typography>Search Players</Typography>
                <Autocomplete
                    onChange={handleChange}
                    id="combo-box-demo"
                    {...(props?.values?.players && { value: setSelectPlayers() || null })}
                    multiple={multiple}
                    options={options}
                    onInputChange={onInputChange}
                    getOptionLabel={(option) => option?.full_name ? option?.full_name : option?.full_name_ascii}
                    renderInput={(params) => (
                        <TextField {...params} variant="outlined" error={props.errors && props.errors[name] ? Boolean(props.errors[name]) : false} helperText={props.errors && props.errors[name] ? props.errors[name]: null} />
                    )}
                    renderOption={(option) => (
                        <Grid item xs className={classes.flex}>
                            <Avatar src={option?.image_url} className={classes.m3}/>
                            <Typography>{`${option?.full_name}${option?.age ? "(" + option?.age + ")" : ""}, ${option?.team?.name}(${option?.team?.country?.name})`}</Typography>
                        </Grid>
                    )}
                />
            </Box>
        </Fragment>
    )
}

export default FilterPlayerByName