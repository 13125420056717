import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import Submit from '../Submit';
import { Form } from 'formik';
import TeamContainer from '../../containers/club_partnership/Team.container';
import UsersInvolvedContainer from '../../containers/club_partnership/UsersInvolved.container';
import TypeStatusContainer from '../../containers/club_partnership/TypeStatus.container';
import Select from '../Select';
import useSWRRequest from '../../core/useSWRRequest'
import Requests from '../../core/requests'
import { useSelector } from 'react-redux'

const player_status =  [{
    id: 'OnSale',
    name: 'OnSale'
},{
    id: 'Stable',
    name: 'Stable'
},{
    id: 'AgencyProposal',
    name: 'AgencyProposal'
},{
    id: 'ClubRequest',
    name: 'ClubRequest'
},{
    id: 'ContractExtension',
    name: 'ContractExtension',
},{
    id: 'ContractExpiring',
    name: 'ContractExpiring'
}]

const work_status =  [{
    label: 'NotPriority',
    value: 'NotPriority'
},{
    label: 'Todo',
    value: 'Todo'
},{
    label: 'InProgress',
    value: 'InProgress'
},{
    label: 'Done',
    value: 'Done'
}]

const AddPlayerComponent = ({
    ...props
}) => {

    const { token } = useSelector(state => state.user)

    const { data: countries, loading } = useSWRRequest(Requests.areas.countriesHasTeams, { token: token.access })

    const onChange = useCallback((name, value) => {
        props.setFieldValue(name, value)
    }, [])

    return (
        <Form onSubmit={props.handleSubmit}>
            <Box mt={2}>
                <TeamContainer loading = {loading} countries = {countries} addSinglePlayer={true} {...props} onChange={onChange} />
            </Box>            
            <Box mt={2}>
                <TypeStatusContainer addSinglePlayer={true} {...props} onChange={(name, value) => props.setFieldValue(name, value)} options={player_status}/>
            </Box>
            <Box mt={2}>
                <Select {...props} label='Work Status' name="workStatus" options={work_status} value={props.values.workStatus}/>
            </Box>
            <Box mt={2}>
                <UsersInvolvedContainer {...props} onChange={(name, value) => props.setFieldValue(name, value)} />
            </Box>
            <Box my={2}>
                <Submit {...props} title='Save' />
            </Box>
        </Form>
    );
};

export default AddPlayerComponent;