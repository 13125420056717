import React from 'react'
import { withStyles } from "@material-ui/core/styles"
import { style } from './PlayerThumb.component.style';
import { Typography, Grid } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import ImageContainer from '../../containers/image/Image.container';

const StyledBadge = withStyles((theme) => ({
    badge: {
        border: `2px solid ${theme.palette.primary.main}`,
        backgroundColor: '#fff',
        padding: '0rem',
        borderRadius: '2rem',
        " & .MuiSvgIcon-root": {
            fontSize: '15px'
        }
    },
}))(Badge);

const PlayerThumbComponent = ({
    classes,
    data,
    identifier
}) => {


    return (
        <Grid container className={classes.root}>
            <Grid item>
                <ImageContainer src={identifier === "partnerPlayer" ? data?.image_url : data?.player?.image_url} className={classes.icon} />
            </Grid>
            <Grid item>
                <Typography className={classes.name}>
                    {identifier === "partnerPlayer" ? data?.full_name : data?.player?.full_name}
                </Typography>
            </Grid>
            <Grid item>
                <Typography className={classes.nationality}>
                    {identifier === "partnerPlayer" ? data?.birth_country?.name : data?.player?.birth_country?.name}
                </Typography>
            </Grid>
        </Grid>
    )

}

export default withStyles(style)(PlayerThumbComponent)