import React from 'react'
import { makeStyles } from '@material-ui/styles';
import Header from '../../components/header/Header.component';
import MenuBar from '../../components/menu/MenuBar.component';
import Footer from '../../components/footer/Footer.component';
import BranchiniLogo from '../../assets/images/branchini-logo.svg'


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        minHeight: '100vh',
    },
    contentWrap: {
        flex: 1
    },
    content: {
        flexGrow: 1,
    },
    toolbar: theme.mixins.toolbar,
    branchini: {
        position: 'relative',
        height: '125px',
        bottom: "4rem",
        left: "40rem"
    }
}));


const MainLayout = ({
    children,
    title,
    elite = false,
    logo = false
}) => {

    const classes = useStyles();


    return (
        <div className={classes.root} >
            <MenuBar />
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Header title={title} elite={elite} logo={logo} />
                <div className={classes.contentWrap}>
                    {children}
                </div>
                <Footer />
            </main>
        </div>
    )
}

export default MainLayout