import _ from 'lodash';

export const PartnerProposalEndpoints = {
    basePath: '/api/v1/agency-requests/',
    endpoints: {
        agenciesList: {
            path: 'area-partner-proposals/',
            method: 'GET',
            get: 'data',
            fallback: [],   
            auth: true,    
        },
        agencyProposals: {
            path: 'partner-proposals/?agency={{agencyId}}',
            method: 'GET',
            get: 'data',
            fallback: [],
            swr: {
                revalidateOnFocus: false,
                revalidateOnMount: false,
                revalidateOnReconnect: false,
                dedupingInterval: 1000,
            },   
            auth: true,    
        },
        deleteProposal: {
            path: 'partner-proposals/{{id}}/',
            method: 'DELETE',
            fallback: null,   
            auth: true
        },
        getProposal: {
            path: 'partner-proposals/{{id}}/',
            method: 'GET',
            get: 'data',
            fallback: [],   
            auth: true,    
        },
        addProposal: {
            path: 'partner-proposals/',
            method: 'POST',
            get: 'data',
            fallback: null,   
            auth: true,    
        },
        editProposal: {
            path: 'partner-proposals/{{id}}/',
            method: 'PATCH',
            get: 'data',
            fallback: null,   
            auth: true,
        },
        convertProposalToMandate:{
            path: 'partner-proposal-to-mandate/',
            method: 'POST',
            get: 'data',
            fallback: null,   
            auth: true, 
        },
        getCommentsByProposal: {
            path: 'partner-proposals/{{mandateId}}/comments/',
            method: 'GET',
            get: 'data',
            fallback: [],
            auth: true,
            swr: {
                revalidateOnFocus: false
            }
        },
        addProposalComment: {
            path: 'partner-proposals/{{id}}/comments/',
            method: 'POST',
            get: 'data',
            fallback: null,   
            auth: true,    
        },
        deleteProposalComment: {
            path: 'partner-proposals/{{id}}/comments/{{commentId}}/',
            method: 'DELETE',
            fallback: null,   
            auth: true
        }

    }
}

export default PartnerProposalEndpoints;