import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionIcon from '@material-ui/icons/Description';
import { isNotMarketOperatorUser } from '../../utils/CheckLoggedUser';
import { useSelector } from 'react-redux';

const RowProposalComponent = ({
    player,
    proposal,
    onSelectItem = () => { },
    onDeleteItem = () => { }
}) => {
    const { user } = useSelector(state => state.user)

    return (
        <TableRow key={player.id}>
            <TableCell>{player.full_name}</TableCell>
            <TableCell>{player.roles.map((role, index) => index === player.roles.length - 1 ? `${role.short_name}` : `${role.short_name}-`)}</TableCell>
            <TableCell>{player.age}</TableCell>
            <TableCell>{player.team.name}</TableCell>
            <TableCell>{player.team.country.name}</TableCell>
            <TableCell onClick={() => onDeleteItem(proposal, player.id)} style={{ cursor: 'pointer' }}><DeleteIcon style={isNotMarketOperatorUser(user?.groups) ? {} : {color: "#dddddd"}} /></TableCell>
            <TableCell onClick={() => onSelectItem(player.id)} style={{ cursor: 'pointer' }}><DescriptionIcon /></TableCell>
        </TableRow>
    )
}

export default RowProposalComponent;
