import React, { useCallback } from "react";
import { Box } from "@material-ui/core";
import EditText from "../EditText";
import EditTextArea from "../EditTextArea";
import Submit from "../Submit";
import { Form } from "formik";
import TeamContainer from "../../containers/club_partnership/Team.container";
import TypeStatusContainer from "../../containers/club_partnership/TypeStatus.container";
import Select from "../Select";
import AgenciesContainer from "../../containers/agency_partnership/Agencies.container";
import FilterPartnerProposalUsers from "../../containers/filters/FilterPartnerProposalUsers.container";
import FilterBaPortfolioUsers from '../../containers/filters/FilterBaPortfolioUsers.container'
import { isNotMarketOperatorUser } from "../../utils/CheckLoggedUser";
import { useSelector } from "react-redux";
import useSWRRequest from '../../core/useSWRRequest'
import Requests from '../../core/requests'
import FilterPlayerByName from "../../containers/filters/FilterPlayerByName.container";


const type_status = [
    {
        id: 1,
        name: "Player on Sale",
    },
    {
        id: 2,
        name: "Player to Buy",
    },
    {
        id: 3,
        name: "Contract extension",
    },
    {
        id: 4,
        name: "Contract expired",
    },
];

const work_status = [
    {
        label: "NotPriority",
        value: "NotPriority",
    },
    {
        label: "Todo",
        value: "Todo",
    },
    {
        label: "InProgress",
        value: "InProgress",
    },
    {
        label: "Done",
        value: "Done",
    },
];

const AddMandateComponent = ({
    country,
    team,
    agencyId,
    identifier,
    usersInvolved,
    setTeam,
    setCountry,
    ...props
}) => {
    const { user, token } = useSelector((state) => state.user);

    const { data: countries, loading } = useSWRRequest(Requests.areas.countriesHasTeams, { token: token.access })


    const onChange = useCallback((name, value) => {
        props.setFieldValue(name, value);
    }, []);

    const isMarketOperatorUser = useCallback(() => {
        return !isNotMarketOperatorUser(user?.groups);
    }, []);

    return (
        <Form onSubmit={props.handleSubmit}>
            {identifier !== "partnerProposal" && (
                <Box mt={2}>
                    <EditText
                        {...props}
                        type="date"
                        label="Deadline"
                        name="deadline"
                        disabled={isMarketOperatorUser()}
                    />
                </Box>
            )}
            <Box mt={2}>
                <AgenciesContainer
                    {...props}
                    countryId={country}
                    agencyId={agencyId}
                    onChange={(name, value) => props.setFieldValue(name, value)}
                />
            </Box>
            <Box mt={2}>
                {identifier === "partnerProposal" || identifier === "baPortfolio" ? 
                <FilterPlayerByName {...props} setFilter={(value) => onChange('players', value)} name="players" multiple={false} setTeam={setTeam} setCountry={setCountry} /> 
                :
                <TeamContainer loading = {loading} countries = {countries} isMarketOperatorUser={isMarketOperatorUser} country={country} team={team} {...props} onChange={onChange} />
                }
            </Box>
            {identifier !== "partnerProposal" && (
                <>
                    <Box mt={2}>
                        <TypeStatusContainer
                            isMarketOperatorUser={isMarketOperatorUser}
                            {...props}
                            onChange={(name, value) => props.setFieldValue(name, value)}
                            options={type_status}
                        />
                    </Box>
                    <Box mt={2}>
                        <Select
                            disabled={isMarketOperatorUser()}
                            {...props}
                            label="Work Status"
                            name="workStatus"
                            options={work_status}
                            value={props.values.workStatus}
                        />
                    </Box>
                </>
            )}
            <Box mt={2}>
                {
                    identifier === 'partnerProposal' ? (
                        <FilterPartnerProposalUsers
                            {...props}
                            onChange={(name, value) => props.setFieldValue(name, value)}
                        />
                    ) : <FilterBaPortfolioUsers
                        isMarketOperatorUser={isMarketOperatorUser}
                        {...props}
                        onChange={(name, value) => props.setFieldValue(name, value)}
                    />
                }

            </Box>
            <Box mt={2}>
                <EditTextArea
                    {...props}
                    label="Note"
                    name="note"
                    disabled={isMarketOperatorUser()}
                />
            </Box>
            <Box my={2}>
                <Submit {...props} title="Save" disabled={isMarketOperatorUser()} />
            </Box>
        </Form>
    );
};

export default AddMandateComponent;
