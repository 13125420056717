import { color } from '../../config/colors/colors.js';

export const style = theme => ({
    root: {

    },
    title: {
        fontSize: theme.spacing(3),
        color: color.title,
        fontWeight: 'bold',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        fontFamily: 'Anton-Regular'
    },
    icon: {
        width: theme.spacing(10),
        height: theme.spacing(10),
        border: `2px solid ${theme.palette.primary.main}`,
        backgroundColor: '#fff',
        boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)"
    },
    valueContainer: {
        padding: '0.3rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    value: {
        color: color.secondary.main,
        textTransform: 'uppercase',
        paddingLeft: theme.spacing(0.6),
    },
    label: {
        color: color.primary.main,
        fontWeight: 'bold',
    },
    name: {
        fontSize: theme.spacing(2),
        color: color.primary.main,
        fontWeight: 600
    },
    surname: {
        marginLeft: theme.spacing(1),
        fontSize: theme.spacing(2),
        color: color.primary.main,
        fontWeight: 600
    },
    nationality: {
        marginLeft: theme.spacing(1),
        fontSize: theme.spacing(2),
        color: color.primary.main,
        fontStyle: 'italic'
    },
    role: {
        fontSize: theme.spacing(2),
        color: color.secondary.main,
        fontWeight: 600
    }
});