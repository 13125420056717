import React, { Fragment } from 'react'
import FilterAutocompleteComponent from '../../components/filter/FilterAutocomplete.component'

const TypeStatusContainer = ({
    onChange = () => { },
    options,
    isMarketOperatorUser,
    addSinglePlayer,
    ...props
}) => {

    const getTypesStatus = () => {
        if(addSinglePlayer && options && props.values.typeStatus){
            return options.filter(typeStatus => typeStatus.id == props.values.typeStatus)[0];
        }

        if(!addSinglePlayer && options && props?.values?.typeStatus?.length){
            return options.filter(optionItem => props.values.typeStatus.some(id => optionItem.id === id));
        }  
    }

    return (
        <Fragment>
            <FilterAutocompleteComponent disabled={isMarketOperatorUser ? isMarketOperatorUser() : false} {...props} value={getTypesStatus() || []} title={addSinglePlayer ? 'Player Status' : 'Type Status'} multiple={addSinglePlayer ? false : true} options={options} setFilter={(value) => onChange('typeStatus', value)} name="typeStatus"/>
        </Fragment>
    )
}

export default TypeStatusContainer