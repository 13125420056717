import React from 'react'
import MainLayout from '../../layout/main/Main.layout'
import AssistedPlayerContainer from '../../containers/player/AssistedPlayer.container'
import { useParams } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import { color } from '../../config/colors/colors'
import { makeStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box'
import ContentLayout from '../../layout/content/Content.layout'
import { LinearProgress } from '@material-ui/core'
import useSWRRequest from '../../core/useSWRRequest'
import Requests from '../../core/requests'
import { useSelector } from 'react-redux'
import ReportAddFileContainer from '../../containers/reports/ReportPlayerAddFile.container';
import PlayerStatusContainer from '../../containers/player/PlayerStatus.container'
import FilesContainer from '../../containers/club_partnership/Files.container';
import LinksContainer from '../../containers/club_partnership/Links.container'
import PlayerAddLinkContainer from '../../containers/player/PlayerAddLink.container'
import ChatContainer from '../../containers/chat/Chat.container';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: color.grey,
    },
    divider: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(5),
        height: theme.spacing(0.2),
        backgroundColor: color.primary.main,
        width: theme.spacing(14)
    }
}));


const MonitoringPlayerPage = () => {

    const classes = useStyles();
    const { id, playerId } = useParams()
    const identifier = playerId ? "monitoring" : "partnerPlayer";
    const { token } = useSelector(state => state.user)
    const { data, loading, mutate } = useSWRRequest(identifier === "monitoring" ? Requests.players.assistedPlayer : Requests.players.player, { token: token.access, id })
    const { data: comments, mutate: mutateComments } = useSWRRequest(Requests.players.getCommentsByPlayer, { token: token.access, playerId: identifier === "monitoring" ? playerId : id })

    return (
        <MainLayout title={identifier === "monitoring" ? data?.player?.full_name : data?.full_name}>
            {loading && <LinearProgress />}
            <Grid container className={classes.root}>
                <ContentLayout>
                    <Box marginTop={2} marginBottom={8}>
                        <AssistedPlayerContainer id={id} identifier = {identifier} data={data} loading={loading} />
                    </Box>
                </ContentLayout>
            </Grid>
            
            <ContentLayout>
                <Grid container item xs={12} alignItems='center'>
                    <ReportAddFileContainer id={id} data={data} mutate={mutate} identifier={identifier} />
                    <PlayerAddLinkContainer playerId={identifier === "monitoring" ? data?.player?.id : data?.id} mutate= {mutate}/>
                </Grid>

                <Grid container item xs={12}>
                    <FilesContainer identifier = {identifier} files = {identifier === "monitoring" ? data?.player?.files : data?.files} mutate= {mutate} />
                    <LinksContainer identifier = {identifier} links = {identifier === "monitoring" ? data?.player?.links : data?.links} mutate= {mutate}/>
                </Grid>

                 {identifier === "monitoring" &&
                    <>
                        <Grid className={classes.divider} item xs={12} />

                        <Grid container item xs={12} alignItems='center'>
                            <PlayerStatusContainer data={data} loading={loading} mutate={mutate} player={id} />
                        </Grid>
                    </>
                }

                <Grid className={classes.divider} item xs={12} />

                <Grid container item xs={12} alignItems='center'>
                    <ChatContainer data={comments} identifier = {identifier} id = {identifier === "monitoring" ? playerId : id} mutate = {mutateComments}/>
                </Grid>

            </ContentLayout>
        </MainLayout>
    )
}

export default MonitoringPlayerPage