import React from 'react'
import MainLayout from '../../layout/main/Main.layout'
import ContentLayout from '../../layout/content/Content.layout'
import { Box } from '@material-ui/core'
import AllToDoValidProposalsContainer from '../../containers/proposal/AllToDoValidProposals.container'

const AllToDoValidProposalsPage = () => {
    return (
        <MainLayout title='Proposals'>
            <ContentLayout>                
                <Box mt={2} padding={2} width={1}>
                    <AllToDoValidProposalsContainer />
                </Box>
            </ContentLayout>
        </MainLayout>
    )
}

export default AllToDoValidProposalsPage