import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import EditText from '../../components/EditText';
import EditTextArea from '../../components/EditTextArea';
import Submit from '../../components/Submit';
import { Form } from 'formik';
import Select from '../Select';
import FilterTeamContainer from '../../containers/filters/FilterTeam.container';
import FilterRoleContainer from '../../containers/filters/FilterRole.container';
import FilterPriorityContainer from '../../containers/filters/FilterPriority.container';
import FilterPositionContainer from '../../containers/filters/FilterPosition.container';
import FilterUsersContainer from '../../containers/filters/FilterUsers.container';

const AddClubRequest = ({
    disableNationalityField,
    disableClubField,
    competitionName,
    competitionId,
    ...props
}) => {

    const onChange = useCallback((name, value) => {
        props.setFieldValue(name, value)
    }, [])

    const work_status =  [{
        label: 'NotPriority',
        value: 'NotPriority'
    },{
        label: 'Todo',
        value: 'Todo'
    },{
        label: 'InProgress',
        value: 'InProgress'
    },{
        label: 'Done',
        value: 'Done'
    }]

    return (
        <Form onSubmit={props.handleSubmit}>
            <Box mt={2}>
                <EditText {...props} type="date" label='Deadline' name="deadline" />
            </Box>
            <Box mt={2}>
                <EditText {...props} label='Competition' name="competition" disabled={true}/>
            </Box>
            <Box mt={2}>
                <FilterTeamContainer competitionId = {competitionId} disableNationalityField={disableNationalityField} disableClubField={disableClubField} {...props} onChange={onChange} />
            </Box>            
            {/* <Box mt={2}>
                <FilterRoleContainer onChange={onChange} />
            </Box> */}
            
            <Box mt={2}>
                <EditText {...props} label='Position' name="position" />

                {/* <FilterPositionContainer onChange={onChange} /> */}
            </Box>
            <Box mt={2}>
                <Select {...props} label='Status' name="status" options={work_status} value={props.values.workStatus}/>
            </Box>
            {/* <Box mt={2}>
                <FilterPriorityContainer onChange={onChange} />
            </Box> */}
            <Box mt={2}>
                <FilterUsersContainer {...props} onChange={onChange} />
            </Box>
            {/* <Box mt={2}>
                <EditTextArea {...props} label='Note' name="note" />
            </Box> */}
            <Box my={2}>
                <Submit {...props} title='Save' />
            </Box>
        </Form>
    );
};

export default AddClubRequest;