import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
    Fragment,
} from "react";
import Error from "../../components/error/Error.component";
import Loading from "../../components/progress/Progress.component";
import { Box } from "@material-ui/core";
import List from "../../components/requests/ListClubRequests";
import { useSelector } from "react-redux";
import useSWRRequest from "../../core/useSWRRequest";
import Requests from "../../core/requests";
import ConfirmDeletionModalComponent from "../../components/club_partnership/ConfirmDeletionModal.component";
import CallOutcomeComponent from "../../components/club_partnership/CallOutcome.component";
import { isNotMarketOperatorUser } from "../../utils/CheckLoggedUser";

const filterClubRequests = (items, filter) => {
    let result = items;

    if (filter.team) {
        result = result.filter((item) => item.team.id === filter.team);
    }

    if (filter.league) {
        result = result.filter(
            (item) =>
                item.team.competitions.filter((e) => e.id === filter.league).length > 0
        );
    }

    if (filter.nationality) {
        result = result.filter(
            (item) => item.team.country.id === filter.nationality
        );
    }

    return result;
};

const RequestsListContainer = ({
    open,
    filter,
    onAddAction = () => { },
    onSelectItem = () => { },
    add = true,
    teamId,
    isEliteClubProposal
}) => {
    const { token, user } = useSelector((state) => state.user);
    const [openCreate, setOpenCreate] = useState(false);
    const [errorBE, setErrorBE] = useState(null);
    const otherParams = isEliteClubProposal ? "&is_elite=True" : null
    const { data, loading, error, mutate } = useSWRRequest(Requests.notes.agendaNotesByTeam, { token: token.access, teamId, otherParams });
    const items = useMemo(
        () => (filter ? filterClubRequests(data, filter) : data),
        [data, filter]
    );

    let selectedProposalId = 0;

    const onDeleteItem = useCallback((id) => {
        if(isNotMarketOperatorUser(user?.groups)){
            selectedProposalId = id;
            setOpenCreate(true);
        }
    }, []);

    const deleteItem = useCallback(() => {
        Requests.notes
            .delete({ token: token.access, id: selectedProposalId })
            .then(() => {
                mutate();
                setOpenCreate(false);
            })
            .catch((error) => {
                setErrorBE(error?.response?.data?.error);
            });
    }, []);

    useEffect(() => {
        if (!open) {
            mutate();
        }
    }, [open]);

    return (
        <Fragment>
            {error ? <Error message={error.message} /> : null}
            {loading ? <Loading /> : null}
            <Box mt={2} width={1}>
                <List
                    items={items}
                    add={isNotMarketOperatorUser(user?.groups)}
                    onAddAction={onAddAction}
                    onSelectItem={onSelectItem}
                    onDeleteItem={onDeleteItem}
                />
            </Box>
            <ConfirmDeletionModalComponent
                openCreate={openCreate}
                setOpenCreate={setOpenCreate}
                deleteFunction={deleteItem}
            >
                <CallOutcomeComponent error={errorBE} />
            </ConfirmDeletionModalComponent>
        </Fragment>
    );
};

export default RequestsListContainer;
