import React, { Fragment } from 'react';
import { Box, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import Modal from '../../components/Modal'

const useStyles = makeStyles(() => ({
    deletePopupButton: {
        color: "#FFFFFF",
        width: "10rem",
        margin: "16px",
        fontSize: "16px",
        fontWeight: "600",
        backgroundColor: "#2C5364",
        '&:hover': {
            backgroundColor: "#233539",
            boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)"
        }
    }
}));

const ConfirmDeletionModalComponent = ({
    children,
    openCreate,
    setOpenCreate,
    deleteFunction 
}) => {


    const classes = useStyles();

    return (
        <Fragment>
            <Modal open={openCreate} onClose={() => setOpenCreate(false)} title='Are you sure?'>
                {children}
                <Box display="flex" flexDirection="row" justifyContent='center' flexGrow={1}>
                    <Button className={classes.deletePopupButton} onClick={() => setOpenCreate(false)}>Close</Button>
                    <Button className={classes.deletePopupButton} onClick={() => deleteFunction()}>Delete</Button>
                </Box>
            </Modal>
        </Fragment>
    );
};

export default ConfirmDeletionModalComponent;